<template>
  <div class="recorder-container">
    <md-icon v-if="isRecording" class="icon-recording" style="margin: 0">graphic_eq</md-icon>
    <button @click="toggleRecording" class="primary-medium-btn recorder-button" :disabled="finishedRecording">
      <md-icon v-if="!isRecording">mic</md-icon>
      <md-icon v-else>stop</md-icon>
    </button>
    <div>
      <button @click="uploadRecording" :disabled="!finishedRecording" class="primary-medium-btn recorder-button">
        <md-icon>upload</md-icon>
      </button>
      <button @click="downloadRecording" :disabled="!finishedRecording" class="primary-medium-btn recorder-button">
        <md-icon>download</md-icon>
      </button>
    </div>
    <button v-if="this.audioUrl" @click="resetRecordingState" class="primary-medium-btn recorder-button" :disabled="!finishedRecording">
      <md-icon>clear</md-icon>
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      audioFile: {
        file: null,
        localPath: "",
        isTranscribe: true,
        metadata: {
          contentType: "",
        },
      },

      wakeLock: null,

      isRecording: false,
      finishedRecording: false,
      mediaStream: null,
      mediaRecorder: null,
      audioChunks: [],
      audioUrl: null,
      maxFileSizeInBytes: 1024 * 1024 * 1024, // 1 GiB
    };
  },
  methods: {
    async startRecording() {
      try {
        if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
          alert("Your browser does not support audio recording");
          return;
        }

        try {
          const permission = await navigator.permissions.query({ name: 'microphone' });
          if (permission.state === 'denied') {
            alert("Microphone access was denied. Please enable it in your browser settings.");
            return;
          }
        } catch (error) {
          console.warn("Could not request microphone permissions, trying to get stream anyway");
        }

        this.resetRecordingState();
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        this.mediaStream = stream;
        this.mediaRecorder = new MediaRecorder(stream);
        this.mediaRecorder.ondataavailable = this.handleDataAvailable;
        this.mediaRecorder.onstop = this.handleRecordingStop;
        this.mediaRecorder.start();
        this.isRecording = true;
        this.requestWakeLock();
      } catch (error) {
        console.error("Error starting recording:", error);
        alert(
          "An error occurred while starting the recording. Please check your microphone permissions and try again."
        );
      }
    },
    stopRecording() {
      if (this.mediaRecorder && this.isRecording) {
        this.mediaRecorder.stop();
        this.isRecording = false;
        this.releaseWakeLock();
        if (this.mediaStream) {
          this.mediaStream.getTracks().forEach(track => track.stop());
          this.mediaStream = null;
        }
      }
    },
    toggleRecording() {
      if (this.isRecording) {
        this.stopRecording();
      } else {
        this.startRecording();
      }
    },
    handleDataAvailable(event) {
      if (event.data.size > 0) {
        this.audioChunks.push(event.data);
      }
    },
    handleRecordingStop() {
      const timestamp = new Date().toISOString();
      const fileName = `recording_${timestamp}.wav`;
      this.audioFile.file = new File(this.audioChunks, fileName, {
        type: "audio/wav",
      });
      this.audioFile.metadata.contentType = this.audioFile.file.type;
      this.audioUrl = URL.createObjectURL(this.audioFile.file);
      this.finishedRecording = true;
    },
    uploadRecording() {
      this.uploadAudioBlob();
    },
    async uploadAudioBlob() {
      if (!this.audioFile.file) {
        return;
      }

      const tooBig = this.audioFile.file.size > this.maxFileSizeInBytes;
      this.$emit("set-audio-too-big", tooBig);
      if (tooBig) {
        return;
      }

      this.$emit("set-audio", this.audioFile);
    },
    async downloadRecording() {
      if (this.audioFile.file) {
        const url = URL.createObjectURL(this.audioFile.file);
        const link = document.createElement("a");
        link.href = url;
        link.download = this.audioFile.file.name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url); // Clean up the URL object
      }
    },
    resetRecordingState() {
      this.audioChunks = [];
      this.audioFile.file = null;
      this.audioUrl = null;
      this.finishedRecording = false;
    },

    async requestWakeLock() {
      try {
        this.wakeLock = await navigator.wakeLock.request("screen");
        console.log("wakeLock aquired");
        this.wakeLock.addEventListener("release", () => {
          console.log("wakeLock released");
        });
      } catch (error) {
        console.log("could not request wake lock");
      }
    },
    releaseWakeLock() {
      if (this.wakeLock !== null) {
        this.wakeLock.release().then(() => {
          this.wakeLock = null;
        });
      }
    }
  },
};
</script>

<style scoped>
.recorder-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
}

.recorder-button {
  margin: 10px;
  cursor: pointer;
}

.recorder-button.recording {
  background-color: red;
  /* Change this to your desired recording color */
  color: white;
  /* Optional: Change text color when recording */
}

.recorder-button:disabled {
  cursor: not-allowed;
  background-color: rgb(190, 190, 190);
}

.recorder-audio {
  height: 50px;
}

.icon-recording {
  margin: 0 !important;
}

.md-icon {
  margin: none !important;
}

@media (max-width: 876px) {
  .recorder-container {
    flex-direction: column;
  }
}
</style>
