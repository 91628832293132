<template>
    <div class="name-exists-error-container">
        <span class="name-exists-error-text">{{ $t("nameExistsError.errorMessage") }}</span>
    </div>
</template>

<script>
export default {
  name: 'NameExistsError',
};
</script>

<style scoped>
    .name-exists-error-text {
        color: red;
    }
    .name-exists-error-container {
      line-height: .9em;
      margin-top: 1px !important;
    }
</style>
