import ApiService from './api.service';
import { TokenService } from './token.service';

class AuthenticationError extends Error {
  constructor(errCode, msg) {
    super(msg);
    this.name = this.constructor.name;
    this.message = msg;
    this.errorCode = errCode;
  }
}

const UserService = {
  async login(email, password, mfa_token) {
    const reqData = {
      email: email,
      password: password,
      mfa_token: mfa_token
    };
    return ApiService.post('/User.authenticate', reqData).then((response) => {
      return response;
    }).catch((err) => {
      throw new AuthenticationError(err.response.status, err.response.data.error);
    });
  },

  async refreshAccessToken() {
    const reqData = {
      method: 'post',
      url: '/User.refresh_token',
      headers: {
        Authorization: `Bearer ${TokenService.getRefreshToken()}`,
      },
    };
    return ApiService.custom(reqData).then((response) => {
      TokenService.saveAccessToken(response.data.access_token);
      ApiService.setHeader();
      return response;
    }).catch((err) => {
      throw new AuthenticationError(err.response.status, err.response.data.detail);
    });
  },

  logout() {
    TokenService.removeAccessToken();
    TokenService.removeRefreshToken();
    TokenService.removeIsAdmin();
    TokenService.removeIsPoweruser();
    ApiService.removeHeader();
    ApiService.unmount401interceptor();
  },
};

export default UserService;
export { UserService, AuthenticationError };
