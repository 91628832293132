<template>
  <div class="load-audio">
    <md-field>
      <label>{{ $t("loadAudio.openFile") }}</label>
      <md-file accept="audio/*,video/*,.wav,.m4a,.mp3,.mp4,.ogg,.avi,.wmv" id="audioFileInput"
        @change="setAudioFile($event)" />
    </md-field>
  </div>
</template>

<script>
export default {
  name: "AudioLoader",
  data() {
    return {
      audioFile: {
        file: null,
        localPath: "",
        isTranscribe: true,
        metadata: {
          contentType: "",
        },
      },
      maxFileSizeInBytes: 1024 * 1024 * 1024 // 1 GiB
    };
  },
  methods: {
    setAudioFile(event) {
      // Abort if file input is cancelled
      if (event.target.files[0] === undefined) {
        return;
      }

      this.audioFile.file = event.target.files[0];

      // Abort if file size is too big
      var tooBig = this.audioFile.file.size > this.maxFileSizeInBytes
      this.$emit("set-audio-too-big", tooBig);
      if (tooBig === true) {
        return;
      }

      this.audioFile.localPath = this.audioFile.file.path;
      this.audioFile.metadata.contentType = event.target.files[0].type;

      this.$emit("set-audio", this.audioFile);
    }
  },
};
</script>
