<template>
  <div id="details-container">
    <span class="md-display-2" id="details-container--project-name">{{
      project.name
    }}</span>
    <md-list>
      <md-list-item>
        <div id="label">{{ $t("projectDetails.labels.projectId") }}</div>
        <div id="value">{{ project.id }}</div>
      </md-list-item>
      <md-list-item>
        <div id="label">{{ $t("projectDetails.labels.created") }}</div>
        <div id="value">{{ getFormatedDateTimeString(project.date) }}</div>
      </md-list-item>
      <md-list-item>
        <div id="label">{{ $t("projectDetails.labels.fileName") }}</div>
        <div id="value">{{ project.file_name }}</div>
      </md-list-item>
      <md-list-item>
        <div id="label">{{ $t("projectDetails.labels.fileLength") }}</div>
        <div id="value">{{ roundTo2Decimals(project.durationInMinutes) }}</div>
      </md-list-item>
      <md-list-item>
        <div id="label">{{ $t("projectDetails.labels.lastEdited") }}</div>
        <div id="value">{{ getFormatedDateTimeString(project.last_updated) }}</div>
      </md-list-item>
      <md-list-item>
        <div id="label">{{ $t("projectDetails.labels.numberOfSpeakers") }}</div>
        <div id="value">{{ project.numberOfSpeakers }}</div>
      </md-list-item>
      <md-list-item>
        <div id="label">{{ $t("projectDetails.labels.language") }}</div>
        <div id="value">{{ project.language }}</div>
      </md-list-item>
      <md-list-item>
        <div id="label">{{ $t("projectDetails.labels.diarizationEngines") }}</div>
        <div id="value">{{ project.diarization_backend }}</div>
      </md-list-item>
      <md-list-item>
        <div id="label">{{ $t("projectDetails.labels.transcriptionEngines") }}</div>
        <div id="value">{{ project.transcription_backend }}</div>
      </md-list-item>
    </md-list>
  </div>
</template>

<script>
export default {
  name: 'ProjectDetails',
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
  methods: {
    roundTo2Decimals(number) {
      return Math.round((number + Number.EPSILON) * 100) / 100;
    },
    getFormatedDateTimeString(date) {
      return new Date(date).toLocaleString();
    },
  },
};
</script>

<style lang="scss">
#details-container--project-name {
  margin: 15px;
}
</style>
