<template>
  <md-dialog
    :md-active.sync="gapEditorDialogActive"
    v-drag
    class="drag-element">
    <md-dialog-title>{{ $t('gapEditorDialog.title') }}</md-dialog-title>

    <div class="md-dialog-content">
      <div class="md-layout-item">
        <div>{{ $t('gapEditorDialog.gapDescription', {
            length: gapLength.toFixed(2).toString() + "sec",
            start: parseSeconds(startTime).toString().replace(" ", "").replace("s", "sec"),
            end: parseSeconds(endTime).toString().replace(" ", "").replace("s", "sec")
          }) }}
        </div>
      </div>
      <div class="md-layout-item gap-editor-element">
        <span style="font-weight: bold">{{ speakerName }}</span>{{ ': "' + gapSubtext + '"' }}
      </div>
      <div class="md-layout-item audio-player-container">
        <audio
          class="play"
          ref="htmlPlayerMini"
          :src="audioSrc"
          v-on:timeupdate="monitorGapEnd"
          v-on="looped ? {ended: playGap} : {}"
        ></audio>
        <div>
          <button v-on:click="playGap" class="player-button">
            <img :src="isPlaying ? pauseIcon : playIcon" class="svg-img">
            <md-tooltip md-direction="top">{{ $t('gapEditorDialog.startPlayback') }}</md-tooltip>
          </button>
        </div>
        <input type="checkbox" id="checkbox" v-model="setLooped">
        <label for="checkbox">{{ $t('gapEditorDialog.checkboxText') }}</label>
      </div>
      <div class="md-layout-item gap-editor-element">
        <label for="gapType" id="gapType" class="gap-label">{{ $t('gapEditorDialog.changeGapType') }}</label>
        <div>
          <md-radio v-model="selectedGapType" @change="onTypeChange" value="NO SPEECH" class="radio-gap-type md-primary">{{ $t('gapEditorDialog.noSpeech') }}</md-radio>
          <md-radio v-model="selectedGapType" @change="onTypeChange" value="PAUSE" class="radio-gap-type md-primary">{{ $t('gapEditorDialog.singleSpeaker') }}</md-radio>
          <md-radio v-model="selectedGapType" @change="onTypeChange" value="PARALLEL SPEECH" class="radio-gap-type md-primary">{{ $t('gapEditorDialog.parallel') }}</md-radio>
        </div>
        <div class="gap-editor-element">
          <div class="gap-label">{{ $t('gapEditorDialog.resolveGap') }}</div>
          <div class="md-layout-item gap-resolve">{{ $t('gapEditorDialog.resolveText') }}</div>
          <div ref="editForm" class="edit-gap-input-container">
            <div class="edit-gap-speaker-container">{{ speakerName }}</div>
            <input
              class="edit-gap-input-field"
              @input="singleSpeakerText = $event.target.value; onTypeChange()"
            >
          </div>
        </div>
      </div>

      <md-dialog-actions>
        <md-button class="md-primary" @click="closeDialog">
          Cancel
        </md-button>
        <md-button
          class="md-primary"
          @click="resolveGap"
          :disabled="isApplyDisabled"
        >
          Apply
        </md-button>
      </md-dialog-actions>
    </div>
  </md-dialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import playIcon from '../assets/svgs/regular/play-circle.svg';
import pauseIcon from '../assets/svgs/regular/pause-circle.svg';

export default {
  name: 'GapEditorDialog',
  props: [
    'document',
    'speakerId',
    'phrase',
    'gapSubtext',
  ],
  data() {
    return {
      selectedGapType: '',
      initialGapType: '',
      speakerName: '',
      gapLength: 0.0,
      startTime: 0.0,
      endTime: 0.0,
      playPromise: undefined,
      isPlaying: false,
      looped: false,
      isSingleSpeaker: false,
      singleSpeakerText: null,
      isApplyDisabled: true,
      playIcon,
      pauseIcon,
    };
  },
  computed: {
    ...mapGetters('JWT', ['access_token']),
    ...mapGetters('transcript', [
      'getInterviewFilePath',
    ]),
    audioSrc() {
      return `${this.getInterviewFilePath}&jwt=${this.access_token}`;
    },
    gapEditorDialogActive: {
      set(value) {
        if (!value) {
          this.isPlaying = false;
          this.$emit('gap-editor-dialog-close');
        }
      },
      get() {
        return true;
      },
    },
    setLooped: {
      set(checked) {
        this.looped = checked;
      },
      get() {
        return this.looped;
      },
    },
  },
  methods: {
    ...mapGetters('transcript', [
      'getSpeakerNameById',
    ]),
    ...mapActions('transcript', [
      'updateDocument',
    ]),
    ...mapMutations('transcript', [
      'setTranscriptDocument',
    ]),
    onTypeChange() {
      this.isApplyDisabled = this.selectedGapType === this.initialGapType && !this.singleSpeakerText;
    },
    playGap() {
      if (!this.isPlaying) {
        this.isPlaying = true;
        this.$refs.htmlPlayerMini.currentTime = this.startTime - 1;
        this.playPromise = this.$refs.htmlPlayerMini.play();
      } else {
        if (this.playPromise !== undefined) {
          this.playPromise.then(_ => {
            this.isPlaying = false;
            this.$refs.htmlPlayerMini.pause();
          })
            .catch(error => {
              this.isPlaying = false;
              this.$notify({ text: error });
            });
        }
      }
    },
    monitorGapEnd() {
      if (this.isPlaying && this.$refs.htmlPlayerMini.currentTime >= this.endTime + 1) {
        this.isPlaying = false;
        this.$refs.htmlPlayerMini.pause();
        this.$refs.htmlPlayerMini.dispatchEvent(new Event('ended'));
      }
    },
    setTypeNoSpeech() {
      this.$emit('set-gap-no-speech');
    },
    setTypeParallelSpeech() {
      this.$emit('set-gap-parallel-speech');
    },
    setTypeSingleSpeaker() {
      this.$emit('set-gap-pause');
    },
    setGapText() {
      this.$emit('set-gap-word', this.singleSpeakerText);
    },
    editTranscript() {
      const document = JSON.parse(JSON.stringify(this.document));
      for (const frameId in document.content[0].content) {
        if (Object.prototype.hasOwnProperty.call(document.content[0].content, frameId)) {
          if (document.content[0].content[frameId].attrs.endTime > this.startTime) {
            const frame = document.content[0].content[frameId].content;
            for (const phraseId in frame) {
              if (Object.prototype.hasOwnProperty.call(frame, phraseId)) {
                if (frame[phraseId].attrs.startTime === this.startTime && frame[phraseId].attrs.startTime === this.startTime) {
                  if (this.singleSpeakerText) {
                    this.setGapText();
                  } else if (this.selectedGapType === 'PAUSE') {
                    this.setTypeSingleSpeaker();
                  } else if (this.selectedGapType === 'NO SPEECH') {
                    this.setTypeNoSpeech();
                  } else if (this.selectedGapType === 'PARALLEL SPEECH') {
                    this.setTypeParallelSpeech();
                  }
                  break;
                }
              }
            }
            break;
          }
        }
      }
    },
    closeDialog() {
      this.gapEditorDialogActive = false;
    },
    resolveGap() {
      if (this.selectedGapType) {
        this.editTranscript();
      }
      this.closeDialog();
    },
  },
  mounted() {
    const start = this.phrase.getAttribute('starttime');
    const end = this.phrase.getAttribute('endtime');
    this.startTime = parseFloat(start);
    this.endTime = parseFloat(end);
    this.gapLength = end - start;
    this.gapEditorDialogActive = true;
    const getName = this.getSpeakerNameById();
    this.speakerName = getName(this.speakerId);
    this.selectedGapType = this.initialGapType = this.phrase.getAttribute('type');
  },
};
</script>

<style scoped>
.drag-element {
  max-width: min-content;
  max-height: min-content;
  position: absolute;
  left: calc(50% - 235px);
  right: 0;
  top: 60px;
}
.svg-img {
  width: 40px;
  height: 40px;
}
.player-button {
  cursor: pointer;
  border: none;
  background-color: transparent;
}
.radio-gap-type {
  display: flex;
  align-items: center;
}
.audio-player-container {
  display: flex;
  align-items: flex-end;
  padding-top: 16px;
}
.gap-editor-element {
  padding-top: 25px;
}
.md-dialog ::v-deep.md-dialog-container {
  max-width: 470px !important;
  width: 470px;
}
.edit-gap-input-container {
  display: flex;
  padding: 5px;
  align-items: center;
  margin-right: 25px;
}
.edit-gap-input-field {
  width: 80%;
  padding: 5px;
}
.edit-gap-speaker-container {
  padding-right: 10px;
  font-weight: bold;
}
.md-radio ::v-deep.md-radio-label {
  display: flex !important;
}
.md-dialog-content {
  padding-bottom: 12px;
}
.description {
  margin-bottom: 20px;
}
.gap-label {
  font-weight: bold;
  margin-top: 16px;
}
.gap-resolve {
  margin-top: 16px;
}
</style>
