import ApiService from '../../services/api.service';
import { UserService } from '../../services/user.service';
import { TokenService } from '../../services/token.service';

const state = {
  access_token: TokenService.getAccessToken(),
  refresh_token: TokenService.getRefreshToken(),
  is_admin: TokenService.isAdmin(),
  is_poweruser: TokenService.isPoweruser(),
};

const getters = {
  access_token: state => state.access_token,
  refresh_token: state => state.refresh_token,
  loggedIn: state => state.refresh_token !== '',
  isAdmin: state => state.is_admin,
  isPoweruser: state => state.is_poweruser,
};

const mutations = {
  setJWT(state, jwt) {
    // When this updates, the getters and anything bound to them updates as well.
    state.access_token = jwt.access_token;
    state.refresh_token = jwt.refresh_token;
    state.is_admin = jwt.is_admin;
    state.is_poweruser = jwt.is_poweruser;
  },

  setAccessToken(state, token) {
    state.access_token = token;
  },

  clearAllTokens(state) {
    state.access_token = '';
    state.refresh_token = '';
  },
};

const actions = {
  async login({commit}, {email, password, mfa_token}) {
    const response = await UserService.login(email, password, mfa_token);
    // check mfa
    if (response.status == 202 && "mfa" in response.data) {
      return response.data.mfa;
    }

    // set token in local storage
    TokenService.saveAccessToken(response.data.access_token);
    TokenService.saveRefreshToken(response.data.refresh_token);
    TokenService.setAdmin(response.data.is_admin);
    TokenService.setIsPoweruser(response.data.is_poweruser);

    // set jwt in authentication header
    ApiService.setHeader();
    ApiService.mount401interceptor();
    
    commit('setJWT', response.data);

    return "";
  },

  async refreshJWT({ commit }) {
    const response = await UserService.refreshAccessToken();
    commit('setAccessToken', response.data.access_token);
  },

  async refreshAccessToken({ commit }, token) {
    commit('setAccessToken', token);
  },

  async logout({ commit }) {
    UserService.logout();
    commit('clearAllTokens');
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
