<template>
  <notifications :duration="5000" id="alerts" width="25%">
    <template slot="body" slot-scope="props">
      <div class="alert md-elevation-6">
        <md-content :class="classFor(props.item)">
            <div class="close-button">
              <md-button @click="props.close" class="md-icon-button">
                <md-icon>close</md-icon>
              </md-button>
            </div>
          <div class="md-layout md-gutter md-alignment-center-space-between">
            <div class="md-layout-item md-size-90">
              <span class="md-title">{{title(props.item)}}</span>
              <br/>
              <span class="md-body-1">
                  {{props.item.text}}
                </span>
            </div>
          </div>
        </md-content>
      </div>
    </template>
  </notifications>
</template>

<script>
export default {
  name: 'Alert',
  methods: {
    title(item) {
      if (item.title) {
        return item.title;
      }

      const type = item.type || 'error';
      const titles = this.$t('alert.titles');
      if (type in titles) {
        return titles[type];
      }
      throw new Error(`Unknown type: ${type}`);
    },

    classFor(item) {
      const type = item.type || 'error';
      return `item-${type}`;
    },
  },
};
</script>

<style lang="scss" scoped>
  #alerts {
    margin-top: 64px;
    min-width: 20em;
  }

  .alert {
    margin: 10px;
    z-index: 10;
    text-align: left;

    > .md-content {
      position: relative;
    }
  }

  .md-content {
    padding: 15px;

    &.item-error {
      background-color: $color-error;
      color: $color-error-contrast;
    }

    &.item-warn {
      background-color: $color-warn;
      color: $color-warn-contrast;
    }

    &.item-success {
      background-color: $color-success;
      color: $color-success-contrast;
    }

    &.item-info {
      background-color: $color-primary;
      color: $color-primary-contrast;
    }
  }
</style>
