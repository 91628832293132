<template>
  <div class="snackbar">
    <md-snackbar :md-position="snackbar.position" :md-duration="snackbar.duration" :md-active.sync="snackbar.show" md-persistent>
      {{message}}<slot />
    </md-snackbar>
  </div>
</template>

<script>
export default {
  name: 'Snackbar',
  data() {
    return {
      snackbar: {
        show: false,
        duration: 5000,
        position: 'center',
      },
      type: '',
    };
  },
  methods: {
    open(type) {
      this.type = type;
      this.snackbar.show = true;
    },
  },
  computed: {
    message() {
      if (this.type === 'success') {
        return this.$t('snackbar.success') + ': ';
      }
      if (this.type === 'error') {
        return this.$t('snackbar.error') + ': ';
      }
      return '';
    },
  },
};
</script>

<style scoped>

</style>
