<template>
  <div v-if="noPaymentsAvailable" class="md-alignment-center-center">
    {{ $t("billingTab.noPaymentsAvailable") }}
  </div>
  <DataTable
    v-else
    id="billing-table"
    :title="$t('billingTab.title')"
    :columns="tableColumns"
    :rows="tableRows"
    :exportable="true"
  >
    <template v-slot:thead-tr
      ><th>{{ $t("billingTab.receipt") }}</th></template
    >
    <template v-slot:tbody-tr="props">
      <td>
        <a :href="props.row.receipt_url" target="_blank">Download</a>
      </td>
    </template>
  </DataTable>
</template>

<script>
import { mapActions } from "vuex";
import DataTable from "../../node_modules/vue-materialize-datatable/src/DataTable.vue";
import { formatDateUnix } from "../utils/time";

export default {
  name: "BillingTab",
  components: {
    DataTable,
  },
  props: {
    userId: Number,
  },
  data() {
    return {
      tableColumns: [
        {
          label: this.$t("billingTab.paymentId"),
          field: "payment_id",
          numeric: true,
          html: false,
        },
        {
          label: this.$t("billingTab.created"),
          field: "created",
          numeric: false,
          html: false,
        },
        {
          label: this.$t("billingTab.amount"),
          field: "amount",
          numeric: false,
          html: false,
        },
        {
          label: this.$t("billingTab.status"),
          field: "status",
          numeric: false,
          html: false,
        },
      ],
      tableRows: [],
      noPaymentsAvailable: false,
    };
  },
  methods: {
    ...mapActions("payment", ["fetchPayment", "fetchPaymentForUser"]),
    restructurePayment(payment) {
      return {
        ...payment,
        // milliseconds to unix seconds
        created: formatDateUnix(payment.created * 1000),
      };
    },
  },
  async mounted() {
    let payments;
    if (this.userId) {
      payments = await this.fetchPaymentForUser(this.userId).catch((e) =>
        this.$notify({ text: e })
      );
    } else {
      payments = await this.fetchPayment().catch((e) =>
        this.$notify({ text: e })
      );
    }
    this.tableRows = payments.map(this.restructurePayment);
    this.noPaymentsAvailable = this.tableRows.length === 0;
  },
};
</script>

<style lang="scss">
#billing-table > table {
  width: 100%;

  th {
    text-align: left;
    color: initial;
  }
  td {
    text-align: left;
    color: initial;
  }
}

ul.material-pagination {
  list-style: none;
}
</style>
