<template>
    <md-field :class="invalidClass" :md-toggle-password="isToggle">
        <label :key="keyValue">{{label}}</label>
        <md-input
                type="password"
                :name="name"
                :id="id"
                :autocomplete="id"
                v-model="password"
                @input="$emit('update-valid-user')"
                :validator="validator"
                :disabled="disabled">
        </md-input>
    </md-field>
</template>

<script>
export default {
  name: 'ChangePasswordForm',
  props: [
    'name',
    'isToggle',
    'id',
    'validator',
    'disabled',
    'invalidClass',
    'keyValue',
    'label',
  ],
  data: () => {
    return {
      password: '',
    };
  },
  watch: {
    password() {
      if (this.password) {
        this.$emit('field-value', this.password);
      }
    },
  },
};
</script>
