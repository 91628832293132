<template>
  <div>
    <div v-if="$i18n.locale === 'en'" class="video-wrapper">
      <div class="video-caption-wrapper">
        <div class="video-container">
          <iframe
            width="220"
            height="110"
            src="https://www.youtube.com/embed/SwvGzsid7DE"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            frameborder="0"
            allowfullscreen>
          </iframe>
        </div>
        <div class="video-caption">{{ $t("welcomeVideos.interscriberExplained") }}</div>
      </div>
      <div class="video-caption-wrapper">
        <div class="video-container">
          <iframe
            width="220"
            height="110"
            src="https://www.youtube.com/embed/-TihKcj3e7s"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            frameborder="0"
            allowfullscreen>
          </iframe>
        </div>
        <div class="video-caption">{{ $t("welcomeVideos.smartFeatures") }}</div>
      </div>
    </div>
    <div v-else class="video-wrapper">
      <div class="video-caption-wrapper">
        <div class="video-container">
          <iframe
            width="220"
            height="110"
            src="https://www.youtube.com/embed/sN_N9js8vp8"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen>
          </iframe>
        </div>
        <div class="video-caption">{{ $t("welcomeVideos.interscriberExplained") }}</div>
      </div>
      <div class="video-caption-wrapper">
        <div class="video-container">
          <iframe
            width="220"
            height="110"
            src="https://www.youtube.com/embed/peo__lhJJRA"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen>
          </iframe>
        </div>
        <div class="video-caption">{{ $t("welcomeVideos.smartFeatures") }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WelcomeVideos',
};
</script>

<style scoped>
.video-container {
  margin: 16px 16px 0 16px;
  width: 220px;
  height: 150px;
  overflow: hidden;
}
.video-caption {
  color: grey;
}
.video-caption-wrapper {
  margin-bottom: 16px;
}
.video-wrapper {
  display: flex;
  justify-content: center;
  text-align: center;
}
</style>
