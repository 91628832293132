import { AudioBus } from "../audioBus";
import i18n from "../i18n";
import _ from "lodash";

export default {
  data() {
    return {
      keyboardEvents: ["keydown", "keypress", "keyup"],
    };
  },

  mounted() {
    this.keyboardEvents.forEach((event) =>
      document.addEventListener(event, this.keyboardShortcutHandler)
    );

    document.addEventListener("keyup", this.clearLastCommandCode);
  },

  beforeDestroy() {
    this.keyboardEvents.forEach((event) =>
      document.removeEventListener(event, this.keyboardShortcutHandler)
    );

    document.removeEventListener("keyup", this.clearLastCommandCode);
  },

  methods: {
    keyboardShortcutHandler(event) {
      const { altKey, type, code } = event;

      // if (altKey) event.preventDefault()
      if (!altKey || type !== "keydown" || code === this.lastCommandCode)
        return;

      this.lastCommandCode = code;

      if (/^Digit\d$/.test(code))
        this.assignSpeakerByOrderShortcut(Number(_.last(code)), event);
      else this.defineShortcutCommand(code, event);
    },

    defineShortcutCommand(code, event) {
      switch (code) {
        case "KeyS":
          event.preventDefault();
          return AudioBus.$emit("toggle");
        case "KeyP":
          return this.playFromCursorPositionShortcut(event);
        case "KeyR":
          return this.continuousPlaybackShortcut(event);
        case "KeyT":
          return this.toggleCapitalizationShortcut(event);
        case "ArrowUp":
          return this.assignToSpeakerShortcut("above", event);
        case "ArrowDown":
          return this.assignToSpeakerShortcut("below", event);
      }
    },

    assignSpeakerByOrderShortcut(order, event) {
      try {
        const speaker = this.getSpeakers[order - 1];
        if (!speaker)
          this.$notify({
            text: i18n.t("editor.shortcuts.noSpeaker", { order }),
          });
        else this.changeSpeakerForCurrentSelection(speaker.id);
      } catch (error) {
        console.log(error);
        this.$notify({ text: i18n.t("editor.shortcuts.noSelection") });
      }
      event.preventDefault();
    },

    toggleCapitalizationShortcut(event) {
      this.toggleCase();
      event.preventDefault();
    },

    assignToSpeakerShortcut(direction, event) {
      this.mergeByDirection(direction);
      event.preventDefault();
    },

    playFromCursorPositionShortcut(event) {
      this.playFromCursorPosition();
      event.preventDefault();
    },

    continuousPlaybackShortcut(event) {
      this.playSelectionLoopy();
      event.preventDefault();
    },

    clearLastCommandCode() {
      this.lastCommandCode = "";
    },
  },
};
