<template>
  <md-dialog
    :md-active.sync="dialogActive">
    <md-dialog-title>{{ $t('editor.speakerSettingsDialog.title') }}</md-dialog-title>

    <div class="md-dialog-content">
      <div class="description">{{ $t('editor.speakerSettingsDialog.content') }}</div>
      <div v-for="speaker in getSpeakers" :key="speaker.id">
        <md-field>
          <md-input type="text" :value="speaker.name"
                    @input="changeSpeakerName({ speakerId: speaker.id, name: $event })"
          ></md-input>
          <md-button @click="removeSpeaker({ speakerId: speaker.id })"
                     :disabled="speakersInUse.has(speaker.id)"
          >
            <md-icon>delete</md-icon>
          </md-button>
          <md-tooltip v-if="speakersInUse.has(speaker.id)" class="adjust-height" md-direction="top" md-delay="300">
            {{ $t('editor.speakerSettingsDialog.speakerInUse') }}
          </md-tooltip>
        </md-field>
      </div>

      <md-field>
        <label>{{ $t('editor.speakerSettingsDialog.newSpeaker') }}</label>
        <md-input type="text" v-model="newSpeakerName" @keydown.space.stop=""></md-input>
        <md-button class="md-primary" @click="addNewSpeaker">{{ $t('editor.speakerSettingsDialog.newSpeakerButton') }}
        </md-button>
      </md-field>
    </div>

    <md-dialog-actions>
      <md-button class="md-primary" @click="closeDialog">
        {{ $t('buttons.okey') }}
      </md-button>
    </md-dialog-actions>
  </md-dialog>
</template>

<script>
import { ListItem } from 'tiptap-extensions';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'SpeakerSettings',
  props: {
  },
  data() {
    return {
      speakers: [],
      dialogActive: false,
      newSpeakerName: '',
      speakersInUse: new Set(),
    };
  },
  mounted() {
  },
  methods: {
    ...mapActions('transcript', [
      'addSpeaker',
      'removeSpeaker',
      'changeSpeakerName',
    ]),
    openSpeakerSettingsDialog(speakersInUse) {
      this.speakersInUse = speakersInUse;
      this.dialogActive = true;
    },
    addNewSpeaker() {
      if (this.newSpeakerName !== '') {
        this.addSpeaker({ name: this.newSpeakerName });
        this.newSpeakerName = '';
      }
    },
    closeDialog() {
      this.dialogActive = false;
    },
  },
  computed: {
    ...mapGetters('transcript', [
      'getSpeakers',
    ]),
  },
};
</script>

<style scoped>
  .md-dialog-content {
    padding-bottom: 0
  }

  .description {
    margin-bottom: 20px;
  }
</style>
