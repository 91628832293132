<template>
  <div id="app">
    <Alert />
    <router-view />
  </div>
</template>

<script>
import Alert from "./components/Alert";

export default {
  name: "App",
  components: {
    Alert,
  },
};
</script>

<style lang="scss">
@import "~@fontsource/nunito-sans/index.css";

#app {
  font-family: "Nunito Sans", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #142b41;
}

.close-button {
  position: absolute;
  right: 0px;
  z-index: 10;
}
</style>

<style lang="scss">
.primary-large-btn {
  background-color: $color-primary;
  font-size: 1.5em;
  font-weight: 800;
  color: #fff;
  padding: 0.5em 1em;
  border-radius: 0.5em;
  border-color: transparent;
  cursor: pointer;

  &:disabled {
    background-color: #ffb1bc;
    cursor: default;
  }

  &:hover:enabled {
    background-color: $color-primary-dark;
  }

  .md-icon.md-theme-default.md-icon-font {
    color: #fff;
  }
}

.primary-medium-btn {
  background-color: $color-primary;
  font-size: 1em;
  font-weight: 600;
  color: #fff;
  padding: 0.5em 1em;
  border-radius: 0.5em;
  border-color: transparent;
  cursor: pointer;

  &:disabled {
    background-color: #ffb1bc;
    cursor: default;
  }

  &:hover:enabled {
    background-color: $color-primary-dark;
  }

  .md-icon.md-theme-default.md-icon-font {
    color: #fff;
  }
}

.md-error,
.error-class {
  color: $color-error;
}

.clickable {
  cursor: pointer;
}
</style>
