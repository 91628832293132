<template>
  <div v-if="noProjectsAvailable" class="md-alignment-center-center">
    {{ $t("projectsTable.noProjectsAvailable") }}
  </div>
  <DataTable
    v-else
    id="projects-table"
    :title="$t('projectsTable.title')"
    :columns="tableColumns"
    :rows="tableRows"
    :exportable="true"
  >
    <template v-slot:thead-tr>
      <th>
        {{ $t("projectsTable.details") }}
      </th>
    </template>
    <template v-slot:tbody-tr="props">
      <td>
        <md-button class="md-icon-button" @click="props.row.showDetails = true">
          <md-icon>info</md-icon>
        </md-button>
        <md-dialog :md-active.sync="props.row.showDetails">
          <ProjectDetails
            id="project-details-modal"
            :project="props.row"
          ></ProjectDetails>
          <md-dialog-actions>
            <md-button
              class="md-primary"
              @click="props.row.showDetails = false"
            >
              {{ $t("projectsTable.closeDetails") }}
            </md-button>
          </md-dialog-actions>
        </md-dialog>
      </td>
    </template>
  </DataTable>
</template>

<script>
import { mapActions } from 'vuex';
import DataTable from '../../node_modules/vue-materialize-datatable/src/DataTable.vue';
import ProjectDetails from './ProjectDetails';

export default {
  name: 'ProjectsTable',
  components: {
    DataTable,
    ProjectDetails,
  },
  props: {
    userId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      tableColumns: [
        {
          label: this.$t('projectsTable.projectId'),
          field: 'id',
          numeric: true,
          html: false,
        },
        {
          label: this.$t('projectsTable.created'),
          field: 'createdString',
          numeric: false,
          html: false,
        },
        {
          label: this.$t('projectsTable.projectName'),
          field: 'name',
          numeric: false,
          html: false,
        },
        {
          label: this.$t('projectsTable.numberOfSpeakers'),
          field: 'numberOfSpeakers',
          numeric: true,
          html: false,
        },
        {
          label: this.$t('projectsTable.diarizationEngines'),
          field: 'diarization_backend',
          numeric: false,
          html: false,
        },
        {
          label: this.$t('projectsTable.transcriptionEngines'),
          field: 'transcription_backend',
          numeric: false,
          html: false,
        },
      ],
      tableRows: [],
      noProjectsAvailable: false,
      showDetails: false,
    };
  },
  methods: {
    ...mapActions('transcript', ['fetchTranscriptsForUser']),
    restructureTranscript(transcript) {
      return {
        ...transcript,
        numberOfSpeakers: transcript.speakers.length,
        durationInMinutes: transcript.audio_length / 60,
        createdString: new Date(transcript.date).toLocaleString(),
        updatedString: new Date(transcript.last_updated).toLocaleString(),
        showDetails: false,
      };
    },
  },
  async mounted() {
    const transcripts = await this.fetchTranscriptsForUser(this.userId).catch((e) =>
      this.$notify({ text: e }),
    );
    this.tableRows = transcripts.map(this.restructureTranscript);
    this.noProjectsAvailable = this.tableRows.length === 0;
  },
};
</script>

<style lang="scss">
#projects-table > table {
  width: 100%;

  th {
    text-align: left;
    color: initial;
  }

  tr {
    text-align: left;
  }
}

.md-dialog ::v-deep.md-dialog-container {
  width: 768px;
}
</style>
