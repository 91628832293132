<template>
  <Page>
    <div
      class="md-layout md-gutter md-alignment-top-space-around load-file-section"
    >
      <div class="md-layout-item md-size-100">
        <md-tabs>
          <md-tab md-label="Users">
            <md-card class="md-layout-item md-size-100 md-small-size-100">
              <md-card-content>
                <UserList />
              </md-card-content>
            </md-card>
          </md-tab>
          <md-tab md-label="Orphans">
            <md-card class="md-layout-item md-size-100 md-small-size-100">
              <md-card-content>
                <OrphanedAudioFiles />
              </md-card-content>
            </md-card>
          </md-tab>
          <md-tab md-label="Settings">
            <md-card class="md-layout-item md-size-100 md-small-size-100">
              <md-card-content>
                <AdminSettings />
              </md-card-content>
            </md-card>
          </md-tab>
        </md-tabs>
      </div>
    </div>
  </Page>
</template>
<script>
import Page from "../components/Page";
import UserList from "../components/UserList";
import OrphanedAudioFiles from "../components/OrphanedAudioFiles";
import AdminSettings from "../components/AdminSettings";

export default {
  components: {
    Page,
    UserList,
    OrphanedAudioFiles,
    AdminSettings,
  },
};
</script>
<style scoped>
.md-tabs-content {
  height: 65vh !important;
  overflow-y: auto;
}
.md-ripple .md-button-content {
  font-size: 16px;
}
</style>
