import Vue from "vue";
import ApiService from "../../services/api.service";
import _ from "lodash";

const state = {
  transcriptionCost: 0,
  availableLanguages: [],
  transcripts: [],
  interviewFilePath: "",
  audioMetaData: {
    id: null,
    userId: null,
    channelCount: null,
    fileType: "",
    sampleRate: null,
    length: null,
    uploadName: "",
    language: "English",
    speakerCount: 2,
    clueWords: "",
    backend: "google",
  },
  transcript: {
    id: null,
    name: "",
    date: null,
    language: "",
    backend: "",
    speakers: {
      id: null,
      name: "",
    },
    searchResults: {},
    highlight_sentences: "",
    student_summary: "",
    information: null,
  },
  settings: {
    highlightConfidence: false,
    confidenceThreshold: 1,
    playbackSpeed: 1.0,
    pauseThreshold: 1.0,
    showPauses: false,
  },
  smartPlaybackSettings: {
    audio: {
      isPlaying: false,
    },
    smartPlaybackState: false,
    smartPlaybackOption: "continuePlayback",
    jumpBackTime: 0,
  },
  gapSettings: {
    gapBrackets: "square",
    displayStyle: "normal",
    fontSize: "normal",
  },
  audioSettings: {
    jumpStep: 0,
  },
};

const mutations = {
  setSmartPlaybackState(state, smartPlaybackState) {
    Vue.set(
      state.smartPlaybackSettings,
      "smartPlaybackState",
      smartPlaybackState
    );
  },
  setSmartPlaybackOption(state, smartPlaybackOption) {
    Vue.set(
      state.smartPlaybackSettings,
      "smartPlaybackOption",
      smartPlaybackOption
    );
  },
  setJumpBackTime(state, jumpBackTime) {
    Vue.set(state.smartPlaybackSettings, "jumpBackTime", jumpBackTime);
  },
  setAudioState(state, audioState) {
    Vue.set(state.smartPlaybackSettings.audio, "isPlaying", audioState);
  },
  setJumpStep(state, jumpStep) {
    Vue.set(state.audioSettings, "jumpStep", jumpStep);
  },
  setAvailableLanguages(state, languages) {
    Vue.set(state, "availableLanguages", [...languages]);
  },
  setLanguage(state, language) {
    Vue.set(state, "language", language);
  },
  setDiarizationBackend(state, diarizationBackend) {
    Vue.set(state, "diarizationBackend", diarizationBackend);
  },
  setTranscriptionBackend(state, transcriptionBackend) {
    Vue.set(state, "transcriptionBackend", transcriptionBackend);
  },
  setSpeakerCount(state, speakerCount) {
    Vue.set(state.audioMetaData, "speakerCount", speakerCount);
  },
  setClueWords(state, clueWords) {
    Vue.set(state.audioMetaData, "clueWords", clueWords);
  },
  setTranscripts(state, transcripts) {
    Vue.set(state, "transcripts", transcripts);
  },
  setInterviewFilePath(state, path) {
    state.interviewFilePath = path;
  },
  setAudioMetaData(state, audio) {
    Vue.set(state, "audioMetaData", { ...audio });
  },
  setTranscript(state, transcript) {
    state.transcript = transcript;
  },
  setTranscriptNameInEditor(state, transcriptNewName) {
    state.transcript.name = transcriptNewName;
  },
  setTranscriptName(state, { transcriptId, transcriptNewName }) {
    state.transcripts.map((transcript) => {
      if (transcript.id === transcriptId) {
        transcript.name = transcriptNewName;
      }
    });
  },
  setTranscriptInformation(state, { transcriptInformation }) {
    state.transcript.information = transcriptInformation;
  },
  setHighlightSentences(state, summary) {
    state.transcript.highlight_sentences = summary;
  },
  setTranscriptSummary(state, summary) {
    state.transcript.student_summary = summary;
  },
  setGeneratedSummary(state, summary) {
    state.transcript.student_summary = summary;
  },
  setTranscriptDocument(state, document) {
    state.transcript.document = document;
  },
  setTranscriptionCost(state, cost) {
    Vue.set(state, "transcriptionCost", cost);
  },
  setSpeakers(state, speakers) {
    state.transcript.speakers = speakers;
  },
  setSpeakerNameById(state, { speakerId, name }) {
    state.transcript.speakers.map((speaker) => {
      if (speaker.id === speakerId) {
        speaker.name = name;
      }
    });
  },
  setPlaybackSpeed(state, speed) {
    Vue.set(state.settings, "playbackSpeed", speed);
  },
  setConfidenceThreshold(state, threshold) {
    Vue.set(state.settings, "confidenceThreshold", threshold);
  },
  setHighlightConfidence(state, highlight) {
    Vue.set(state.settings, "highlightConfidence", highlight);
  },
  setPauseThreshold(state, threshold) {
    Vue.set(state.settings, "pauseThreshold", threshold);
  },
  setShowPauses(state, show) {
    Vue.set(state.settings, "showPauses", show);
  },
  setGapBrackets(state, type) {
    Vue.set(state.gapSettings, "gapBrackets", type);
  },
  setDisplayStyle(state, type) {
    Vue.set(state.gapSettings, "displayStyle", type);
  },
  setFontSize(state, size) {
    Vue.set(state.gapSettings, "fontSize", size);
  },
  setSearchResults(state, searchResults) {
    state.transcript.searchResults = searchResults;
  },
};

const actions = {
  async fetchLanguages({ commit }) {
    const response = await ApiService.get(
      "/Transcript.list_available_languages"
    );
    commit("setAvailableLanguages", response.data.data.available_languages);
    return response.data.data.available_languages;
  },
  async fetchTranscripts({ commit }) {
    const response = await ApiService.get("/Transcript.list");
    commit("setTranscripts", convertAndSortTranscripts(response.data.data));
    return response.data.data;
  },
  async fetchTranscriptInformation({ commit }, transcriptId) {
    const response = await ApiService.get(
      `/Transcript.get_transcript_information?transcript_id=${transcriptId}`
    );
    const transcriptInformation = response.data;
    commit("setTranscriptInformation", transcriptInformation);
    return transcriptInformation;
  },
  async fetchTranscriptsForUser(_, userId) {
    const response = await ApiService.get(
      `/Transcript.list.for_user?user_id=${userId}`
    );
    return response.data.data;
  },
  async importInterview({ dispatch }, { formData, projectName }) {
    const reqData = {
      method: "post",
      url: `/Transcript.import?project_name=${projectName}`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    };
    const data = await ApiService.custom(reqData);
    await dispatch("fetchInterview", data.data.transcript_id);
  },
  async fetchInterview({ commit }, transcriptId) {
    const reqData = {
      method: "get",
      url: "/Transcript.get",
      params: {
        transcript_id: transcriptId,
      },
    };
    const response = await ApiService.custom(reqData);
    const { transcript, audio } = response.data.data;
    commit("setInterviewFilePath", createFilePath(audio));
    commit("setTranscript", convertTranscript(transcript));
    commit("setAudioMetaData", convertAudioMetaData(audio));
  },
  async deleteTranscript({ dispatch }, transcriptId) {
    await ApiService.post("/Transcript.delete", {
      transcript_id: transcriptId,
    });
    dispatch("fetchTranscripts");
  },
  async updateDocument({ commit, getters }, document) {
    await ApiService.post("/Transcript.update_doc", {
      transcript_id: getters.getTranscriptId,
      document: document,
    });
    commit("setTranscriptDocument", document);
  },
  async updateTranscriptName({ commit }, { transcriptId, transcriptNewName }) {
    await ApiService.post("/Transcript.update_name", {
      transcript_id: transcriptId,
      name: transcriptNewName,
    });
    commit("setTranscriptName", { transcriptId, transcriptNewName });
  },
  async updateTranscriptNameInEditor({ commit, getters }, transcriptNewName) {
    await ApiService.post("/Transcript.update_name", {
      transcript_id: getters.getTranscriptId,
      name: transcriptNewName,
    });
    commit("setTranscriptNameInEditor", transcriptNewName);
  },
  async updateHighlightSentences({ commit, getters }, summary) {
    await ApiService.post("/Transcript.update_highlight_sentences", {
      transcript_id: getters.getTranscriptId,
      highlight_sentences: summary,
    });
    commit("setTranscriptSummary", summary);
  },
  async updateTranscriptSummary({ commit, getters }, summary) {
    await ApiService.post("/Transcript.update_student_summary", {
      transcript_id: getters.getTranscriptId,
      student_summary: summary,
    });
    commit("setTranscriptSummary", summary);
  },
  async generateSummary({ commit, getters }, transcript) {
    const response = await ApiService.post("/Transcript.generate_summary", {
      transcript_id: getters.getTranscriptId,
      transcript: transcript,
    });
    console.log(response.data.summary);
    return response.data.summary;
  },
  updateSpeakerInfo: _.debounce(async function (
    { commit, getters },
    newSpeakerInfo
  ) {
    const response = await ApiService.post("/Transcript.update_speaker_info", {
      transcript_id: getters.getTranscriptId,
      speaker_info: newSpeakerInfo,
    });
    commit("setSpeakers", response.data.data);
  },
  1000),
  changeSpeakerName({ commit, state, dispatch }, { speakerId, name }) {
    commit("setSpeakerNameById", { speakerId, name });
    dispatch("updateSpeakerInfo", state.transcript.speakers);
  },
  addSpeaker({ commit, state, getters, dispatch }, { name }) {
    const newSpeakers = [
      ...getters.getSpeakers,
      {
        name: name,
        id: Math.max(...getters.getSpeakers.map((speaker) => speaker.id)) + 1,
      },
    ];
    commit("setSpeakers", newSpeakers);
    dispatch("updateSpeakerInfo", state.transcript.speakers);
  },
  removeSpeaker({ commit, state, getters, dispatch }, { speakerId }) {
    const newSpeakers = getters.getSpeakers.filter(
      (speaker) => speaker.id !== speakerId
    );
    commit("setSpeakers", newSpeakers);
    dispatch("updateSpeakerInfo", state.transcript.speakers);
  },
  async suggestProjectName(_context, name) {
    return ApiService.post("/Transcript.suggest_name", { name }).then(
      (result) => result.data.data.name
    );
  },
  async isValidProjectName(_context, name) {
    return ApiService.post("/Transcript.is_valid_project_name", { name }).then(
      (result) => result.data.data.is_valid_name
    );
  },
  async searchInTranscript({ commit, state, getters, dispatch }, keyword) {
    const reqData = {
      method: "get",
      url: "/Transcript.search",
      params: {
        keyword: keyword,
        transcript_id: getters.getTranscriptId,
      },
    };

    const response = await ApiService.custom(reqData);
    return response.data;
  },
};

const getters = {
  getSmartPlaybackState: (state) => {
    return state.smartPlaybackSettings.smartPlaybackState;
  },
  getSmartPlaybackOption: (state) => {
    return state.smartPlaybackSettings.smartPlaybackOption;
  },
  getJumpBackTime: (state) => {
    return state.smartPlaybackSettings.jumpBackTime;
  },
  getAudioState: (state) => {
    return state.smartPlaybackSettings.audio.isPlaying;
  },
  getJumpStep: (state) => {
    return state.audioSettings.jumpStep;
  },
  getAvailableLanguages: (state) => {
    return state.availableLanguages;
  },
  getTranscripts: (state) => {
    return state.transcripts;
  },
  getTranscriptInformation: (state) => {
    return state.transcript.information;
  },
  getInterviewFilePath: (state) => {
    return state.interviewFilePath;
  },
  getAudioMetaData: (state) => {
    return state.audioMetaData;
  },
  getLanguage: (state) => {
    return state.language;
  },
  getTranscriptionBackend: (state) => {
    return state.transcriptionBackend;
  },
  getSpeakerCount: (state) => {
    return state.audioMetaData.speakerCount;
  },
  getClueWords: (state) => {
    return state.audioMetaData.clueWords;
  },
  getTranscriptionCost: (state) => {
    return state.transcriptionCost;
  },
  getTranscript(state) {
    return state.transcript;
  },
  getTranscriptId(state) {
    return state.transcript.id;
  },
  getSpeakers(state) {
    return state.transcript.speakers;
  },
  getSpeakerNameById: (state) => (speakerId) => {
    const speaker = state.transcript.speakers.find(
      (speaker) => speaker.id === speakerId
    );
    if (speaker === undefined) {
      throw new Error(
        `Speaker with id ${speakerId} not found in ${JSON.stringify(
          state.transcript.speakers
        )}`
      );
    }
    return speaker.name;
  },
  getPlaybackSpeed: (state) => {
    return state.settings.playbackSpeed;
  },
  getConfidenceThreshold: (state) => {
    return state.settings.confidenceThreshold;
  },
  getHighlightConfidence: (state) => {
    return state.settings.highlightConfidence;
  },
  getPauseThreshold: (state) => {
    return state.settings.pauseThreshold;
  },
  getShowPauses: (state) => {
    return state.settings.showPauses;
  },
  getGapBrackets: (state) => {
    return state.gapSettings.gapBrackets;
  },
  getDisplayStyle: (state) => {
    return state.gapSettings.displayStyle;
  },
  getFontSize: (state) => {
    return state.gapSettings.fontSize;
  },
  getHighlightSentences: (state) => {
    return state.transcript.highlight_sentences;
  },
  getTranscriptSummary: (state) => {
    return state.transcript.student_summary;
  },
  getSearchResults: (state) => {
    return state.transcript.searchResults;
  },
};

function convertAndSortTranscripts(transcripts) {
  return transcripts
    .map((transcript) => {
      return {
        audioLength: transcript.audio_length,
        date: transcript.date,
        lastUpdated: transcript.last_updated,
        fileName: transcript.file_name,
        id: transcript.id,
        language: transcript.language,
        name: transcript.name,
        speakers: transcript.speakers,
        status: transcript.status,
        progress: Math.round(transcript.progress * 100),
      };
    })
    .sort((a, b) => a.date - b.date);
}

function convertTranscript(transcript) {
  return {
    id: transcript.id,
    name: transcript.name,
    date: transcript.date,
    language: transcript.language,
    backend: transcript.backend,
    speakers: transcript.speakers,
    document: transcript.document,
    highlight_sentences: transcript.highlight_sentences,
    student_summary: transcript.student_summary,
  };
}

function convertAudioMetaData(audio) {
  return {
    id: audio.id,
    channelCount: audio.audio_channel_count,
    fileType: audio.file_type,
    sampleRate: audio.sample_rate,
    length: audio.length,
    uploadName: audio.upload_name,
  };
}

function createFilePath(audio) {
  return `${process.env.VUE_APP_PROTOCOL}://${process.env.VUE_APP_HOST}:${process.env.VUE_APP_PORT}/Audiofile.read?id=${audio.id}`;
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
