<template>
  <div>
  <div>
    <div class="search-container">
      <div class="search-input">
        <input
        @input="searchQuery = $event.target.value"
        @keyup.enter="searchWord()"
      />
      <md-button
        class="md-icon-button md-primary search-button"
        @click="searchWord()"
      >
        <md-icon>search</md-icon>
      </md-button>
      </div>
      <div class="replace-input">
        <input
        :placeholder="$t('editor.sidebar.replaceWith')"
        @input="replaceQuery = $event.target.value"
        @keyup.enter="replaceWord()"
      ></input>
      <md-button class="md-icon-button md-primary" 
        @click="replaceWord()">
        <md-icon>change_circle</md-icon>
      </md-button>
      </div>
    </div>
    <div v-if="searchQuery" class="result-container">
        <p>{{$t("editor.sidebar.words")}} {{ wordCount }}</p>
      </div>
  </div>

    <div
      class="md-layout md-alignment-center-left"
      ref="pmEditorRefs"
      id="pmeditor-container"
    >
      <div class="md-layout-item md-size-15 md-small-hide">
        <div class="sticky-sidebar">
          <div>
            <md-button
              :disabled="!canAssignAbove"
              @click="mergeByDirection('above')"
              class="md-icon-button md-raised md-primary"
            >
              <md-icon class="md-size-2x">keyboard_arrow_up</md-icon>
              <md-tooltip
                class="adjust-height"
                md-direction="top"
                md-delay="300"
              >
                {{ $t("editor.sidebar.assignTextToPreviousSpeaker") }}
              </md-tooltip>
            </md-button>
          </div>

          <div class="speaker-assignment">
            <md-button
              class="md-dense md-raised md-primary sidebar-speaker-button"
              v-for="speaker in getSpeakers"
              @click="changeSpeakerForCurrentSelection(speaker.id)"
              :disabled="!canAssignToSpeaker(speaker.id)"
              :key="speaker.id"
            >
              {{ speaker.name }}
              <md-tooltip
                class="adjust-height"
                md-direction="top"
                md-delay="300"
              >
                {{
                  $t("editor.sidebar.assignTextToThisSpeaker", {
                    name: speaker.name,
                    id: speaker.id,
                  })
                }}
              </md-tooltip>
            </md-button>
          </div>

          <div>
            <md-button
              :disabled="!canAssignBelow"
              @click="mergeByDirection('below')"
              class="md-icon-button md-raised md-primary"
            >
              <md-icon class="md-size-2x">keyboard_arrow_down</md-icon>
              <md-tooltip
                class="adjust-height"
                md-direction="top"
                md-delay="300"
              >
                {{ $t("editor.sidebar.assignTextToNextSpeaker") }}
              </md-tooltip>
            </md-button>
          </div>
        </div>
      </div>

      <div
        id="interscriber-editor"
        class="md-layout-item md-size-65 md-small-size-80 md-xsmall-size-100"
        :class="'t' + getThreshold"
        @contextmenu.prevent="openContextMenu"
        ondragstart="return false;"
        ondrop="return false;"
      >
        <div
          class="phrase-tooltip md-tooltip adjust-height md-tooltip-top md-theme-default"
          id="phrase-tooltip"
        ></div>

        <vue-context ref="menu" id="editor-menu">
          <ul>
            <li @click="playFromCursorPosition">
              {{ $t("editor.wordContextMenu.play") }} <span>ALT+p</span>
            </li>
            <li @click="playSelectionLoopy" v-if="speakerSelected">
              {{ $t("editor.segmentContextMenu.playRepeat") }}
              <span>ALT+r</span>
            </li>
            <li @click="playSelectionLoopy" v-else>
              {{ $t("editor.wordContextMenu.playRepeat") }} <span>ALT+r</span>
            </li>
            <md-divider class="context-divider"></md-divider>
            <li @click="toggleCase()" v-if="!speakerSelected && canToggle">
              {{ $t("editor.wordContextMenu.toggleCase") }} <span>ALT+t</span>
            </li>
            <md-divider class="context-divider"></md-divider>
            <li
              @click="changeSpeakerForCurrentSelection(speaker)"
              v-on:keyup="keyHandler($event)"
              v-for="speaker in assignableSpeakers"
              :key="speaker"
            >
              {{ $t("editor.wordContextMenu.changeTo") }}
              {{ getSpeakerNameById(speaker) }} <span>ALT+{{ speaker }}</span>
            </li>
          </ul>
        </vue-context>

        <editor-content
          :editor="editor"
          :class="[pauseClass, displayClass, bracketsClass, pauseFontClass]"
          style="height: 100px"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Editor, EditorContent, Text } from "tiptap";
import { History } from "tiptap-extensions";
import KeyboardShortcutMixin from "../mixins/KeyboardShortcutMixin";
import { AudioBus } from "../audioBus";
import { mapActions, mapGetters } from "vuex";
import { VueContext } from "vue-context";
import { TextSelection } from "prosemirror-state";
import _ from "lodash";
import { PluginInjector } from "../editor/plugins";
import { Doc, Phrase, SpeakerFrame, Transcription } from "../editor/schema";

export default {
  name: "PMEditor",
  mixins: [KeyboardShortcutMixin],
  components: {
    VueContext,
    EditorContent,
  },
  props: ["document", "toggle", "undoTask", "redoTask"],
  data() {
    const vm = this;
    return {
      editor: new Editor({
        content: this.document,
        useBuiltInExtensions: false,
        onUpdate() {
          vm.updateDoc();
        },
        extensions: [
          new Doc(),
          new SpeakerFrame(this),
          new Transcription(),
          new Text(),
          new Phrase(),
          new History(),
          new PluginInjector(),
        ],
      }),
      speakerSelected: false,
      editGap: null,
      gapNode: null,
      gapPos: null,
      wordCount: 0,
    };
  },
  watch: {
    toggle() {
      if (this.toggle) {
        this.toggleCase();
        this.$emit("toggle-complete");
      }
    },
    undoTask() {
      if (this.undoTask) {
        this.editor.commands.undo(this.editor.state, this.editor.state.tr);
        this.$emit("undo-done");
      }
    },
    redoTask() {
      if (this.redoTask) {
        this.editor.commands.redo(this.editor.state, this.editor.state.tr);
        this.$emit("redo-done");
      }
    },
  },
  computed: {
    ...mapGetters("transcript", [
      "getHighlightConfidence",
      "getConfidenceThreshold",
      "getSpeakers",
      "getSpeakerNameById",
      "getShowPauses",
      "getGapBrackets",
      "getDisplayStyle",
      "getFontSize",
    ]),
    pauseClass() {
      if (!this.getShowPauses) {
        return "";
      }

      return `show-pause-${this.getShowPauses.toString().replace(".", "-")}-up`;
    },
    bracketsClass() {
      if (!this.getShowPauses) {
        return "";
      }
      return this.getGapBrackets;
    },
    displayClass() {
      if (!this.getShowPauses) {
        return "";
      }
      return this.getDisplayStyle;
    },
    pauseFontClass() {
      if (!this.getShowPauses) {
        return "";
      }
      return this.getFontSize;
    },
    getThreshold() {
      return this.getHighlightConfidence ? this.getConfidenceThreshold : 0;
    },
    assignableSpeakers() {
      const speakers = this.getSpeakers
        .filter((speaker) => !this.selectedSpeakers.has(speaker.id))
        .map((speaker) => speaker.id);
      const getName = this.getSpeakerNameById;
      speakers.sort(function (a, b) {
        const nameA = getName(a);
        const nameB = getName(b);
        if (nameA > nameB) {
          return 1;
        }
        if (nameA < nameB) {
          return -1;
        }
        return 0;
      });
      return speakers;
    },
    getSpeakerIdsInUse() {
      const tr = this.editor.state.tr;
      const speakers = new Set();
      // find all `speaker_frame`s with leading space characters
      tr.doc.descendants((node, pos) => {
        if (node.type.name === "speaker_frame") {
          speakers.add(node.attrs.speakerId);
        }
      });
      return speakers;
    },
    /**
     * Checks if the current selection can be toggled.
     *
     * @returns true/false
     */
    canToggle() {
      this.$emit("can-toggle", this.toggleCase(false));
      return this.toggleCase(false);
    },
    /**
     * Checks if the current selection can be appended to the previous `speaker_frame`. Basically,
     * checks if there is a `speaker_frame` preceiding the one the current selection is part of.
     *
     * @returns true/false
     */
    canAssignAbove() {
      return this.getSpeakerByDirection("above") !== undefined;
    },
    /**
     * Checks if the current selection can be prepended to the following `speaker_frame`. This
     * basically checks if there is a `speaker_frame` following the one the current selection is
     * part of.
     *
     * @returns true/false
     */
    canAssignBelow() {
      return this.getSpeakerByDirection("below") !== undefined;
    },
    /**
     * Gets the ids of the speakers corresponding to the `speaker_frame`s that the current
     * selection is part of.
     *
     * @returns The ids of the currently selected speakers.
     */
    selectedSpeakers() {
      const state = this.editor.state;
      const speakerSet = new Set();
      state.selection.content().content.descendants(function (node) {
        if (node.type.name === "speaker_frame") {
          speakerSet.add(node.attrs.speakerId);
          return false;
        }
        return true;
      });
      return speakerSet;
    },
    /**
     * Gets the id of the speaker corresponding to the `speaker_frame` preceiding the one the
     * current selection is part of.
     *
     * @returns The id of the previous speaker.
     */
    previousSpeaker() {
      const state = this.editor.state;
      const transcript = this.transcriptNode;
      const speakerFrameIndex = state.selection.$from.index(1);
      if (speakerFrameIndex === 0) {
        return undefined;
      }
      return transcript.child(speakerFrameIndex - 1).attrs.speakerId;
    },
    /**
     * Gets the id of the speaker corresponding to the `speaker_frame` following the one the
     * current selection is part of.
     *
     * @returns The id of the next speaker.
     */
    nextSpeaker() {
      const state = this.editor.state;
      const transcript = this.transcriptNode;
      const speakerFrameIndex = state.selection.$from.index(1);
      if (speakerFrameIndex + 1 >= transcript.childCount) {
        return undefined;
      }
      return transcript.child(speakerFrameIndex + 1).attrs.speakerId;
    },
    /**
     * Returns the node corresponding to the root of the transcript.
     *
     * @returns Transcript root node.
     */
    transcriptNode() {
      return this.editor.state.doc.child(0);
    },
    /**
     * Returns the timespan associated with the current selection. Gets the timestamps of the
     * recording for both the start and the end of the selection.
     *
     * @returns An object with the key value pairs `start` and `end`.
     */
    selectedTimeSpan() {
      const state = this.editor.state;
      let end = 0;
      let start = 100000000;
      state.doc.nodesBetween(
        state.selection.from,
        state.selection.to,
        function (node) {
          if (node.type.name === "phrase") {
            start = Math.min(node.attrs.startTime, start);
            end = Math.max(node.attrs.endTime, end);
            return false;
          }
          return true;
        }
      );
      return { start, end };
    },
  },
  methods: {
    ...mapActions("transcript", ["updateDocument", "updateSpeakerInfo"]),
    searchWord() {
      const tr = this.editor.state.tr;
      const doc = this.editor.state.doc;
      let found = false;
      let count = 0;

      // Traverse through the document to find the word
      doc.descendants((node, pos) => {
        if (node.isText && node.text.includes(this.searchQuery)) {
          found = true;
          count += 1;
          // Highlight the found word
          const start = node.text.indexOf(this.searchQuery) + pos;
          const end = start + this.searchQuery.length;

          tr.setSelection(TextSelection.create(tr.doc, start, end));
          this.editor.view.dispatch(tr);
          return false;
        }
        return true;
      });

      this.wordCount = count;

      if (!found) {
        alert(`The word "${this.searchQuery}" was not found.`);
      }
    },
    replaceWord() {
      const tr = this.editor.state.tr;
      const doc = this.editor.state.doc;
      let found = false;
      let count = 0; 

      // Traverse through the document to find and replace the word
      doc.descendants((node, pos) => {
        if (node.isText && node.text.includes(this.searchQuery)) {
          count += 1;
          found = true;
          const start = node.text.indexOf(this.searchQuery) + pos;
          const end = start + this.searchQuery.length;

          // Replace the found word with the new word
          tr.insertText(this.replaceQuery, start, end);
        }
        return true;
      });
      if(count > 0){
        this.editor.view.dispatch(tr);
      }
      if (!found) {
        alert(`The word "${this.searchQuery}" was not found.`);
      }
    },
    openContextMenu(event) {
      const utterance = event
        .composedPath()
        .find((p) => p.classList && p.classList.contains("utterance"));
      this.speakerSelected = utterance === undefined;
      this.$refs.menu.open(event);
    },
    /**
     * Gets the entire `speaker_frame` after the one the current selection is placed in.
     *
     * @param transcript  The transcript document
     * @param selection   The selection on the transcript
     * @returns The speaker frame following the selection.
     */
    getNextSpeakerFrame(transcript, selection) {
      const speakerFrameIndex = selection.$from.index(1);
      if (speakerFrameIndex + 1 >= transcript.childCount) {
        return undefined;
      }
      return transcript.child(speakerFrameIndex + 1);
    },
    /**
     * Gets the entire `speaker_frame` before the one the current selection is placed in.
     *
     * @param transcript  The transcript document
     * @param selection   The selection on the transcript
     * @returns The speaker frame preceiding the selection.
     */
    getPreviousSpeakerFrame(transcript, selection) {
      const speakerFrameIndex = selection.$from.index(1);
      if (speakerFrameIndex === 0) {
        return undefined;
      }
      return transcript.child(speakerFrameIndex - 1);
    },
    /**
     * Gets all `phrase`s from the current selection. If specified, the pauses are also collected.
     *
     * @param includePauses Includes the pauses in the final collection.
     * @param state         The state of the editor.
     * @returns All `phrase`s inside the current selection.
     */
    selectedPhrases(includePauses = false, state = this.editor.state) {
      const phrases = [];
      state.doc.nodesBetween(
        state.selection.from,
        state.selection.to,
        function (node, pos, parent, index) {
          if (node.type.name === "phrase") {
            if (node.attrs.type === "WORD") {
              const selectedTextInNode = node.textBetween(
                Math.max(0, state.selection.from - pos - 1),
                Math.min(node.nodeSize - 2, state.selection.to - pos - 1)
              );
              if (selectedTextInNode) {
                if (selectedTextInNode.replace(/[.!,? -]/g, "").length === 0) {
                  return false;
                }
              }
            } else if (
              (node.attrs.type === "NO SPEECH" ||
                node.attrs.type === "PAUSE" ||
                node.attrs.type === "PARALLEL SPEECH") &&
              !includePauses
            ) {
              return false;
            }
            phrases.push({
              node: node,
              start: pos,
              end: pos + node.nodeSize,
              parent: parent,
              index: index,
            });
            return false;
          }
          return true;
        }
      );
      return phrases;
    },
    setActivePhrase(currentTime) {
      const newT = this.editor.state.tr;
      newT.setMeta("audio", currentTime);
      this.editor.dispatchTransaction(newT);
    },
    updateDoc: _.debounce(function () {
      this.updateDocument(this.editor.getJSON());
    }, 1000),
    /**
     * Starts the audio playback from the start of the current selection.
     */
    playFromCursorPosition() {
      AudioBus.$emit("play", this.selectedTimeSpan.start);
    },
    /**
     * Starts an audio playback loop over the selection.
     */
    playSelectionLoopy() {
      AudioBus.$emit("loopy", {
        startTime: this.selectedTimeSpan.start,
        endTime: this.selectedTimeSpan.end,
      });
    },
    /**
     * Returns a version of the input word with toggled casing. Only toggles the first alphabetic
     * character in the word. If the input word does not contain an alphabetic character, the input
     * will be returned unchanged. Leading or following white spaces or punctuations will be
     * conserved.
     *
     * @param word  The input word.
     * @returns The input word with toggled casing.
     */
    toggleSingleWordCase(word) {
      for (let i = 0; i < word.length; i++) {
        if (word[i].toUpperCase() !== word[i].toLowerCase()) {
          // ignore punctuations
          let toggled;
          if (word[i] === word[i].toUpperCase()) {
            toggled = word[i].toLowerCase();
          } else {
            toggled = word[i].toUpperCase();
          }
          return word.substring(0, i) + toggled + word.substring(i + 1);
        }
      }
      return word;
    },
    defaultDispatch(tr) {
      this.editor.dispatchTransaction(tr);
      this.editor.focus();
    },
    toggleCase(dispatch = this.defaultDispatch, state = this.editor.state) {
      const selectedPhrases = this.selectedPhrases(false, state);
      if (selectedPhrases.length > 1) {
        return false;
      }
      let result;
      if (selectedPhrases.length === 1) {
        result = selectedPhrases[0];
      } else {
        const resolvedPos = state.selection.$to;
        if (resolvedPos.depth < 3) {
          return false;
        }
        result = {
          node: resolvedPos.node(3),
          start: resolvedPos.start(3) - 1,
          end: resolvedPos.end(3) + 1,
        };
      }
      const { node, start, end } = result;
      const tr = state.tr;
      tr.insertText(
        this.toggleSingleWordCase(node.textContent),
        start + 1,
        end - 1
      );
      if (dispatch) {
        dispatch(tr);
      }
      return true;
    },
    /**
     * Changes the speaker for the current selection to the one associated with given speaker id.
     *
     * @param newSpeakerId  The id of the new speaker.
     */
    async changeSpeakerForCurrentSelection(newSpeakerId) {
      this.changeSpeaker(newSpeakerId);
      this.removeLeadingSpacesFromSpeakerFrames();
      this.removeTrailingSpacesFromPreviousSpeakerFrames();
    },
    /**
     * Changes the speaker for the provided selection to the one associated with the given speaker
     * id.
     *
     * @param newSpeakerId        The id of the new speaker.
     * @param stateWithSelection  The state with the current selection.
     * @param dispatch            The dispatch function.
     */
    changeSpeaker(
      newSpeakerId,
      stateWithSelection = this.editor.state,
      dispatch = this.defaultDispatch
    ) {
      const selectedPhrases = this.selectedPhrases(false, stateWithSelection);
      if (selectedPhrases.length === 0) {
        return false;
        // throw 'No phrases selected';
      }
      const tr = stateWithSelection.tr;
      const from = selectedPhrases[0].start;
      const to = selectedPhrases[selectedPhrases.length - 1].end;
      // use mapping since split will change position
      tr.split(tr.mapping.map(from, -1));
      tr.split(tr.mapping.map(to, 1));
      const middle = Math.floor(
        (tr.mapping.map(from) + tr.mapping.map(to)) / 2
      );
      tr.doc.nodesBetween(middle, middle, function (node, pos) {
        if (node.type.name === "speaker_frame") {
          tr.setNodeMarkup(pos, undefined, { speakerId: newSpeakerId });
        }
        return true;
      });
      // use the following selection if you want the changed text to remain selected
      // let selection = TextSelection.create(tr.doc, tr.mapping.map(from, -1) + 3, tr.mapping.map(to, 1) - 3)
      const selection = TextSelection.create(tr.doc, tr.mapping.map(to, 1) - 3);
      tr.setSelection(selection);

      const isLastPhraseOfFrame =
        tr.selection.$to.node(2).lastChild === tr.selection.$to.node(3);
      const hasTrailingSpace =
        tr.doc.textBetween(tr.selection.to - 1, tr.selection.to) === " ";
      if (isLastPhraseOfFrame && hasTrailingSpace) {
        tr.insertText("", tr.selection.to - 1, tr.selection.to);
      }

      dispatch(tr);
    },
    /**
     * Gets the speaker for the given direction. If the direction is `above`, it gets the previous
     * speaker. For `below`, it gets the next speaker. Otherwise, `undefined` is returned.
     *
     * @param direction Expected to be either `above` or `below`.
     * @returns The speaker corresponding to the direction.
     */
    getSpeakerByDirection(direction) {
      if (direction === "above") {
        return this.previousSpeaker;
      }
      if (direction === "below") {
        return this.nextSpeaker;
      }
      return undefined;
    },
    /**
     * Checks if the current selection is assignable to the speaker corresponding to the provided
     * id.
     *
     * @param speakerId The id of the speaker the selection should be assigned to.
     * @returns true/false
     */
    canAssignToSpeaker(speakerId) {
      return (
        this.assignableSpeakers.indexOf(speakerId) !== -1 &&
        !this.editor.state.selection.empty
      );
    },
    /**
     * Goes through all the available `speaker_frame`s and removes any leading space characters
     * from them.
     */
    removeLeadingSpacesFromSpeakerFrames() {
      const tr = this.editor.state.tr;
      const frames = [];
      // find all `speaker_frame`s with leading space characters
      tr.doc.descendants((node, pos) => {
        if (node.type.name === "speaker_frame") {
          if (/^\s/.test(node.textContent)) {
            const children = [];
            node.forEach((element) => {
              if (element !== node.firstChild) {
                children.push(element);
              } else if (element.textContent !== " ") {
                children.push(element);
              }
            });
            frames.push({
              from: pos,
              to: pos + node.nodeSize,
              nodes: children,
              speakerFrame: node,
            });
          }
          return false;
        }
        return true;
      });
      // remove the leading space character from the found `speaker_frame`s
      for (const { from, to, nodes, speakerFrame } of frames) {
        const firstNode = nodes.shift();
        const firstText = firstNode.textContent;
        const textNode = this.editor.schema.text(firstText.trimLeft());
        const phraseNode = this.editor.schema.nodes.phrase.create(
          firstNode.attrs,
          textNode,
          firstNode.marks
        );
        nodes.unshift(phraseNode);
        const speakerFrameNode = this.editor.schema.nodes.speaker_frame.create(
          speakerFrame.attrs,
          nodes,
          speakerFrame.marks
        );
        tr.replaceWith(
          tr.mapping.map(from),
          tr.mapping.map(to),
          speakerFrameNode
        );
      }
      this.defaultDispatch(tr);
    },
    /**
     * Goes through all the available `speaker_frame`s. Removes trailing spaces from all
     * `speaker_frame`s that come before the one with the current selection.
     */
    removeTrailingSpacesFromPreviousSpeakerFrames() {
      const tr = this.editor.state.tr;
      const selection = this.editor.selection;
      const frames = [];
      // find all `speaker_frame`s with trailing space characters preceiding the current selection
      tr.doc.descendants((node, pos) => {
        if (node.type.name === "speaker_frame") {
          if (
            /\s$/.test(node.textContent) &&
            selection.from - node.nodeSize >= pos
          ) {
            const children = [];
            node.forEach((element) => {
              if (element !== node.lastChild) {
                children.push(element);
              } else if (element.textContent !== " ") {
                children.push(element);
              }
            });
            frames.push({
              from: pos,
              to: pos + node.nodeSize,
              nodes: children,
              speakerFrame: node,
            });
          }
          return false;
        }
        return true;
      });
      // remove the trailing space character from the found `speaker_frame`s
      for (const { from, to, nodes, speakerFrame } of frames) {
        const lastNode = nodes.pop();
        const lastText = lastNode.textContent;
        const textNode = this.editor.schema.text(lastText.trim());
        const phraseNode = this.editor.schema.nodes.phrase.create(
          lastNode.attrs,
          textNode,
          lastNode.marks
        );
        nodes.push(phraseNode);
        const speakerFrameNode = this.editor.schema.nodes.speaker_frame.create(
          speakerFrame.attrs,
          nodes,
          speakerFrame.marks
        );
        tr.replaceWith(
          tr.mapping.map(from),
          tr.mapping.map(to),
          speakerFrameNode
        );
      }
      this.defaultDispatch(tr);
    },
    /**
     * Merges the current selection into the `speaker_frame` associated with the given direction.
     *
     * @param direction The merge direction. Expected to be either `above` or `below`.
     */
    async mergeByDirection(direction) {
      const speakerId = this.getSpeakerByDirection(direction);
      if (speakerId === undefined) {
        throw Error("Speaker id undefined");
      }
      this.changeSpeaker(speakerId);
      this.removeLeadingSpacesFromSpeakerFrames();
      this.removeTrailingSpacesFromPreviousSpeakerFrames();
    },
    /**
     * Creates the ProseMirror Selection for the given merge direction on the also provided editor
     * state.
     *
     * @param direction The merge direction. Is expected to be either `above` or `below`.
     * @param state     The editor state.
     * @returns ProseMirror TextSelection
     */
    createSelectionForMergeByDirection(direction, state) {
      const tr = state.tr;
      if (direction === "above") {
        // creates a text selection for everything from the beginning of the document until the end of the provided selection
        return TextSelection.create(tr.doc, state.selection.to, 0);
      }
      if (direction === "below") {
        // creates a text selection for everything from the beginning of the provided selection until the end of the document
        return TextSelection.create(
          tr.doc,
          state.selection.from,
          tr.doc.textContent.length
        );
      }
    },
    /**
     * Returns the state with the corresponding selection for the given merge direction.
     *
     * @param direction The merge direction. Is expected to be either `above` or `below`.
     * @returns Editor state with the selection.
     */
    getStateWithSelectionForMergeByDirection(direction) {
      const state = this.editor.state;
      const tr = state.tr;
      const selection = this.createSelectionForMergeByDirection(
        direction,
        state
      );
      tr.setSelection(selection);
      return state.apply(tr);
    },
    openGapEditor(event) {
      this.gapNode = event.node;
      this.gapPos = event.pos;
      this.$emit("pause-clicked", {
        speakerId: event.speakerId,
        target: event.target,
        gapSubtext: event.gapSubtext,
      });
    },
    setPhraseNoSpeech() {
      const tr = this.editor.state.tr;
      tr.setNodeMarkup(this.gapPos, undefined, {
        ...this.gapNode.attrs,
        type: "NO SPEECH",
      });
      this.defaultDispatch(tr);
    },
    setPhraseParallelSpeech() {
      const tr = this.editor.state.tr;
      tr.setNodeMarkup(this.gapPos, undefined, {
        ...this.gapNode.attrs,
        type: "PARALLEL SPEECH",
      });
      this.defaultDispatch(tr);
    },
    setPhrasePause() {
      const tr = this.editor.state.tr;
      tr.setNodeMarkup(this.gapPos, undefined, {
        ...this.gapNode.attrs,
        type: "PAUSE",
      });
      this.defaultDispatch(tr);
    },
    setPhraseWord(text) {
      const tr = this.editor.state.tr;
      tr.setNodeMarkup(this.gapPos, undefined, {
        ...this.gapNode.attrs,
        type: "WORD",
      });
      tr.insertText("", this.gapPos + 1);
      tr.insertText(text, this.gapPos + 1);
      this.defaultDispatch(tr);
    },
  },
  created() {
    AudioBus.$on("updateActivePhrase", this.setActivePhrase);
  },
  mounted() {
    this.$refs.pmEditorRefs.vueRefs = this;
  },
  beforeDestroy() {
    // Always destroy your editor instance when it's no longer needed
    AudioBus.$off("updateActivePhrase", this.setActivePhrase);
    this.editor.destroy();
  },
};
</script>

<style lang="scss">


.search-container {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  display: flex;
  align-items: center; /* Align elements on the same height */
  justify-content: flex-end; /* Align elements to the right */
  gap: 10px; /* Add spacing between child elements */
}

.search-input,
.replace-input {
  display: flex;
  align-items: center; /* Ensure input and button align on the same row */
}

input {
  height: 30px;
  padding: 5px;
  font-size: 14px;
}

.result-container {
  margin: 0.5em;
  display: flex;
  align-items: center; /* Align elements on the same height */
  justify-content: flex-end; /* Align elements to the right */
  font-size: 14px;
}


/*for editor*/
div.frame {
  display: block;
  text-align: left;
}

.speaker input {
  border: none;
  background-color: transparent;
  font-weight: bold;
  line-height: 25px;
  overflow: hidden;
  text-align: center;
  outline: none;
  width: 100%;
}

#interscriber-editor .speaker input {
  font-size: 16px;
}

.timestamp-text {
  position: relative;
  font-size: 10px;
  color: grey;
  opacity: 0.8;
  bottom: 8px;
  max-width: max-content;
  min-width: min-content;
}

.segment {
  margin: 0 30px;
  padding: 5px;
  min-height: 25px;
}

.utterance {
  border-left: 2px solid black;
  line-height: 25px;
  text-align: left;
  padding-left: 30px !important;
}

[contenteditable]:focus {
  outline: 0 solid transparent;
}

/*for context menu*/
ul.v-context {
  transform: translateX(-50%);
  position: absolute;
  display: flex;
  z-index: 10;
}

ul.v-context ul {
  list-style-type: none;
  list-style-position: inside;
  padding-inline-start: 10px;
  padding-inline-end: 10px;
  background-color: white;
}

ul.v-context ul li,
.v-context li {
  cursor: pointer;
  font-size: 14px;
  color: rgb(44, 62, 80);
  font-weight: 500;
  line-height: 2.7;
  text-align: left;
}

.v-context li span {
  float: right;
  padding-left: 10px;
}

ul.v-context ul li:hover,
.v-context li:hover {
  background-color: #eeeeee;
}

.context-divider {
  margin-top: 8px;
  margin-bottom: 8px;
  background-color: #3c3f41;
}

.playback-highlight {
  color: #54a1a9;
}

/* confidence css*/
#interscriber-editor {
  font-size: 16px;

  &.t1 .t1-red,
  &.t2 .t2-red,
  &.t3 .t3-red,
  &.t4 .t4-red,
  &.t5 .t5-red {
    background-color: rgba(255, 51, 0, 0.3);
  }

  &.t1 .t1-yellow,
  &.t2 .t2-yellow,
  &.t3 .t3-yellow,
  &.t4 .t4-yellow,
  &.t5 .t5-yellow {
    background-color: rgba(255, 255, 0, 0.3);
  }

  &.t1 .t1-green,
  &.t2 .t2-green,
  &.t3 .t3-green,
  &.t4 .t4-green,
  &.t5 .t5-green {
    background-color: rgba(0, 153, 51, 0.3);
  }
}

phrase[type="PAUSE"],
phrase[type="NO SPEECH"],
phrase[type="PARALLEL SPEECH"] {
  display: none;
}

.small phrase[type="PAUSE"],
.small phrase[type="NO SPEECH"],
.small phrase[type="PARALLEL SPEECH"] {
  cursor: text;
  font-size: 10px;
  color: #a9a9a9;
}

.square phrase[type="PAUSE"]::before {
  cursor: text;
  content: "[SPEECH: ";
}

.square phrase[type="NO SPEECH"]::before {
  cursor: text;
  content: "[PAUSE: ";
}

.square phrase[type="PARALLEL SPEECH"]::before {
  cursor: text;
  content: "[PARALLEL SPEECH: ";
}

.square phrase[type="PAUSE"]::after,
.square phrase[type="NO SPEECH"]::after,
.square phrase[type="PARALLEL SPEECH"]::after {
  cursor: text;
  content: "sec] ";
}

.round phrase[type="PAUSE"]::before {
  cursor: text;
  content: "(SPEECH: ";
}

.round phrase[type="NO SPEECH"]::before {
  cursor: text;
  content: "(PAUSE: ";
}

.round phrase[type="PARALLEL SPEECH"]::before {
  cursor: text;
  content: "(PARALLEL SPEECH: ";
}

.round phrase[type="PAUSE"]::after,
.round phrase[type="NO SPEECH"]::after,
.round phrase[type="PARALLEL SPEECH"]::after {
  cursor: text;
  content: "sec) ";
}

.short.round phrase[type="PAUSE"]::before {
  cursor: text;
  content: "(S ";
}

.short.round phrase[type="NO SPEECH"]::before {
  cursor: text;
  content: "(P ";
}

.short.round phrase[type="PARALLEL SPEECH"]::before {
  cursor: text;
  content: "(PS: ";
}

.short.round phrase[type="PAUSE"]::after,
.short.round phrase[type="NO SPEECH"]::after,
.short.round phrase[type="PARALLEL SPEECH"]::after {
  cursor: text;
  content: "s) ";
}

.short.square phrase[type="PAUSE"]::before {
  cursor: text;
  content: "[S ";
}

.short.square phrase[type="NO SPEECH"]::before {
  cursor: text;
  content: "[P ";
}

.short.square phrase[type="PARALLEL SPEECH"]::before {
  cursor: text;
  content: "[PS: ";
}

.short.square phrase[type="PAUSE"]::after,
.short.square phrase[type="NO SPEECH"]::after,
.short.square phrase[type="PARALLEL SPEECH"]::after {
  cursor: text;
  content: "s] ";
}

$display-pause-groups: ["0-3", "0-5", "1", "2", "5"];
@for $i from 1 through length($display-pause-groups) {
  $i-group: nth($display-pause-groups, $i);
  @for $j from $i through length($display-pause-groups) {
    $j-group: nth($display-pause-groups, $j);
    .show-pause-#{$i-group}-up {
      phrase[type="PAUSE"],
      phrase[type="NO SPEECH"],
      phrase[type="PARALLEL SPEECH"] {
        &.pause-group-#{$j-group} {
          display: initial;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .speaker input {
    font-size: 14px;
  }

  #interscriber-editor {
    font-size: 14px;
  }

  .utterance {
    padding-left: 15px !important;
  }
}

@media (max-width: 450px) {
  .search-container {
    flex-direction: column; /* Stack elements vertically */
    align-items: flex-end; /* Align elements to the right side */
    gap: 5px; /* Reduce spacing between child elements for better appearance */
  }


  .search-input,
  .replace-input {
    width: auto; /* Ensure input fields take full width on small devices */
  }
}


</style>
<style lang="scss" scoped>
.phrase-tooltip {
  transform: translate(-50%, -100%);
  background-color: red;
  position: absolute;
  z-index: 50;
  text-align: left;
  padding: 0px 8px;
  height: auto;
}

.sticky-sidebar {
  position: fixed;
  top: 50%;
  left: 5px;
  width: 15%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  margin: 0;
  transform: translateY(-25%);
}

.sidebar-speaker-button {
  text-transform: none;
}

.speaker-assignment {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.speaker-assignment .md-button {
  margin: 6px 0;
}
</style>
