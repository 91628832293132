<template>
  <md-dialog :md-active.sync="showDialog">
    <md-dialog-title>{{ $t("exportTxtDialog.title") }}</md-dialog-title>
    <div class="close-button">
      <md-button @click="close" class="md-icon-button">
        <md-icon>close</md-icon>
      </md-button>
    </div>
    <md-dialog-content>
      <form novalidate @submit.prevent="exportTxt">
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <md-checkbox class="md-primary" v-model="form.showTimestamps">
              {{ $t("exportTxtDialog.showTimestamps") }}
            </md-checkbox>
          </div>
          <div class="md-layout-item md-size-100">
            <md-button type="submit" class="md-raised md-primary">{{
              $t("buttons.export")
            }}</md-button>
          </div>
        </div>
      </form>
    </md-dialog-content>
  </md-dialog>
</template>

<script>
export default {
  name: "ExportTxtDialog",
  props: ["show"],
  data() {
    return {
      form: {
        showTimestamps: false,
      },
    };
  },
  computed: {
    showDialog: {
      get() {
        return this.show;
      },
      set(newValue) {
        if (!newValue) {
          this.close();
        }
      },
    },
  },
  methods: {
    exportTxt() {
      this.$emit("export", this.form);
    },
    close() {
      this.$emit("close-window");
    },
  },
};
</script>
