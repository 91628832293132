<template>
  <div>
    <md-radio
      v-for="language in languages"
      v-model="$i18n.locale"
      :key="language.locale"
      :value="language.locale"
      >{{ $t(language.name) }}</md-radio
    >
  </div>
</template>

<script>
export default {
  name: "ChangeLanguage",
  data() {
    return {
      languages: [
        { name: "languages.english", locale: "en" },
        { name: "languages.german", locale: "de" },
        { name: "languages.italian", locale: "it" },
        { name: "languages.french", locale: "fr" },
      ],
    };
  },
};
</script>

<style scoped>
.change-language-settings {
  font-size: 16px;
  min-width: 200px;
  max-width: 200px;
}
.change-language {
  min-width: 200px;
  max-width: 200px;
}
</style>
