<template>
  <div class="md-layout md-gutter">
    <div class="md-layout-item">
      <div class="md-display-2">{{ numberOfActiveProjects }}</div>
      <div class="md-display-1">
        {{ $t("projectsOverview.numberOfActiveProjects") }}
      </div>
    </div>
    <div class="md-layout-item">
      <div class="md-display-2">{{ transcriptionActiveTime }}</div>
      <div class="md-display-1">
        {{ $t("projectsOverview.transcriptionActiveTime") }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "ProjectsOverview",
  props: {
    userId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      numberOfActiveProjects: -1,
      transcriptionActiveTime: null,
    };
  },
  methods: {
    ...mapActions("transcript", ["fetchTranscriptsForUser"]),
  },
  async mounted() {
    try {
      const transcripts = await this.fetchTranscriptsForUser(this.userId);
      this.numberOfActiveProjects = transcripts.length;
      const totalLenghtInSecondsOfAudioFiles = transcripts.reduce(
        (sum, cur) => {
          return sum + cur.audio_length;
        },
        0
      );
      this.transcriptionActiveTime = new Date(
        totalLenghtInSecondsOfAudioFiles * 1000
      )
        .toISOString()
        .substr(11, 8);
    } catch (e) {
      this.$notify({ text: e });
    }
  },
};
</script>

<style lang="scss"></style>
