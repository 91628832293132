<template>
  <div class="language-container">
    <div class="md-layout-item md-layout md-size-100">
        <div class="md-layout-item md-size-50">
            <span class="value-field">{{$t('changeLanguageTab.selectLanguage')}}</span>
            <ChangeLanguage></ChangeLanguage>
        </div>
    </div>
  </div>
</template>

<script>
import ChangeLanguage from './ChangeLanguage';

export default {
  name: 'ChangeLanguageTab',
  components: {
    ChangeLanguage,
  },
};
</script>

<style scoped>
  .language-container {
    margin: 15px;
  }
  .value-field {
    margin-right: 10px;
    font-size: 16px;
  }
  .md-layout-item.md-size-50 {
    min-width: 300px;
    max-width: 500px;
    text-align: left;
  }

</style>
