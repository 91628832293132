<template>
  <div class="md-layout md-gutter">
    <div class="md-layout-item">
      <div class="md-display-2">{{ totalNumberOfPayments }}</div>
      <div class="md-display-1">
        {{ $t("paymentsOverview.totalNumberOfPayments") }}
      </div>
    </div>
    <div class="md-layout-item">
      <div class="md-display-2">{{ totalPaidAmount }}</div>
      <div class="md-display-1">
        {{ $t("paymentsOverview.totalPaidAmount") }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "PaymentsOverview",
  props: {
    userId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      totalNumberOfPayments: -1,
      totalPaidAmount: -1,
    };
  },
  methods: {
    ...mapActions("payment", ["fetchPaymentForUser"]),
  },
  async mounted() {
    try {
      const payments = await this.fetchPaymentForUser(this.userId);
      this.totalNumberOfPayments = payments.length;
      this.totalPaidAmount = payments.reduce((sum, cur) => {
        return sum + cur.amount;
      }, 0);
    } catch (e) {
      this.$notify({ text: e });
    }
  },
};
</script>

<style scoped>
.md-layout-item {
  text-align: left;
}
</style>
