<template>
  <div>
    <form
      novalidate
      class="md-layout md-alignment-top-left"
      @submit.prevent="validateUserPassword"
    >
      <div
        class="change-password-container md-layout-item md-layout md-gutter md-small-size-100"
      >
        <div class="md-layout-item md-layout md-size-100">
          <div class="md-layout-item md-size-30">
            <ChangePasswordForm
              name="password"
              :isToggle="false"
              :keyValue="user.password"
              id="password"
              :validator="$v.user.password"
              :disabled="sending"
              :invalidClass="getValidationClass('password')"
              @update-valid-user="updateValidUser('password', $event)"
              @field-value="user.password = $event"
              :label="$t('fieldName.currentPassword')"
            ></ChangePasswordForm>
          </div>
          <div class="md-layout-item md-size-100" v-if="passwordChangeMode">
            <span class="md-error" v-if="!$v.user.password.required">{{
              $t("users.passwordRequired")
            }}</span>
          </div>
        </div>

        <div class="md-layout-item md-layout md-size-100">
          <div class="md-layout-item md-size-30">
            <ChangePasswordForm
              name="newPassword"
              :isToggle="true"
              :keyValue="user.newPassword"
              id="newPassword"
              :validator="$v.user.newPassword"
              :disabled="sending"
              :invalidClass="getValidationClass('newPassword')"
              @update-valid-user="updateValidUser('newPassword', $event)"
              @field-value="user.newPassword = $event"
              :label="$t('fieldName.newPassword')"
            ></ChangePasswordForm>
          </div>
          <div class="md-layout-item md-size-100" v-if="passwordChangeMode">
            <span class="md-error" v-if="!$v.user.newPassword.required">{{
              $t("users.passwordRequired")
            }}</span>
            <span class="md-error" v-else-if="!$v.user.newPassword.minLength">{{
              $t("users.passwordInvalidLength")
            }}</span>
            <span
              class="md-error"
              v-else-if="!$v.user.newPassword.containsUppercase"
              >{{ $t("users.passwordInvalidCase") }}</span
            >
            <span
              class="md-error"
              v-else-if="!$v.user.newPassword.containsLowercase"
              >{{ $t("users.passwordInvalidCase") }}</span
            >
            <span
              class="md-error"
              v-else-if="!$v.user.newPassword.containsNumber"
              >{{ $t("users.passwordInvalidNumber") }}</span
            >
            <span
              class="md-error"
              v-else-if="!$v.user.newPassword.containsSpecial"
              >{{ $t("users.passwordInvalidSpecial") }}</span
            >
          </div>
        </div>

        <div class="md-layout-item md-layout md-size-100">
          <div class="md-layout-item md-size-30">
            <ChangePasswordForm
              name="passwordRepeat"
              :isToggle="true"
              :keyValue="user.passwordRepeat"
              id="passwordRepeat"
              :validator="$v.user.passwordRepeat"
              :disabled="sending"
              :invalidClass="getValidationClass('passwordRepeat')"
              @update-valid-user="updateValidUser('passwordRepeat', $event)"
              @field-value="user.passwordRepeat = $event"
              :label="$t('fieldName.confirmPassword')"
            ></ChangePasswordForm>
          </div>
          <div
            class="md-layout-item md-layout md-size-100"
            v-if="passwordChangeMode"
          >
            <span
              class="md-error"
              v-if="!$v.user.passwordRepeat.sameAsPassword"
              >{{ $t("users.passwordSame") }}</span
            >
          </div>
        </div>
      </div>
    </form>
    <div class="md-layout md-gutter md-alignment-bottom-left buttons-container">
      <div class="save-button-container md-layout-item md-size-15">
        <md-button
          type="submit"
          class="md-raised md-primary save-button"
          :disabled="!isFormValid || !user.password || sending"
          @click="validateUserPassword"
          >{{ $t("buttons.saveButton") }}</md-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { required, minLength, sameAs } from "vuelidate/lib/validators";
import { mapActions, mapGetters, mapMutations } from "vuex";
import ChangePasswordForm from "./ChangePasswordForm";
export default {
  name: "Settings",
  components: {
    ChangePasswordForm,
  },
  data: () => {
    return {
      sending: false,
      alert: "",
      userSaved: false,
    };
  },
  computed: {
    ...mapGetters("user", {
      user: "getEditedUserDetails",
      fieldLength: "getFieldLength",
      timeout: "getTimeoutDuration",
    }),
    // flag used to add validation if true.
    passwordChangeMode() {
      if (
        this.user.password ||
        this.user.newPassword ||
        this.user.passwordRepeat
      ) {
        return true;
      }
      return false;
    },
    isFormValid() {
      return this.$v.$invalid ? false : true;
    },
  },
  methods: {
    ...mapActions("user", ["updateUserPassword"]),
    ...mapMutations("user", ["setUserDetailProperty", "resetPassword"]),

    getValidationClass(fieldName) {
      const field = this.$v.user[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },

    saveUserPassword() {
      this.sending = true;
      this.updateUserPassword()
        .then(({ response }) => {
          this.userSaved = true;
        })
        .catch(({ response }) =>
          this.$notify(response && response.data && response.data.error)
        )
        .finally(() => {
          this.sending = false;
        });
    },
    updateValidUser(property, value) {
      this.setUserDetailProperty({
        property,
        value,
      });
      this.$v.user[property].$touch();
    },
    validateUserPassword() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.saveUserPassword();
      }
    },
  },

  validations() {
    const containsUppercase = (value) => /[A-Z]/.test(value);
    const containsLowercase = (value) => /[a-z]/.test(value);
    const containsNumber = (value) => /[0-9]/.test(value);
    const containsSpecial = (value) => /[#?!@$%^&*-]/.test(value);
    const data = {
      user: {},
    };
    if (this.passwordChangeMode) {
      data.user.password = {
        required,
      };
      data.user.newPassword = {
        required,
        minLength: minLength([this.fieldLength.password]),
        containsUppercase,
        containsLowercase,
        containsNumber,
        containsSpecial,
      };
      data.user.passwordRepeat = {
        sameAsPassword: sameAs("newPassword"),
      };
    }
    return data;
  },
};
</script>

<style scoped>
.change-password-container {
  margin: 0;
  padding-left: 0 !important;
}

.buttons-container {
  min-height: 10vh !important;
  align-items: left;
}

.error-class {
  margin-left: 20px;
}
.save-button-container {
  margin-left: 1em;
  text-align: left;
}

.md-layout-item.md-size-30 {
  min-width: 250px;
  max-width: 450px;
}

.md-layout-item.md-size-100 {
  text-align: left;
}
</style>
