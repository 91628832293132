<template>
  <div>
    <h2>{{ $t("subscriptions.my_subscription") }}</h2>
    <div class="card-holder">
      <div v-if="active_plan_id !== null">
        <SubscriptionActive :planId="active_plan_id" @reload="loadSubscriptions" />
      </div>
    </div>
    <div>
      <h2>{{ $t("subscriptions.all_subscriptions") }}</h2>
    </div>
    <div class="card-holder">
      <div v-for="planId in this.other_plan_ids" :key="planId">
        <SubscriptionActive :planId="planId" @reload="loadSubscriptions" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SubscriptionActive from "./payment/SubscriptionActive.vue";
import SubscriptionPayment from "./payment/SubscriptionPayment.vue";

export default {
  name: "SubscriptionTab",
  components: {
    SubscriptionActive,
    SubscriptionPayment,
  },
  data: () => ({
    active_plan_id: null,
    other_plan_ids: [],
  }),
  computed: {},
  watch: {},
  mounted() {
    this.loadSubscriptions();
  },
  methods: {
    ...mapActions("payment", ["getSubscriptionPlans"]),
    loadSubscriptions() {
      this.getSubscriptionPlans().then((result) => {
        this.active_plan_id = result.data.data.active_plan_id;
        this.other_plan_ids = result.data.data.other_plan_ids;

        // free plan is not yet supported
        if (this.active_plan_id == 1){
          this.active_plan_id = null;
        }
        this.other_plan_ids = this.other_plan_ids.filter(p => p != 1);
      });
    }
  },
};
</script>

<style scoped>
.card-holder {
  justify-content: left;
  margin-bottom: 3em;
}
h2 {
  text-align: left;
}
</style>
