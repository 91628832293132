import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";

import JWT from "./modules/JWT";
import transcript from "./modules/transcript";
import user from "./modules/user";
import payment from "./modules/payment";
import highlights from "./modules/highlights";

Vue.use(Vuex);

const vuexLocalStorage = new VuexPersist({
  key: "interscriber", // The key to store the state on in the storage provider.
  storage: window.localStorage, // or window.sessionStorage or localForage
  // Function that passes the state and returns the state with only the objects you want to store.
  // reducer: state => state,
  // Function that passes a mutation and lets you decide if it should update the state in localStorage.
  // filter: mutation => (true)
  modules: ["JWT", "transcript", "user", "payment"],
});

const store = new Vuex.Store({
  modules: {
    JWT,
    transcript,
    user,
    payment,
    highlights,
  },
  plugins: [vuexLocalStorage.plugin],
  strict: process.env.NODE_ENV !== "production",
});

export default store;
