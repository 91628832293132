<template>
  <div>
    <md-dialog-confirm
      :md-active.sync="deletionConfirmationActive"
      :md-title="$t('users.deleteTitle')"
      :md-content="$t('users.deleteContent')"
      :md-confirm-text="$t('users.deleteConfirm')"
      :md-cancel-text="$t('users.deleteCancel')"
      @md-confirm="deleteUser(currentUser)"
    />
    <md-table
      v-model="tableRows"
      md-sort="id"
      md-sort-order="asc"
      md-fixed-header
    >
      <md-table-toolbar>
        <h1 class="md-title">Users</h1>
      </md-table-toolbar>
      <md-table-row
        @click="goToDetails(item)"
        slot="md-table-row"
        slot-scope="{ item }"
      >
        <md-table-cell
          :md-label="$t('userList.labels.userId')"
          md-sort-by="id"
          md-numeric
        >
          {{ item.id }}
        </md-table-cell>
        <md-table-cell
          :md-label="$t('userList.labels.name')"
          md-sort-by="name"
          class="table-cell-wrap-text"
        >
          {{ item.name }}
        </md-table-cell>
        <md-table-cell
          :md-label="$t('userList.labels.email')"
          md-sort-by="email"
          class="table-cell-wrap-text"
        >
          {{ item.email }}
        </md-table-cell>
        <md-table-cell :md-label="$t('userList.labels.role')" md-sort-by="role">
          {{ item.role }}
        </md-table-cell>
        <md-table-cell
          :md-label="$t('userList.labels.created')"
          md-sort-by="created"
        >
          {{ item.createdString }}
        </md-table-cell>
        <md-table-cell
          :md-label="$t('userList.labels.lastSeen')"
          md-sort-by="last_seen"
        >
          {{ item.lastSeenString }}
        </md-table-cell>

        <md-table-cell
          :md-label="$t('userList.labels.numberOfActiveProjects')"
          md-sort-by="numberOfActiveProjects"
          md-numeric
        >
          {{ item.numberOfActiveProjects }}
        </md-table-cell>
        <md-table-cell
          :md-label="$t('userList.labels.transcriptionActiveTime')"
          md-sort-by="transcriptionActiveTime"
          md-numeric
        >
          {{ item.transcriptionActiveTime }}
        </md-table-cell>
        <md-table-cell
          :md-label="$t('userList.labels.numberOfDeletedProjects')"
          md-sort-by="numberOfDeletedProjects"
          md-numeric
        >
          {{ item.numberOfDeletedProjects }}
        </md-table-cell>
        <md-table-cell
          :md-label="$t('userList.labels.transcriptionDeletedTime')"
          md-sort-by="transcriptionDeletedTime"
          md-numeric
        >
          {{ item.transcriptionDeletedTime }}
        </md-table-cell>
        <md-table-cell
          :md-label="$t('userList.labels.totalNumberOfProjects')"
          md-sort-by="totalNumberOfProjects"
          md-numeric
        >
          {{ item.totalNumberOfProjects }}
        </md-table-cell>
        <md-table-cell
          :md-label="$t('userList.labels.transcriptionTotalTime')"
          md-sort-by="transcriptionTotalTime"
          md-numeric
        >
          {{ item.transcriptionTotalTime }}
        </md-table-cell>
        <md-table-cell
          :md-label="$t('userList.labels.freeTime')"
          md-sort-by="credits"
          md-numeric
        >
          {{ item.credits }}
        </md-table-cell>
        <md-table-cell
          :md-label="$t('userList.labels.totalNumberOfPayments')"
          md-sort-by="paymentCountPlus1"
          md-numeric
        >
          {{ item.payment_count }}
        </md-table-cell>
        <md-table-cell
          :md-label="$t('userList.labels.status')"
          md-sort-by="status"
        >
          {{ item.status }}
        </md-table-cell>
        <md-table-cell :md-label="$t('userList.labels.actions')">
          <md-button
            class="md-icon-button"
            @click="showDeleteConfirmation(item.id)"
          >
            <md-icon>delete</md-icon>
          </md-button>
        </md-table-cell>
      </md-table-row>
    </md-table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";

export default {
  name: "UserList",
  data() {
    return {
      currentUser: null,
      deletionConfirmationActive: false,
      tableRows: [],
    };
  },
  computed: {
    ...mapGetters("user", ["getUsers"]),
  },
  methods: {
    ...mapActions("user", ["fetchUsers", "deleteUser"]),
    showDeleteConfirmation(userId) {
      this.currentUser = this.findUserWithId(userId);
      this.deletionConfirmationActive = true;
    },
    findUserWithId(userId) {
      return _.find(this.getUsers, { id: userId });
    },
    convertSecToHMS(value) {
      const sec = parseInt(value, 10);
      let h = Math.floor(sec / 3600);
      let m = Math.floor((sec - h * 3600) / 60);
      let s = sec - h * 3600 - m * 60;
      if (h < 10) {
        h = "0" + h;
      }
      if (m < 10) {
        m = "0" + m;
      }
      if (s < 10) {
        s = "0" + s;
      }
      return h + ":" + m + ":" + s;
    },
    restructureUser(user) {
      return {
        ...user,
        createdString: new Date(user.created).toLocaleString(),
        lastSeenString: new Date(user.last_seen).toLocaleString(),
        credtis: user.credits,
        transcriptCountPlus1: user.transcript_count + 1,
        paymentCountPlus1: user.payment_count + 1,
        totalNumberOfProjects: user.projects_total_count,
        numberOfActiveProjects: user.projects_active_count,
        numberOfDeletedProjects: user.projects_deleted_count,
        transcriptionTotalTime: this.convertSecToHMS(
          user.transcript_time_total
        ),
        transcriptionActiveTime: this.convertSecToHMS(
          user.transcript_time_active
        ),
        transcriptionDeletedTime: this.convertSecToHMS(
          user.transcript_time_deleted
        ),
      };
    },
    goToDetails(row) {
      this.$router.push({ name: "UserDetail", params: { id: row.id } });
    },
  },
  async mounted() {
    let users;
    try {
      users = await this.fetchUsers();
    } catch (e) {
      this.$notify({ text: e });
    }
    this.tableRows = users.map(this.restructureUser);
    this.tableRows = _.sortBy(this.tableRows, ["id"]);
  },
};
</script>

<style scoped lang="scss">
.md-table-row {
  cursor: pointer;
}

.md-table-cell-container {
  padding: 2px 2px 2px 2px !important;
  text-align: center;
}

.table-cell-wrap-text {
  word-break: break-all;
}

table {
  table-layout: fixed;
}
.md-title {
  text-align: left;
}
</style>
