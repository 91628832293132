<template>
  <md-toolbar class="md-primary">
    <div class="md-layout md-gutter md-alignment-center-space-between">
      <div class="md-layout-item items">
        <router-link to="/">
          <img class="brand-logo" src="~@/assets/Stenoris_Logo_min.svg" />
        </router-link>
      </div>
      <div class="md-layout-item brand-name">
        <router-link class="md-display-2" to="/" custom v-slot="{ navigate }">
          <span @click="navigate" role="link" @keypress.enter="navigate">{{ $t("header.title") }}</span>
        </router-link>
      </div>
      <md-menu
        md-size="small"
        md-direction="bottom-end"
        md-align-trigger
        class="items"
      >
        <md-button class="md-icon-button" md-menu-trigger>
          <md-icon>menu</md-icon>
        </md-button>
        <md-menu-content>
          <router-link to="/" exact>
            <md-menu-item>{{ $t("headerMenu.myProjects") }}</md-menu-item>
          </router-link>
          <router-link to="/usersettings" exact>
            <md-menu-item>{{ $t("headerMenu.myAccount") }}</md-menu-item>
          </router-link>
          <router-link v-if="isAdmin" to="/admin" exact>
            <md-menu-item>{{ $t("headerMenu.admin") }}</md-menu-item>
          </router-link>
          <md-menu-item v-if="isAdmin" @click="openMetabase()">{{
            $t("headerMenu.metabaseDashboard")
          }}</md-menu-item>
          <router-link to="/help" exact>
            <md-menu-item>{{ $t("headerMenu.help") }}</md-menu-item>
          </router-link>
          <md-menu-item @click="logoutAndReroute">{{
            $t("headerMenu.logout")
          }}</md-menu-item>
        </md-menu-content>
      </md-menu>
    </div>
  </md-toolbar>
</template>

<script>
export default {
  name: "Header",
  data: () => {
    return {
      showShortcuts: false,
    };
  },
  computed: {
    isAdmin() {
      return this.$store.getters["JWT/isAdmin"];
    },
  },
  methods: {
    openMetabase() {
      window.open(process.env.VUE_APP_DASHBOARD_URI, "_blank");
    },
    logoutAndReroute() {
      this.$store.dispatch("JWT/logout").then(() => {
        this.$router.push("/login");
      });
    },

    showSettingDialogBox() {
      this.showSetting = true;
    },
  },
};
</script>

<style scoped>
.items {
  flex: 1;
  padding: 0 !important;
}

.items:first-child {
  text-align: left;
}

.items:last-child {
  text-align: right;
}

.brand-name {
  cursor: pointer;
  padding: 0 !important;
}

.brand-logo {
  height: 4rem;
  width: 4rem;
}

.md-toolbar .md-layout {
  width: 100%;
  margin: 0;
}

@media screen and (max-width: 991px) {
  /* start of large tablet styles */
  .md-display-2 {
    font-size: 36px;
  }
}

@media screen and (max-width: 767px) {
  /* start of medium tablet styles */
  .md-display-2 {
    font-size: 32px;
  }
  .brand-logo {
    height: 35px;
    width: 35px;
  }
}

@media screen and (max-width: 479px) {
  /* start of phone styles */
  .md-display-2 {
    font-size: 28px;
  }
  .brand-logo {
    height: 30px;
    width: 30px;
  }
}

@media screen and (max-width: 300px) {
  /* start of narrow phone styles */
  .md-display-2 {
    font-size: 21px;
  }
  .brand-logo {
    height: 18px;
    width: 18px;
  }
}
</style>

<style>
#setting-dialog-box > .md-dialog-container {
  min-width: 65vw;
  width: 100%;
}
</style>
