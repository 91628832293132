<template>
  <div class="btn-container">
    <div>
      <button
        class="primary-medium-btn"
        @click="$emit('show-new-project-dialog')"
      >
        {{ $t("dashboard.new") }}
      </button>
    </div>
    <div>
      <button
        class="primary-medium-btn"
        @click="$emit('show-import-project-dialog')"
      >
        {{ $t("dashboard.import") }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "NewProjectControls",
};
</script>

<style scoped>
.btn-container {
  display: inline-grid;
  grid-template-columns: 12em 12em;
  grid-gap: 1em;
  align-items: center;
}

.btn-container button {
  width: 100%;
}

@media (max-width: 876px) {
  .btn-container {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }
}
</style>
