<template>
  <md-dialog
    :md-active.sync="dialogActive"
    class="gap-settings-dialog md-primary"
    v-drag
  >
    <md-dialog-title
      >{{ $t("gapSettingsDialog.title") }}
      <div class="hint-text">{{ $t("gapSettingsDialog.hint") }}</div>
    </md-dialog-title>
    <md-dialog-content>
      <div class="gap-settings-element md-layout-item">
        <label for="showGaps" id="showGaps">{{
          $t("gapSettingsDialog.showPauses")
        }}</label>
        <div class="radio-container">
          <md-radio
            v-model="showGaps"
            v-for="pause in pauses"
            :key="pause"
            :value="pause"
            class="md-primary"
            >{{ pauseLabel(pause) }}</md-radio
          >
        </div>
      </div>
      <div class="gap-settings-element md-layout-item">
        <label for="gapBrackets" id="gapBrackets">{{
          $t("gapSettingsDialog.encloseGaps")
        }}</label>
        <div class="radio-container">
          <md-radio v-model="gapBrackets" value="square" class="md-primary"
            >[ ]</md-radio
          >
          <md-radio v-model="gapBrackets" value="round" class="md-primary"
            >( )</md-radio
          >
        </div>
      </div>
      <div class="gap-settings-element md-layout-item">
        <label for="fontSize" id="fontSize">{{
          $t("gapSettingsDialog.fontType")
        }}</label>
        <div class="radio-container">
          <md-radio v-model="fontSize" value="normal" class="md-primary">
            <i>{{ $t("gapSettingsDialog.normal") }}</i
            >{{ $t("gapSettingsDialog.gapTextExample0") }}
            <span class="text-gap-sample-normal">[Pause: 0.4sec]</span>
            {{ $t("gapSettingsDialog.gapTextExample1") }}
          </md-radio>
          <md-radio v-model="fontSize" value="small" class="md-primary">
            <i>{{ $t("gapSettingsDialog.small") }}</i
            >{{ $t("gapSettingsDialog.gapTextExample0") }}
            <span class="text-gap-sample">[PAUSE: 0.4sec]</span>
            {{ $t("gapSettingsDialog.gapTextExample1") }}
          </md-radio>
        </div>
      </div>
      <div class="gap-settings-element md-layout-item">
        <label for="displayStyle" id="displayStyle">{{
          $t("gapSettingsDialog.displayStyle")
        }}</label>
        <div class="radio-container">
          <md-radio v-model="displayStyle" value="short" class="md-primary">
            <i>{{ $t("gapSettingsDialog.short") }}</i
            >{{ $t("gapSettingsDialog.gapTextExample0") }}
            <span class="text-gap-sample">[P: 0.4s]</span>
            {{ $t("gapSettingsDialog.gapTextExample1") }}
          </md-radio>
          <md-radio v-model="displayStyle" value="normal" class="md-primary">
            <i>{{ $t("gapSettingsDialog.long") }}</i
            >{{ $t("gapSettingsDialog.gapTextExample0") }}
            <span class="text-gap-sample">[PAUSE: 0.4sec]</span>
            {{ $t("gapSettingsDialog.gapTextExample1") }}
          </md-radio>
        </div>
      </div>
    </md-dialog-content>
    <md-dialog-actions>
      <md-button class="md-primary" @click="closeDialog">
        {{ $t("buttons.okey") }}
      </md-button>
    </md-dialog-actions>
  </md-dialog>
</template>

<script>
import questionIcon from "../assets/svgs/regular/question-circle.svg";
import { DISPLAY_PAUSE_GROUPS } from "@/editor/schema";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "GapSettingsDialog",
  data() {
    return {
      questionIcon,
      dialogActive: false,
      pauses: DISPLAY_PAUSE_GROUPS.slice(1),
      gapsActive: !!this.getShowPauses,
      lastPause: DISPLAY_PAUSE_GROUPS[1],
    };
  },
  computed: {
    ...mapGetters("transcript", [
      "getShowPauses",
      "getGapBrackets",
      "getDisplayStyle",
      "getFontSize",
    ]),
    showGaps: {
      set(pause) {
        this.lastPause = pause;
        this.setShowPauses(pause);
      },
      get() {
        const setPause = this.getShowPauses;
        if (setPause) {
          return this.getShowPauses;
        } else {
          return this.lastPause;
        }
      },
    },
    gapBrackets: {
      set(type) {
        this.setGapBrackets(type);
      },
      get() {
        return this.getGapBrackets;
      },
    },
    fontSize: {
      set(size) {
        this.setFontSize(size);
      },
      get() {
        return this.getFontSize;
      },
    },
    displayStyle: {
      set(type) {
        this.setDisplayStyle(type);
      },
      get() {
        return this.getDisplayStyle;
      },
    },
  },
  methods: {
    ...mapMutations("transcript", [
      "setShowPauses",
      "setGapBrackets",
      "setDisplayStyle",
      "setFontSize",
    ]),
    openGapSettingsDialog() {
      this.dialogActive = true;
    },
    pauseLabel(pause) {
      if (!pause) {
        return this.$t("editorSettings.noPause");
      }
      return this.$t("editorSettings.pause", { duration: pause });
    },
    closeDialog() {
      this.dialogActive = false;
    },
  },
};
</script>

<style scoped>
.gap-settings-dialog {
  max-width: 600px;
  position: absolute;
  left: calc(50% - 245px);
  right: 0;
  top: 60px;
}
.text-gap-sample {
  font-size: 10px;
  color: #a9a9a9;
}
.gap-settings-element {
  margin-top: 16px;
}
.md-dialog ::v-deep.md-dialog-container {
  width: 510px;
  max-width: 100%;
  border: 5px solid #54a1a9;
}
.md-radio {
  margin: 8px 16px 8px 0;
}
.hint-text {
  font-size: 10px;
  color: grey;
  line-height: 1.5em;
}
.md-dialog-title {
  margin-bottom: 12px;
}
.radio-container {
  margin-left: 16px;
}
</style>
