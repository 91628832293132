<template>
  <md-dialog class="email-ask" :md-active.sync="active">
    <div class="close-button">
      <md-button @click="setActiveEmailAsk(false)" class="md-icon-button">
        <md-icon>close</md-icon>
      </md-button>
    </div>
    <md-dialog-title>{{$t('buttons.resetPassword')}}</md-dialog-title>

    <md-dialog-content>
      <span class="error-class" v-if="errorMessage">{{errorMessage}}</span>
      <div class="success-class" v-if="successMessage">
        <span>{{$t('resetPassword.emailSent.first')}}</span>
      </div>
      <md-field :class="getValidationClass('resetEmail')">
        <label for="email">{{$t('fieldName.email')}}</label>
        <md-input name="email" id="email" :placeholder="$t('fieldName.email')" v-model="resetEmail" :disabled="sending" />
        <span class="md-error" v-if="!$v.resetEmail.required">{{$t("users.emailRequired")}}</span>
        <span class="md-error" v-else-if="!$v.resetEmail.email">{{$t("users.emailInvalid")}}</span>
      </md-field>
    </md-dialog-content>

    <md-dialog-actions v-if="!successMessage && !sending">
      <md-button class="md-primary cancel" @click="setActiveEmailAsk(false)">{{$t('buttons.cancelButton')}}</md-button>
      <md-button class="md-primary send-reset-link" @click="sendResetPasswordLink(resetEmail)">{{$t('buttons.okey')}}</md-button>
    </md-dialog-actions>
  </md-dialog>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators';
import { mapGetters, mapActions, mapMutations } from 'vuex';
export default {
  data: () => ({
    resetEmail: '',
    sending: false,
    errorMessage: '',
    successMessage: '',
  }),
  computed: {
    ...mapGetters('user', {
      timeout: 'getTimeoutDuration',
      resetActive: 'getActiveEmailAsk',
    }),
    active: {
      set(value) {
        this.setActiveEmailAsk(value);
      },
      get() {
        return this.resetActive;
      },
    },
  },
  watch: {
    errorMessage() {
      if (this.errorMessage) {
        setTimeout(() => {
          this.errorMessage = '';
        }, this.timeout);
      }
    },
  },
  methods: {
    ...mapMutations('user', [
      'setActiveEmailAsk',
    ]),
    ...mapActions('user', [
      'resetPasswordLink',
    ]),
    sendResetPasswordLink(email) {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.sending = true;
        this.resetPasswordLink(email).then((resp) => {
          this.successMessage = resp.data.msg;
          this.sending = false;
        }).catch(({ response }) => {
          this.errorMessage = response && response.data && response.data.error;
          this.sending = false;
        });
      }
    },
    getValidationClass(fieldName) {
      const field = this.$v[fieldName];

      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty,
        };
      }
    },
  },
  validations() {
    const data = {
      resetEmail: {
        required,
        email,
      },
    };
    return data;
  },
};
</script>

<style scoped lang="scss">
.email-ask {
  max-width: 400px;
  .success-class {
    color: $color-sea-breeze;
  }
}
</style>
