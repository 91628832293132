<template>
  <Page>
    <div class="md-layout md-gutter md-alignment-top-space-around load-file-section">
      <div class="md-layout-item md-size-100">
        <md-tabs md-dynamic-height class="setting_tabs" @click="$emit('show-setting-dialog-box')">
          <md-tab class="profile_setting" :md-label="$t('settings.profile')" :md-disabled="disableTab">
            <ProfileTab @close-setting-dialog="$emit('close-setting-dialog')"
              @disable-setting-close="this.closeAndDisableTab"></ProfileTab>
          </md-tab>
          <md-tab class="change-password-setting" :md-label="$t('settings.changePassword')" :md-disabled="disableTab">
            <ChangePasswordTab @close-setting-dialog="$emit('close-setting-dialog')"></ChangePasswordTab>
          </md-tab>
          <md-tab class="change-language-setting" :md-label="$t('settings.language')" :md-disabled="disableTab">
            <ChangeLanguageTab></ChangeLanguageTab>
          </md-tab>
          <md-tab class="" :md-label="$t('settings.mfa')" :md-disabled="disableTab">
            <SetupMFATab></SetupMFATab>
          </md-tab>
          <md-tab class="" md-label="Subscription" :md-disabled="disableTab">
            <SubscriptionTab></SubscriptionTab>
          </md-tab>
          <md-tab class="" :md-label="$t('settings.transactions')" :md-disabled="disableTab">
            <TransactionsTab></TransactionsTab>
          </md-tab>
          <md-tab class="billing-setting" :md-label="$t('settings.yourPayment')" :md-disabled="disableTab">
            <BillingTab></BillingTab>
          </md-tab>
          <!-- <md-tab class="preference-setting" md-label="Preferences" :md-disabled="disableTab">
            <PreferenceTab></PreferenceTab>
          </md-tab> -->
        </md-tabs>
      </div>
    </div>
  </Page>
</template>

<script>
import Page from "../components/Page";
import ProfileTab from "../components/ProfileTab";
import BillingTab from "../components/BillingTab";
import ChangePasswordTab from "../components/ChangePasswordTab";
import ChangeLanguageTab from "../components/ChangeLanguageTab";
import TransactionsTab from "../components/TransactionsTab";
import SetupMFATab from "../components/SetupMFATab";
import SubscriptionTab from "../components/SubscriptionTab"

export default {
  data: () => {
    return {
      disableTab: null,
    };
  },
  components: {
    Page,
    ProfileTab,
    BillingTab,
    ChangePasswordTab,
    ChangeLanguageTab,
    TransactionsTab,
    SetupMFATab,
    SubscriptionTab
  },
  computed: {
    closeAndDisableTab() {
      // TODO: what should happen here?
      return false;
    },
  },
};
</script>

<style scoped>
.setting_tabs ::v-deep .md-tabs-navigation .md-active {
  color: #54a1a9 !important;
}

.setting_tabs ::v-deep .md-tabs-indicator {
  background-color: #54a1a9 !important;
}

.setting_tabs ::v-deep .md-tabs-content {
  height: 80vh !important;
  overflow-y: auto;
}

.setting_tabs ::v-deep .md-ripple .md-button-content {
  font-size: 16px;
}

.setting_tabs ::v-deep .md-tabs-navigation {
  flex-wrap: wrap;
}
</style>
