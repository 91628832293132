import Vue from 'vue';
import ApiService from '../../services/api.service';
import _ from 'lodash';

const state = {
  // user data for UserRole=USER.
  userDetails: {
    name: '',
    email: '',
    password: '',
    newPassword: '',
    passwordRepeat: '',
    stripe_customer_id: '',
    created: '',
    show_welcome_screen: '',
    mfa_enabled: '',
  },
  users: [{
    id: null,
  }],
  fieldLength: {
    name: 3,
    password: 12,
  },
  timeoutDuration: 3000,
  padValue: 25700000,
  activeEmailAsk: false,
  availableRoles: [],
};

const mutations = {
  setActiveEmailAsk(state, active) {
    Vue.set(state, 'activeEmailAsk', active);
  },
  // set the values of userDetails.
  setUserDetails(state, userDetails) {
    Vue.set(state, 'userDetails', { ...userDetails });
  },

  deleteUserFromList(state, userId) {
    Vue.set(state, 'users', state.users.filter(user => user.id !== userId));
  },

  // Set individual property value of userDetails.
  setUserDetailProperty(state, { property, value }) {
    Vue.set(state.userDetails, property, value);
  },

  setUsers(state, users) {
    Vue.set(state, 'users', [...users]);
  },

  setAvailableRoles(state, roles) {
    Vue.set(state, 'availableRoles', [...roles]);
  },

  // reset profileTab fields.
  resetProfileTab(state) {
    Vue.set(state.userDetails, 'name', '');
    Vue.set(state.userDetails, 'email', '');
  },
  resetPassword(state) {
    Vue.set(state.userDetails, 'password', '');
    Vue.set(state.userDetails, 'newPassword', '');
    Vue.set(state.userDetails, 'passwordRepeat', '');
  },
};

const actions = {
  async fetchUsers({ commit }) {
    const response = await ApiService.get('/User.list');
    commit('setUsers', response.data.data);
    return response.data.data;
  },
  async fetchUser(_, userId) {
    const response = await ApiService.get(`/User.get?user_id=${userId}`);
    return response.data.data;
  },
  // get user details from database.
  async fetchUserDetails({ commit }) {
    const reqData = {
      method: 'get',
      url: '/User.get.details',
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
    };
    const response = await ApiService.custom(reqData);
    commit('setUserDetails', response.data.data);
  },

  async ensureStripeAccountExists({ commit }) {
    const response = await ApiService.post('/User.ensure_stripe_account_exists');
    commit('setUserDetails', response.data.data);
  },

  // create user for everyone.
  signupUser(empty = {}, { formData }) {
    return ApiService.post('/User.signup', {
      user_data: _.omit(formData, 'passwordRepeat'),
    });
  },
  signupUserMFA(empty = {}, { formData }) {
    return ApiService.post('/User.signup_mfa', {
      user_data: _.omit(formData, 'passwordRepeat'),
    });
  },
  signupUserMFAConfirm(empty = {}, { formData }) {
    return ApiService.post('/User.signup_mfa_confirm', { user_data: formData });
  },
  setupMFA() {
    return ApiService.get('/User.setup_mfa');
  },
  confirmMFA(empty = {}, { formData }) {
    return ApiService.post('/User.confirm_mfa', { user_data: formData });
  },
  disableMFA(empty = {}, { formData }) {
    return ApiService.post('/User.disable_mfa', { user_data: formData });
  },
  checkLanguageServer() {
    return ApiService.get('/lshealth');
  },

  async updateUser(_, { id, user }) {
    user.id = id;
    const response = await ApiService.post('/User.update', user, 'password_repeat');
    return response.data.data;
  },

  // update user data for UserRole=USER
  updateUserDetails({ getters }) {
    return ApiService.post('/User.update.details', _.omit(getters.getEditedUserDetails, 'passwordRepeat', 'password', 'newPassword'));
  },
  // send email to verify account or email id.
  generateEmailConfirmationUrl({ getters }) {
    return ApiService.post('/User.generate.url', _.omit(getters.getEditedUserDetails, 'passwordRepeat'));
  },
  // update user password.
  updateUserPassword({ getters }) {
    return ApiService.post('/User.update.details', _.omit(getters.getEditedUserDetails, 'passwordRepeat', 'name', 'email'));
  },
  updateUserShowScreen({ getters }) {
    return ApiService.post('/User.update.details', _.omit(getters.getEditedUserDetails, 'passwordRepeat', 'password', 'newPassword', 'created'));
  },

  async deleteUser({ commit }, { id }) {
    await ApiService.post('/Admin_access.user.delete', { id });
    commit('deleteUserFromList', id);
  },

  deletedUserAccount() {
    return ApiService.get('/User.terminate.account');
  },
  resetPasswordLink(_, email) {
    return ApiService.post('/User.reset_password_link', { email });
  },
  resetPassword(_, resetData) {
    return ApiService.post('/User.reset_password', resetData);
  },

  async fetchAvailableRoles({ commit }) {
    const response = await ApiService.get('/User.get_available_roles');
    commit('setAvailableRoles', response.data.data.roles);
    return response.data.data.roles;
  },
};

const getters = {
  getEditedUserDetails: (state) => {
    return state.userDetails;
  },
  getUsers: (state) => {
    return state.users;
  },
  getFieldLength: (state) => {
    return state.fieldLength;
  },
  getTimeoutDuration: (state) => {
    return state.timeoutDuration;
  },
  getPadValue: (state) => {
    return state.padValue;
  },
  getActiveEmailAsk: (state) => {
    return state.activeEmailAsk;
  },
  getAvailableRoles: (state) => {
    return state.availableRoles;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
