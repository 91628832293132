<template>
  <footer>
    <div>
      <span class="md-caption copyright">{{ $t('landingPage.footer.copyright') }}</span>
    </div>
  </footer>
</template>

<script>

export default {
  name: 'Footer',
};
</script>

<style>
footer {
  height: 23px;
  border-top: 1px solid darkgrey;
  bottom: 0;
  text-align: center;
  padding: 0 15px;
}
</style>
