<template>
  <div class="md-layout md-alignment-center-center">
    <md-progress-spinner class="md-primary" :md-stroke="3" md-mode="indeterminate"></md-progress-spinner>
  </div>
</template>

<script>
export default {
};
</script>

<style lang="css" scoped>
</style>
