<template>
  <div class="editor-settings">
    <div class="md-layout md-gutter md-alignment-center-left">
      <div
        class="left-settings md-layout-item md-size-40 md-medium-size-40 md-small-size-40 md-xsmall-size-100 text-center"
      >
        <div class="confidence md-layout md-gutter md-alignment-center-left">
          <div class="md-layout-item md-size-15 text-center-compact">
            <md-button
              class="shortcut-button md-icon-button md-raised md-primary"
              v-on:click="$emit('open-speaker-dialog')"
            >
              <md-icon>people</md-icon>
              <md-tooltip md-direction="top">{{
                $t("editor.speakerSettingsDialog.title")
              }}</md-tooltip>
            </md-button>
          </div>
          <div class="md-layout-item md-size-15 text-center-compact">
            <md-button
              class="md-icon-button md-raised md-primary"
              @click="$emit('undo-task')"
            >
              <md-icon>undo</md-icon>
              <md-tooltip md-direction="top">{{
                $t("editor.tooltip.undo")
              }}</md-tooltip>
            </md-button>
          </div>
          <div class="md-layout-item md-size-15 text-center-compact">
            <md-button
              class="md-icon-button md-raised md-primary"
              @click="$emit('redo-task')"
            >
              <md-icon>redo</md-icon>
              <md-tooltip md-direction="top">{{
                $t("editor.tooltip.redo")
              }}</md-tooltip>
            </md-button>
          </div>
          <div class="md-layout-item md-size-15 text-center-compact">
            <md-button
              class="md-icon-button md-raised md-primary"
              @click="$emit('toggle-case')"
              :disabled="!canToggle"
            >
              <img :src="fileToggle" class="png-img" />
              <md-tooltip md-direction="top">{{
                $t("editor.wordContextMenu.toggleCase")
              }}</md-tooltip>
            </md-button>
          </div>
        </div>
      </div>
      <div
        class="right-settings md-layout-item md-size-80 md-medium-size-60 md-small-size-50 md-xsmall-size-40 md-layout md-gutter md-alignment-center-right"
      >
        <div
          class="edit-gaps-container md-layout-item md-size-30 text-center-compact"
        >
          <md-checkbox
            v-model="showPausesChecked"
            class="md-primary editor-settings-checkbox"
            >{{ $t("editorSettings.showGaps") }}</md-checkbox
          >
          <button
            v-on:click="$emit('open-gap-settings-dialog')"
            class="edit-gaps-button"
          >
            <img :src="penIcon" class="edit-gaps-img" />
            <md-tooltip md-direction="top">{{
              $t("editorSettings.editGapSettings")
            }}</md-tooltip>
          </button>
        </div>
        <div
          class="highlight-confidence md-layout-item md-size-30 md-small-size-30"
        >
          <md-checkbox
            class="editor-settings-checkbox md-primary"
            v-model="highlightConfidence"
            >{{ $t("editorSettings.highlightConfidences") }}</md-checkbox
          >
        </div>
        <div class="threshold md-layout-item md-size-35 md-small-size-35">
          <md-field class="confidence-threshold">
            <label>{{ $t("editorSettings.confidenceThreshold") }}</label>
            <md-select v-model="confidenceThreshold">
              <md-option value="1">{{
                $t("editorSettings.veryLow")
              }}</md-option>
              <md-option value="2">{{ $t("editorSettings.low") }}</md-option>
              <md-option value="3">{{ $t("editorSettings.medium") }}</md-option>
              <md-option value="4">{{ $t("editorSettings.high") }}</md-option>
              <md-option value="5">{{
                $t("editorSettings.veryHigh")
              }}</md-option>
            </md-select>
          </md-field>
        </div>
      </div>
    </div>
    <KeyboardShortcut
      :showShortcuts="showShortcuts"
      @close-show-shortcut="$emit('close-shortcut')"
    ></KeyboardShortcut>
  </div>
</template>

<script>
import penIcon from "../assets/svgs/solid/pen.svg";
import fileWord from "../assets/file-word.svg";
import fileCsv from "../assets/file-csv.svg";
import commentsIcon from "../assets/svgs/regular/comments.svg";
import { AudioBus } from "../audioBus";
import { mapGetters, mapMutations } from "vuex";
import { DISPLAY_PAUSE_GROUPS } from "../editor/schema";
import fileMerge from "../assets/file-merge.svg";
import fileToggle from "../assets/toggle-case.png";
import KeyboardShortcut from "./KeyboardShortcut";

export default {
  name: "EditorSettings",
  components: {
    KeyboardShortcut,
  },
  props: ["canToggle", "showShortcuts"],
  data() {
    return {
      exportWordDialogActive: false,
      penIcon,
      fileWord,
      fileCsv,
      fileMerge,
      fileToggle,
      commentsIcon,
      settings: {
        playbackSpeed: 1,
        pauseThreshold: 1.0,
        highlightConfidences: false,
        confidenceThreshold: 3,
        pauseMessage: "(.)",
        hesitationMessage: "(hesitation)",
        spaceBetween: " ", // '\xa0' for non breaking whitespace
      },
      sliderSettings: {
        pauseThresholdOptions: [0, 0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2.0],
        pauseThresholdLabels: [
          {
            label: "0s",
          },
          {
            label: "0.25s",
            isHide: true,
          },
          {
            label: "0.5s",
            isHide: true,
          },
          {
            label: "0.75s",
            isHide: true,
          },
          {
            label: "1s",
          },
          {
            label: "1.25s",
            isHide: true,
          },
          {
            label: "1.5s",
            isHide: true,
          },
          {
            label: "1.75s",
            isHide: true,
          },
          {
            label: "2s",
          },
        ],
        labelStyles: {
          color: "#000",
        },
        processStyles: {
          backgroundColor: "#ff5252",
        },
        tooltipStyles: {
          backgroundColor: "#ff5252",
          borderColor: "#ff5252",
        },
      },
      pauses: DISPLAY_PAUSE_GROUPS,
    };
  },
  computed: {
    ...mapGetters("transcript", [
      "getPlaybackSpeed",
      "getConfidenceThreshold",
      "getHighlightConfidence",
      "getPauseThreshold",
      "getShowPauses",
    ]),
    playbackSpeed: {
      set(speed) {
        const speedF = parseFloat(speed);
        AudioBus.$emit("changePlaybackRate", speedF);
        this.setPlaybackSpeed(parseFloat(speedF));
      },
      get() {
        return this.getPlaybackSpeed;
      },
    },
    confidenceThreshold: {
      set(threshold) {
        this.setConfidenceThreshold(parseInt(threshold));
      },
      get() {
        return this.getConfidenceThreshold;
      },
    },
    highlightConfidence: {
      set(highlight) {
        this.setHighlightConfidence(highlight);
      },
      get() {
        return this.getHighlightConfidence;
      },
    },
    pauseThreshold: {
      set(threshold) {
        this.setPauseThreshold(parseFloat(threshold));
      },
      get() {
        return this.getPauseThreshold;
      },
    },
    showPausesChecked: {
      set(checked) {
        this.$emit("show-pauses", checked);
      },
      get() {
        if (this.getShowPauses) {
          return true;
        } else {
          return false;
        }
      },
    },
  },
  methods: {
    ...mapMutations("transcript", [
      "setPlaybackSpeed",
      "setConfidenceThreshold",
      "setHighlightConfidence",
      "setPauseThreshold",
      "setShowPauses",
    ]),
    pauseLabel(pause) {
      if (!pause) {
        return this.$t("editorSettings.noPause");
      }
      return this.$t("editorSettings.pause", { duration: pause });
    },
  },
};
</script>

<style scoped>
.text-center {
  text-align: center;
}

.text-center-compact {
  padding: 0 !important;
  text-align: center;
}

.editor-settings {
  padding: 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.4);
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  width: 100%;
}

.editor-settings .md-field {
  margin: 0;
}

.pause-threshold-label {
  width: 100%;
  text-align: center;
}

.svg-img {
  filter: invert(1);
  width: 24px;
  height: 24px;
}

.edit-gaps-container {
  display: flex;
}

.edit-gaps-container ::v-deep label {
  text-align: initial;
  width: 90px;
}

.edit-gaps-img {
  display: flex;
  min-width: 14px;
  min-height: 14px;
  margin: 0 -5px;
}

.edit-gaps-button {
  cursor: pointer;
  border: none;
  background-color: transparent;
  align-self: center;
}

.png-img {
  width: 24px;
  height: 24px;
}

.editor-settings-checkbox {
  display: flex;
  min-width: 110px;
  max-width: 173px;
  margin-right: 8px;
}

.highlight-confidence .editor-settings-checkbox {
  margin: 0 !important;
}

.highlight-confidence ::v-deep label {
  text-align: initial;
}

.confidence-threshold {
  min-width: 120px;
  max-width: 133px;
}

.show-pause {
  min-width: 130px;
  max-width: 130px;
}

.shortcut-button {
  float: initial;
  right: 3px;
}

@media (max-width: 820px) {
  .editor-settings {
    padding: 10px 20px;
  }

  .left-settings {
    max-width: 100% !important;
    min-width: 100% !important;
    margin-bottom: 10px;
  }

  .right-settings {
    max-width: 100% !important;
    min-width: 100% !important;
  }

  .display-pause {
    max-width: 33%;
    min-width: 33%;
  }
  .highlight-confidence {
    max-width: 33%;
    min-width: 33%;
  }
  .confidence-threshold {
    float: right;
  }
  .threshold {
    max-width: 33%;
    min-width: 33%;
  }
}

@media (max-width: 600px) {
  .show-pause label,
  .show-pause select {
    font-size: 14px !important;
  }

  .editor-settings-checkbox ::v-deep label {
    font-size: 14px !important;
  }

  .confidence-threshold .md-select ::v-deep input,
  .confidence-threshold .md-select ::v-deep div {
    font-size: 14px !important;
  }

  .show-pause {
    min-width: 115px;
    max-width: 115px;
  }

  .editor-settings {
    padding: 5px;
  }

  .confidence {
    justify-content: space-between !important;
  }

  .md-icon-button {
    min-width: 35px !important;
    width: 35px !important;
    height: 35px !important;
  }
}

@media (min-width: 600px) {
  .hide-buttons {
    display: none;
  }
}

@media (max-width: 400px) {
  .display-pause {
    max-width: 40% !important;
    min-width: 40% !important;
  }
  .highlight-confidence {
    max-width: 40% !important;
    min-width: 40% !important;
  }
  .threshold {
    min-width: 75% !important;
    max-width: 75% !important;
  }
}
</style>
