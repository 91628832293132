import Vue from "vue";
import VueI18n from "vue-i18n";

import en from "../static/locales/en.json";
import de from "../static/locales/de.json";
import fr from "../static/locales/fr.json";
import it from "../static/locales/it.json";

Vue.use(VueI18n);

// Import all locale files above and add them in this messages variable
const messages = {
  en: en,
  de: de,
  fr: fr,
  it: it,
};

let locale;
if (!locale) {
  locale = "en";
}

export default new VueI18n({
  locale: locale,
  messages,
});
