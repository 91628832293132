<template>
  <md-dialog-content class="welcome-container">
    <div class="welcome-title">{{ $t("welcomeScreen.title") }}</div>
    <p class="welcome-thanks">{{ $t("welcomeScreen.thanks0") }}<br /> {{ $t("welcomeScreen.thanks1") }}</p>
    <div class="welcome-videos">{{ $t("welcomeScreen.videosIntro") }}</div>
    <WelcomeVideos/>
    <div>
      <button class="primary-large-btn" @click="$emit('close-welcome-screen')">{{ $t("welcomeScreen.startTranscription") }}</button>
    </div>
    <md-checkbox class="md-primary md-xsmall-size-10" v-model="checked" @change="disableWelcomeScreen">{{ $t("welcomeScreen.hideMessage") }}</md-checkbox>
  </md-dialog-content>
</template>

<script>
import WelcomeVideos from './WelcomeVideos';

export default {
  name: 'WelcomeScreen',
  components: { WelcomeVideos },
  data() {
    return {
      checked: false,
    };
  },
  methods: {
    disableWelcomeScreen() {
      this.$emit('disable-welcome-screen', this.checked);
    },
  },
};
</script>

<style scoped>
.welcome-container {
  text-align: center;
  padding: 16px;
}
.welcome-title {
  font-size: 45px;
  padding: 16px;
}
.welcome-thanks {
  font-size: 1.75em;
  padding: 8px;
  margin: 0;
  line-height: normal;
}
.welcome-videos {
  font-size: 1.75em;
  color: grey;
  padding: 8px;
}
</style>
