<template>
  <div>
    <md-progress-spinner v-if="form === null" md-mode="indeterminate" />
    <div v-else>
      <md-card>
        <md-card-header>
          <h1>Settings</h1>
        </md-card-header>
        <md-card-area>
          <md-card-content class="md-layout md-gutter">
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field>
                <label for="NEW_USER_CREDIT_AMOUNT">{{ $t("adminSettings.NEW_USER_CREDIT_AMOUNT") }}</label>
                <md-input v-model="form.NEW_USER_CREDIT_AMOUNT" name="NEW_USER_CREDIT_AMOUNT"
                  id="NEW_USER_CREDIT_AMOUNT" />
              </md-field>
            </div>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field>
                <label for="DEFAULT_CREDIT_PRICE_IN_RP">{{ $t("adminSettings.DEFAULT_CREDIT_PRICE_IN_RP") }}</label>
                <md-input v-model="form.DEFAULT_CREDIT_PRICE_IN_RP" name="DEFAULT_CREDIT_PRICE_IN_RP"
                  id="DEFAULT_CREDIT_PRICE_IN_RP" />
              </md-field>
            </div>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field>
                <label for="DEFAULT_SECONDS_PER_CREDIT">{{ $t("adminSettings.DEFAULT_SECONDS_PER_CREDIT") }}</label>
                <md-input v-model="form.DEFAULT_SECONDS_PER_CREDIT" name="DEFAULT_SECONDS_PER_CREDIT"
                  id="DEFAULT_SECONDS_PER_CREDIT" />
              </md-field>
            </div>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field>
                <label for="MINIMUM_PAYMENT_AMOUNT">{{ $t("adminSettings.MINIMUM_PAYMENT_AMOUNT") }}</label>
                <md-input v-model="form.MINIMUM_PAYMENT_AMOUNT" name="MINIMUM_PAYMENT_AMOUNT"
                  id="MINIMUM_PAYMENT_AMOUNT" />
              </md-field>
            </div>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field>
                <label for="SALE_IN_PERCENT">{{ $t("adminSettings.SALE_IN_PERCENT") }}</label>
                <md-input v-model="form.SALE_IN_PERCENT" name="SALE_IN_PERCENT" id="SALE_IN_PERCENT" />
              </md-field>
            </div>
          </md-card-content>
        </md-card-area>
        <md-card-actions>
          <md-button type="submit" class="md-raised md-primary save-button" @click="saveSettings">
            {{ $t("buttons.saveButton") }}
          </md-button>
        </md-card-actions>
      </md-card>
    </div>
  </div>
</template>

<script>
import ApiService from "../services/api.service";

export default {
  components: {},
  data: () => ({
    form: null,
  }),
  mounted() {
    ApiService.get("/Admin.settings").then((response) => {
      this.form = response.data.data;
    })
  },
  methods: {
    saveSettings() {
      ApiService.post("/Admin.settings", this.form).then((response) => {
        this.form = response.data.data;
      });
    },
  },
};
</script>

<style scoped>
.md-card-header>h2 {
  margin: 0;
}

.md-card {
  box-shadow: none;
  height: 100%;
}

.md-card-actions {
  margin-top: 1rem;
}

.md-tabs-content {
  overflow-y: auto;
}
</style>
