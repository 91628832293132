<template>
  <div class="editor-page">
    <GlobalEvents
      @keydown.ctrl.s.exact.stop.prevent="$refs.saveinterview.saveProject()"
      @keydown.ctrl.shift.s.stop.prevent="$refs.saveinterview.saveInterviewAs()"
    />
    <Header />
    <md-toolbar class="editor-toolbar">
      <div
        class="editor-controls md-layout md-gutter md-alignment-center-space-between"
      >
        <div
          class="md-layout md-gutter md-layout-item md-xsmall-size-100 md-size-70 md-alignment-center-left"
        >
          <div class="md-layout-item md-size-65 md-xsmall-size-100">
            <audio-player :transcript="getTranscript" />
          </div>

          <div class="md-layout-item md-size-30 md-xsmall-hide" id="ps">
            <PlaybackSpeed />
            <div class="md-layout-item md-alignment-top-left" id="spb">
              <span>
                <md-checkbox
                  v-model="activateSmartPlayback"
                  class="md-primary"
                  >{{ $t("editorSettings.activateSmartPlayback") }}</md-checkbox
                >
                <button
                  class="edit-gaps-button"
                  v-on:click="
                    smartPlaybackDialogActive = !smartPlaybackDialogActive
                  "
                >
                  <img :src="penIcon" class="edit-gaps-img" />
                  <md-tooltip md-direction="top">{{
                    $t("editorSettings.smartPlaybackDialog.tooltip")
                  }}</md-tooltip>
                </button>
              </span>
            </div>
            <SmartPlaybackDialog
              :show="smartPlaybackDialogActive"
              @close-window="smartPlaybackDialogActive = false"
            />
          </div>
        </div>
        <div
          class="md-layout md-gutter md-layout-item md-size-30 md-xsmall-hide md-alignment-center-left button-container"
        >
          <div class="md-layout-item text-center-compact">
            <md-button
              class="md-icon-button md-raised md-primary"
              v-on:click="exportTxtDialogActive = true"
            >
              <img :src="fileTxt" class="svg-img" />
              <md-tooltip md-direction="top">{{
                $t("editor.export.exportToTxt")
              }}</md-tooltip>
            </md-button>
          </div>
          <div class="md-layout-item text-center-compact">
            <md-button
              class="md-icon-button md-raised md-primary"
              v-on:click="exportSrtDialogActive = true"
            >
              <img :src="fileSrt" class="svg-img" />
              <md-tooltip md-direction="top">{{
                $t("editor.export.exportToSrt")
              }}</md-tooltip>
            </md-button>
          </div>

          <div class="md-layout-item text-center-compact">
            <md-button
              class="md-icon-button md-raised md-primary"
              v-on:click="exportWordDialogActive = true"
            >
              <img :src="fileWord" class="svg-img" />
              <md-tooltip md-direction="top">{{
                $t("editor.export.exportToWord")
              }}</md-tooltip>
            </md-button>
          </div>
          <div class="md-layout-item text-center-compact">
            <md-button
              class="export-csv md-icon-button md-raised md-primary"
              v-on:click="exportToCsv()"
            >
              <img :src="fileExcel" class="png-img" />
              <md-tooltip md-direction="top">{{
                $t("editor.export.exportToCsv")
              }}</md-tooltip>
            </md-button>
          </div>
          <div class="md-layout-item text-center-compact">
            <SaveInterview
              ref="saveinterview"
              :interviewFilePath="interviewFilePath"
              :interview="interview"
              :audioFile="audioFile"
              @show-alert="getAlert($event)"
              @set-interview-file-path="setInterviewFilePath($event)"
              @show-snackbar="openSnackbar($event)"
            />
          </div>
          <div class="md-layout-item text-center-compact">
            <md-button
              class="shortcut-button md-icon-button md-raised md-primary"
              @click="showShortcuts = !showShortcuts"
            >
              <md-icon>keyboard</md-icon>
              <md-tooltip md-direction="top">{{
                $t("editor.tooltip.keyboardShortcut")
              }}</md-tooltip>
            </md-button>
          </div>
          <div
            v-if="isAdmin || isPoweruser"
            class="md-layout-item text-center-compact"
          >
            <md-button
              class="shortcut-button md-icon-button md-raised md-primary"
              @click="$router.push('/summary')"
            >
              <md-icon>edit_note</md-icon>
              <md-tooltip md-direction="top">{{
                $t("editor.tooltip.summary")
              }}</md-tooltip>
            </md-button>
          </div>
          <div
            v-if="isAdmin || isPoweruser"
            class="md-layout-item text-center-compact"
          >
            <md-button
              class="shortcut-button md-icon-button md-raised md-primary"
              @click="$router.push('/translation')"
            >
              <md-icon>language</md-icon>
              <md-tooltip md-direction="top">{{
                $t("editor.tooltip.translation")
              }}</md-tooltip>
            </md-button>
          </div>
        </div>
      </div>
      <EditorSettings
        class="md-xsmall-hide"
        v-on:change-settings="changeSettings($event)"
        v-on:copy-transcript="exportToClipboard"
        v-on:export-word="exportWordDialogActive = true"
        v-on:export-txt="exportTxtDialogActive = true"
        v-on:export-srt="exportSrtDialogActive = true"
        v-on:export-csv="exportToCsv"
        v-on:open-speaker-dialog="openSpeakerDialog"
        v-on:open-gap-settings-dialog="openGapDialog"
        :canToggle="canToggle"
        v-on:toggle-case="toggle = true"
        v-on:undo-task="undoTask = true"
        v-on:redo-task="redoTask = true"
        :showShortcuts="showShortcuts"
        v-on:close-shortcut="showShortcuts = false"
        v-on:show-pauses="setShowGaps($event)"
      >
      </EditorSettings>
    </md-toolbar>

    <div class="main-content">
      <div class="editor">
        <PMEditor
          ref="pmEditor"
          :document="getTranscript.document"
          @speaker-selected="selectedSpeaker($event)"
          @can-toggle="canToggle = $event"
          :toggle="toggle"
          @toggle-complete="toggle = false"
          :undoTask="undoTask"
          @undo-done="undoTask = false"
          :redoTask="redoTask"
          @redo-done="redoTask = false"
          @pause-clicked="setGapEditorPhrase($event)"
        />
        <SpeakerSettings ref="speakersettings" />
        <GapEditorDialog
          v-if="gapEditorDialogActive"
          ref="gapEditorDialog"
          :document="getTranscript.document"
          :phrase="gapPhrase"
          :speakerId="speakerId"
          :gapSubtext="gapSubtext"
          @set-gap-no-speech="setPhraseNoSpeech"
          @set-gap-pause="setPhrasePause"
          @set-gap-parallel-speech="setPhraseParallelSpeech"
          @set-gap-word="setGapWord($event)"
          @gap-editor-dialog-close="gapEditorDialogActive = false"
        />
      </div>
    </div>

    <Snackbar ref="snackbar">{{ snackbar.message }}</Snackbar>

    <Footer />

    <ExportWordDialog
      :show="exportWordDialogActive"
      @close-window="exportWordDialogActive = false"
      v-on:export="exportWord($event)"
    />
    <ExportTxtDialog
      :show="exportTxtDialogActive"
      @close-window="exportTxtDialogActive = false"
      v-on:export="exportTxt($event)"
    />
    <ExportSrtDialog
      :show="exportSrtDialogActive"
      @close-window="exportSrtDialogActive = false"
      v-on:export="exportSrt($event)"
    />
    <GapSettingsDialogue
      ref="gapSettingsDialog"
      :show="gapSettingsDialogActive"
      @close-window="gapSettingsDialogActive = false"
    />
  </div>
</template>

<script>
import penIcon from "../assets/svgs/solid/pen.svg";
import AudioPlayer from "../components/AudioPlayer";
import Header from "../components/Header";
import Footer from "../components/Footer";
import SaveInterview from "../components/SaveInterview";
import Snackbar from "../components/Snackbar";
import GlobalEvents from "vue-global-events";
import EditorSettings from "../components/EditorSettings";
import ExportMixin from "../components/ExportMixin";
import ExportWordDialog from "../components/ExportWordDialog";
import SpeakerSettings from "../components/SpeakerSettings";
import PMEditor from "../components/PMEditor";
import ExportTxtDialog from "../components/ExportTxtDialog";
import ExportSrtDialog from "../components/ExportSrtDialog";
import { mapGetters, mapMutations } from "vuex";
import fileWord from "../assets/file-word.svg";
import fileExcel from "../assets/excel-export.png";
import fileTxt from "../assets/file-txt.svg";
import fileSrt from "../assets/file-srt.svg";
import commentsIcon from "../assets/svgs/regular/comments.svg";
import PlaybackSpeed from "../components/PlaybackSpeed";
import GapEditorDialog from "../components/GapEditorDialog";
import GapSettingsDialogue from "../components/GapSettingsDialog";
import SmartPlaybackDialog from "../components/SmartPlaybackDialog";
import smartPlaybackIcon from "../assets/svgs/solid/play-circle.svg";

export default {
  name: "EditorPage",
  components: {
    AudioPlayer,
    PMEditor,
    Header,
    Footer,
    SaveInterview,
    Snackbar,
    EditorSettings,
    SpeakerSettings,
    GlobalEvents,
    ExportWordDialog,
    ExportTxtDialog,
    ExportSrtDialog,
    PlaybackSpeed,
    GapEditorDialog,
    GapSettingsDialogue,
    SmartPlaybackDialog,
  },
  mixins: [ExportMixin],

  data: () => ({
    penIcon,
    fileWord,
    fileExcel,
    fileTxt,
    fileSrt,
    commentsIcon,
    audioFile: {},
    interviewFilePath: "",
    interview: {
      transcript: {
        speaker_frames: [],
      },
      audio: {
        metadata: {
          language: "en-US",
          speaker_count: 1,
          backend: "GOOGLE",
        },
      },
    },
    snackbar: {
      type: "",
      message: "",
    },
    exportWordDialogActive: false,
    exportTxtDialogActive: false,
    exportSrtDialogActive: false,
    gapSettingsDialogActive: false,
    canToggle: false,
    toggle: false,
    undoTask: false,
    redoTask: false,
    showShortcuts: false,
    gapPhrase: null,
    gapEditorDialogActive: false,
    speakerId: null,
    gapSubtext: "",
    smartPlaybackDialogActive: false,
    smartPlaybackIcon,
  }),
  computed: {
    ...mapGetters("transcript", [
      "getTranscript",
      "getPlaybackSpeed",
      "getShowPauses",
      "getSmartPlaybackState",
    ]),
    ...mapGetters("JWT", {
      isAdmin: "isAdmin",
      isPoweruser: "isPoweruser",
    }),
    activateSmartPlayback: {
      get() {
        return this.getSmartPlaybackState;
      },
      set(val) {
        this.setSmartPlaybackState(val);
      },
    },
    isTranscriptEnglish() {
      return this.getTranscript.language === "ENGLISH";
    },
  },
  methods: {
    ...mapMutations("transcript", [
      "setPlaybackSpeed",
      "setTranscriptDocument",
      "setShowPauses",
      "setSmartPlaybackState",
    ]),
    setInterview(interview) {
      this.interview = interview;
    },
    transcriptHasChanged() {
      this.transcript.changed = true;
    },
    openSnackbar(message) {
      this.snackbar = message;
      this.$refs.snackbar.open(message.type);
      if (process.env.VUE_APP_CONSOLE_WRITE) {
        console.log("snackbar message:", message);
      }
    },
    setInterviewFilePath(path) {
      this.interviewFilePath = path;
      this.transcript.changed = false;
      this.switchStep(3);
    },
    openSpeakerDialog() {
      let speakerIdsInUse = new Set();
      if (this.$refs.pmEditor) {
        speakerIdsInUse = this.$refs.pmEditor.getSpeakerIdsInUse;
      }
      this.$refs.speakersettings.openSpeakerSettingsDialog(speakerIdsInUse);
    },
    openGapDialog() {
      this.$refs.gapSettingsDialog.openGapSettingsDialog();
    },
    exportWord(event) {
      this.exportWordDialogActive = false;
      this.exportToWord(event);
    },
    exportTxt(event) {
      this.exportTxtDialogActive = false;
      this.exportToTxt(event);
    },
    exportSrt(event) {
      this.exportSrtDialogActive = false;
      this.exportToSrt(event);
    },
    setShowGaps(show) {
      this.setShowPauses(show ? this.$refs.gapSettingsDialog.lastPause : false);
      this.$refs.gapSettingsDialog.gapsActive = show;
    },
    setGapEditorPhrase(event) {
      this.gapPhrase = event.target;
      this.speakerId = event.speakerId;
      this.gapSubtext = event.gapSubtext;
      this.gapEditorDialogActive = true;
    },
    setPhraseNoSpeech() {
      this.$refs.pmEditor.setPhraseNoSpeech();
    },
    setPhraseParallelSpeech() {
      this.$refs.pmEditor.setPhraseParallelSpeech();
    },
    setPhrasePause() {
      this.$refs.pmEditor.setPhrasePause();
    },
    setGapWord(text) {
      this.$refs.pmEditor.setPhraseWord(text);
    },
  },
  mounted() {},
};
</script>

<style scoped>
#spb > span {
  float: left;
}
.editor-page {
  position: relative;
  overflow-y: hidden;
}

.main-content {
  min-height: calc(100vh - 270px);
  overflow-x: hidden;
}

.editor-controls {
  width: 100%;
}

.editor-toolbar {
  padding: 0;
}

.text-center-compact {
  padding: 0 !important;
  text-align: center;
}

.svg-img {
  filter: invert(1);
  width: 24px;
  height: 24px;
}

.edit-gaps-img {
  min-width: 14px;
  min-height: 14px;
  margin-top: -1.2em;
}

.edit-gaps-button {
  cursor: pointer;
  border: none;
  background-color: transparent;
  align-self: left;
}

.png-img {
  width: 24px;
  height: 24px;
}

.text-center-compact {
  padding: 0 !important;
  text-align: center;
}

.md-layout-item {
  margin-bottom: 5px;
}

#ps {
  margin-right: 0;
  padding-right: 0;
}

@media (max-width: 600px) {
  .md-icon-button {
    min-width: 35px !important;
    width: 35px !important;
    height: 35px !important;
  }
}

@media screen and (max-width: 991px) {
  /* start of large tablet styles */
  .main-content {
    min-height: calc(100vh - 254px);
    overflow-x: hidden;
  }
}
@media screen and (max-width: 820px) {
  /* start of medium tablet styles */
  .main-content {
    min-height: calc(100vh - 284px);
    overflow-x: hidden;
  }
}
@media screen and (max-width: 600px) {
  /* start of phone styles */
  .main-content {
    min-height: calc(100vh - 147px);
    overflow-x: hidden;
  }
}
</style>
