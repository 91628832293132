<template>
  <div class="login-page">
    <md-content class="md-elevation-3">
      <div class="title md-layout">
        <md-avatar class="md-large">
          <img :src="logo" class="svg-img brand-logo" />
        </md-avatar>
        <div class="md-title md-layout-item title-text">
          {{ $t("login.title") }}
        </div>
      </div>

      <form
        id="login_form"
        novalidate
        class="md-layout"
        @submit.prevent="validateLogin"
      >
        <span class="error-class" v-if="error_message">{{
          error_message
        }}</span>

        <md-field :class="getValidationClass('email')">
          <label for="email">{{ $t("fieldName.email") }}</label>
          <md-input
            name="email"
            id="email"
            :placeholder="$t('fieldName.email')"
            v-model="form.email"
            :disabled="sending || mfa_token_required"
          />
          <span class="md-error" v-if="!$v.form.email.required">{{
            $t("users.emailRequired")
          }}</span>
          <span class="md-error" v-else-if="!$v.form.email.email">{{
            $t("users.emailInvalid")
          }}</span>
        </md-field>
        <md-field :class="getValidationClass('password')" md-has-password>
          <label for="password">{{ $t("fieldName.password") }}</label>
          <md-input
            name="password"
            id="password"
            :placeholder="$t('fieldName.password')"
            v-model="form.password"
            :disabled="sending || mfa_token_required"
            type="password"
          />
          <span class="md-error" v-if="!$v.form.password.required">{{
            $t("users.passwordRequired")
          }}</span>
        </md-field>
        <md-field v-if="mfa_token_required">
          <label for="mfa_token">Token</label>
          <md-input
            name="mfa_token"
            id="mfa_token"
            v-model="form.mfa_token"
            :disabled="sending"
          />
        </md-field>
        <span class="signup-link"
          >{{ $t("signupLink.noAccount") }}
          <a href="/signup">{{ $t("signupLink.register") }}</a>
        </span>
        <span class="reset-link">
          <a @click="setActiveEmailAsk(true)">{{
            $t("buttons.resetPassword")
          }}</a>
        </span>
        <div class="actions md-layout md-alignment-center-right">
          <md-button
            type="submit"
            class="md-raised md-primary"
            for="login_form"
            >{{ $t("buttons.loginButton") }}</md-button
          >
        </div>
      </form>

      <EmailAsk></EmailAsk>
    </md-content>
  </div>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import { mapGetters, mapActions, mapMutations } from "vuex";
import logo from "../assets/Stenoris_Logo_min.svg";
import EmailAsk from "../components/EmailAsk";

export default {
  name: "LoginPage",
  components: {
    EmailAsk,
  },
  data: () => ({
    logo,
    form: {
      email: "",
      password: "",
      mfa_token: ""
    },
    mfa_token_required: false,
    sending: false,
    error_message: "",
  }),
  computed: {
    ...mapGetters("JWT", ["access_token"]),
    ...mapGetters("user", {
      fieldLength: "getFieldLength",
      resetActive: "getActiveEmailAsk",
    }),
  },
  validations() {
    const data = {
      form: {
        email: {
          required,
          email,
        },
        password: {
          required,
        },
      },
    };
    return data;
  },
  watch: {
    error_message() {
      if (this.error_message) {
        setTimeout(() => {
          this.error_message = "";
        }, 60000);
      }
    },
  },
  methods: {
    ...mapMutations("user", ["setActiveEmailAsk"]),
    ...mapActions("JWT", ["login"]),
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    tryLogin() {
      this.sending = true;
      var current = this;

      this.login({
        // #Security...
        email: this.form.email,
        password: this.form.password,
        mfa_token: this.form.mfa_token
      }).then(
        (result) => {
          if (result == "token_required") {
            // mfa token required
            this.mfa_token_required = true;
          }
          else if (result == "setup_mfa") {
            // mfa setup needed
            current.$router.push({ name: "SetupMFAPage" });
          }
          else {
            // successful login
            this.mfa_token_required = false;
            current.$router.push({ name: "Dashboard" });
          }
          current.sending = false;
      }).catch(
        (err) => {
          this.error_message = err.message;
          this.mfa_token_required = false;
          this.form.mfa_token = "";
          this.sending = false;
        }
      );
    },
    validateLogin() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.tryLogin();
      }
    },
  },
  mounted() {
    this.setActiveEmailAsk(false);
  },
};
</script>

<style scoped lang="scss">
.login-page {
  background-color: $color-sea-breeze;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100vh;
  .title {
    text-align: center;
    margin-bottom: 30px;
  }
  .signup-link,
  .reset-link {
    margin-top: 30px;
    font-size: 16px;
    text-align: initial !important;
    cursor: pointer;
  }
  .actions {
    margin-top: 10px;
    .md-button {
      margin: 0;
    }
  }
  .form {
    margin-bottom: 60px;
  }
  .md-content {
    z-index: 1;
    padding: 40px;
    width: 100%;
    max-width: 400px;
    position: relative;
  }
  .loading-overlay {
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .brand-logo {
    margin: 0 !important;
    padding: 0 !important;
    height: 100%;
    width: 100%;
  }
  .title-text {
    align-self: center;
  }
}
</style>
