<template>
  <md-card class="subscription-card">
    <md-card-header>
      <md-card-header-text>
        <div class="md-title">{{ subscriptionName }}</div>
      </md-card-header-text>
    </md-card-header>
    <md-card-content>
      <StripeCardElement
        :priceInCHF="subscriptionPriceInCHF"
        :monthly="true"
        :setLoading="cardElementLoading"
        @handle-butten-clicked="cardElementLoading = true"
        @handle-payment="subscribe"
      />
    </md-card-content>
  </md-card>
</template>

<script>
import StripeCardElement from "./StripeCardElement.vue";
import subscriptionMixin from "../../mixins/subscriptionMixin.js";

export default {
  name: "SubscriptionPayment",
  props: ["planId"],
  data() {
    return {
      cardElementLoading: false,
    };
  },
  components: {
    StripeCardElement,
  },
  mixins: [subscriptionMixin],
  methods: {
    subscribe(result) {
      this.cardElementLoading = true;
      if (result.error) {
        this.showError(result.error.message);
        return;
      }

      const body = {
        plan_id: this.planId,
        payment_method_id: result.paymentMethod.id,
      };
      this.createSubscription(body)
        .then((response) => {
          if (response.data.error) {
            this.$notify({ text: response.data.error });
          } else {
            this.$emit("close");
          }
        })
        .catch(() => {
          this.$notify({ text: this.$t("payment.paymentFailed") });
        })
        .finally(() => {
          this.cardElementLoading = false;
        });
    },
  },
};
</script>

<style scoped>
.subscription-card {
  width: 50%; /* 50% of the viewport width */
  margin: 0 auto; /* Center it horizontally */
  box-shadow: none;
  align-self: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  text-align: center;
}
.md-card-header {
  display: flex;
  align-items: center;
}

.close-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #333;
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .subscription-card {
    width: 90%; /* 90% width on smaller screens for responsiveness */
  }
}
</style>
