<template>
  <div>
    <md-dialog-confirm
      :md-active.sync="deletionConfirmationActive"
      :md-title="'Delete Audio File?'"
      :md-content="'Really delete the file?'"
      :md-confirm-text="'Confirm'"
      :md-cancel-text="'Cancel'"
      @md-confirm="deleteFile()"
    />

    <md-progress-spinner v-if="files === null" md-mode="indeterminate" />
    <md-list v-else>
      <md-list-item v-for="file in files" v-bind:key="file.id">
        <div class="md-list-item-text">
          <div>Name: {{file.upload_name}}</div>
          <div>Internal Name: {{file.name}}</div>
          <div>User: {{file.user.name}}</div>
          <div>Date: {{file.modified_on || 'FILE MISSING'}}</div>
        </div>
        <md-button @click="showDeleteConfirmation(file)">
          <md-icon>delete</md-icon>
        </md-button>
      </md-list-item>
    </md-list>
  </div>
</template>
<script>
import ApiService from '../services/api.service';

export default {
  data() {
    return {
      files: null,
      currentFile: null,
      deletionConfirmationActive: false,
    };
  },
  methods: {
    getFile() {
      ApiService.get('/Audiofile.get_orphans').then(response => {
        this.files = response.data.data;
        this.files.reverse();
      }).catch(error => this.$notify({ text: error }));
    },
    showDeleteConfirmation(file) {
      this.currentFile = file;
      this.deletionConfirmationActive = true;
    },
    deleteFile() {
      ApiService.post('/Audiofile.delete', { id: this.currentFile.id }).then(response => {
        this.getFile();
      }).catch(error => this.$notify({ text: error }));
    },
  },
  mounted: function() {
    this.getFile();
  },
};
</script>
