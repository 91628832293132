<template>
    <div class="md-layout-item">
        <audio ref="htmlPlayer" controls controlslist="nodownload" class="play" @error="audioLoadingError"
            v-on:play="start" v-on:pause="stop">
            <source :src="audioSrc" type="audio/wav" />
            <source :src="audioSrc" type="audio/mpeg" />
            <source :src="audioSrc" type="audio/ogg" />
            Your browser does not support the audio element.
        </audio>
    </div>
</template>

<script>
import { AudioBus } from "../../audioBus";
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
    name: "SimpleAudioPlayer",
    props: ["audio_id"],
    data: function () {
        return {
            lastPauseTime: 0,
            currentAudioTime: 0,
            isPlaying: false,
        };
    },
    computed: {
        ...mapGetters("JWT", ["access_token"]),
        audioPath() {
            return `${process.env.VUE_APP_PROTOCOL}://${process.env.VUE_APP_HOST}:${process.env.VUE_APP_PORT}/Audiofile.read?id=${this.audio_id}`;
        },
        audioSrc() {
            return `${this.audioPath}&jwt=${this.access_token}`;
        },
    },
    methods: {
        play(startTime) {
            this.isPlaying = true;
            this.$refs.htmlPlayer.currentTime = startTime || this.lastPauseTime;
            this.$refs.htmlPlayer.play();
        },
        stop() {
            this.isPlaying = false;
            this.$refs.htmlPlayer.pause();
            this.lastPauseTime = this.$refs.htmlPlayer.currentTime;
        },
        start() {
            this.isPlaying = true;
        },
        resumeAtEditPosition(time) {
            this.$refs.htmlPlayer.currentTime = this.currentAudioTime - time;
            this.$refs.htmlPlayer.play();
        },
        resumeBeforeAfterEditPosition(time) {
            this.$refs.htmlPlayer.currentTime = time;
            this.$refs.htmlPlayer.play();
        },
        audioLoadingError() {
            console.log("not implemented");
        },
    },
    created() {
        AudioBus.$on("play", this.play);
        AudioBus.$on("start", this.start);
        AudioBus.$on("stop", this.stop);
        AudioBus.$on("loadAudioFile", this.loadAudioFile);
    },
    mounted() {
    },
    beforeDestroy() {
        AudioBus.$off("play", this.play);
        AudioBus.$off("start", this.start);
        AudioBus.$off("stop", this.stop);
        AudioBus.$off("loadAudioFile", this.loadAudioFile);
    },
    destroyed() {
    },
    directives: {
        focus: {
            inserted(e) {
                e.focus();
            },
        },
    },
};
</script>

<style scoped>
audio {
    height: 32px !important;
    width: 100%;
    margin: 15px 0;
}

@media (max-width: 600px) {
    audio {
        margin: 5px 0 0 0;
    }
}
</style>