import Router from "vue-router";
import store from "../store";

const router = new Router({
  mode: "history",
  routes: [
    // {
    //   path: '*',
    //   name: 'MaintenancePage',
    //   component: require('@/components/MaintenancePage').default,
    // },
    {
      path: "/error",
      name: "EmailError",
      component: require("@/pages/EmailError").default,
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/help",
      name: "HelpPage",
      component: require("@/pages/HelpPage").default,
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/reset_password",
      name: "ResetPassword",
      component: require("@/pages/ResetPassword").default,
      meta: {
        requiresAuth: false,
        unavailableForUsers: true,
      },
    },
    {
      path: "/editor",
      name: "EditorPage",
      component: require("@/pages/EditorPage").default,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/translation",
      name: "CreateTranslation",
      component: require("@/pages/CreateTranslation").default,
      meta: {
        requiresAuth: true,
      },
    },
    // {
    //   path: "/highlights-sentences",
    //   name: "Highlights",
    //   component: require("@/pages/HighlightsPage").default,
    //   meta: {
    //     requiresAuth: true,
    //   },
    // },
    {
      path: "/summary",
      name: "CreateSummary",
      component: require("@/pages/CreateSummary").default,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/",
      name: "Dashboard",
      component: require("@/pages/Dashboard").default,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/usersettings",
      name: "UserSettings",
      component: require("@/pages/UserSettings").default,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/admin",
      name: "AdminPage",
      component: require("@/pages/AdminPage").default,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/admin/users/:id",
      name: "UserDetail",
      component: require("@/pages/UserDetail").default,
      props: true,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/login",
      name: "LoginPage",
      component: require("@/pages/LoginPage").default,
      meta: {
        requiresAuth: false,
        unavailableForUsers: true,
      },
    },
    {
      path: "/signup",
      name: "SignupPage",
      component: require("@/pages/SignupPage").default,
      meta: {
        requiresAuth: false,
        unavailableForUsers: true,
      },
    },
    {
      path: "*",
      redirect: "/",
      meta: {
        requiresAuth: true,
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    !store.getters["JWT/access_token"]
  ) {
    next({
      path: "/login",
      params: { nextUrl: to.fullPath },
    });
  } else if (
    to.matched.some((record) => record.meta.unavailableForUsers) &&
    store.getters["JWT/access_token"]
  ) {
    next({ path: "/" });
  } else {
    next();
  }
});

export default router;
