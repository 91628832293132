import { Node } from 'tiptap';
import { TextSelection } from 'prosemirror-state';
import { mapActions, mapGetters } from 'vuex';

export const DISPLAY_PAUSE_GROUPS = [
  false,
  0.3,
  0.5,
  1,
  2,
  5,
];

function confidenceToClass(confidence) {
  function getColor(threshold) {
    let highlight;
    const thresholdMap = {
      1: [0.25, 0.5],
      2: [0.5, 0.7],
      3: [0.65, 0.8],
      4: [0.80, 0.9],
      5: [0.85, 0.95],
    };
    const [lowConfidenceTop, mediumConfidenceTop] = thresholdMap[threshold];

    if (confidence >= 0 && confidence < lowConfidenceTop) {
      highlight = 'red';
    } else if (confidence >= lowConfidenceTop && confidence < mediumConfidenceTop) {
      highlight = 'yellow';
    } else if (confidence >= mediumConfidenceTop && confidence <= 100) {
      highlight = 'green';
    }
    return 't' + threshold + '-' + highlight;
  }

  const classes = [];
  for (let i = 1; i < 6; i++) {
    classes.push(getColor(i));
  }
  return classes.join(' ');
}

function pauseToClass(startTime, endTime) {
  const difference = endTime - startTime;
  const firstGroup = DISPLAY_PAUSE_GROUPS.slice().reverse().find(g => g && g <= difference);
  return firstGroup ? `pause-group-${firstGroup.toString().replace('.', '-')}` : '';
}

function pauseContent(node) {
  return ((node.attrs.endTime - node.attrs.startTime)).toFixed(2);
}

export class Phrase extends Node {
  get name() {
    return 'phrase';
  }

  get schema() {
    return {
      content: 'text*',
      attrs: {
        startTime: { default: '' },
        endTime: { default: '' },
        type: { default: 'manual' },
        confidence: { default: -1 },
      },
      inline: true,
      parseDOM: [{
        tag: 'phrase',
        getAttrs: dom => ({
          startTime: dom.getAttribute('startTime'),
          endTime: dom.getAttribute('endTime'),
          type: dom.getAttribute('type'),
          confidence: dom.getAttribute('confidence'),
        }),
        preserveWhitespace: true,
      }],
      toDOM: node => ['phrase', {
        startTime: node.attrs.startTime,
        endTime: node.attrs.endTime,
        type: node.attrs.type,
        confidence: node.attrs.confidence,
        class: ['WORD', 'manual'].includes(node.attrs.type) ? confidenceToClass(node.attrs.confidence) : pauseToClass(node.attrs.startTime, node.attrs.endTime),
      }, ['WORD', 'manual'].includes(node.attrs.type) ? 0 : `${pauseContent(node)}`],
    };
  }
}

export class SpeakerFrame extends Node {
  constructor(vm) {
    super();
    this.vm = vm;
  }

  get name() {
    return 'speaker_frame';
  }

  get schema() {
    return {
      content: 'phrase*',
      attrs: {
        startTime: { default: '' },
        endTime: { default: '' },
        parallel: { default: 'false' },
        speakerId: { default: -1 },
      },
      parseDOM: [{
        tag: 'speaker-frame',
        getAttrs: dom => ({
          startTime: dom.getAttribute('startTime'),
          endTime: dom.getAttribute('endTime'),
          parallel: dom.getAttribute('parallel'),
          speakerId: dom.getAttribute('speakerId'),
        }),
      }],
      toDOM: node => ['speaker-frame', {
        startTime: node.attrs.startTime,
        endTime: node.attrs.endTime,
        parallel: node.attrs.parallel,
        speakerId: node.attrs.speakerId,
      }, 0],
    };
  }

  get view() {
    return {
      props: ['node', 'getPos', 'editor'],
      computed: {
        ...mapGetters('transcript', [
          'getSpeakerNameById',
        ]),
        speakerName: {
          get() {
            return this.getSpeakerNameById(this.node.attrs.speakerId);
          },
          set(value) {
            this.changeSpeakerName({ speakerId: this.node.attrs.speakerId, name: value });
          },
        },
        startTime() {
          return this.node.child(0).attrs.startTime;
        },
        endTime() {
          return this.node.child(this.node.childCount - 1).attrs.endTime;
        },
        editable() {
          return this.editor.options.editable;
        },
      },
      methods: {
        ...mapActions('transcript', [
          'changeSpeakerName',
        ]),
        selectWholeFrame() {
          const tr = this.editor.state.tr;
          const selection = TextSelection.create(tr.doc, this.getPos() + 2, this.getPos() + this.node.nodeSize - 2);
          tr.setSelection(selection);
          this.editor.dispatchTransaction(tr);
          this.editor.focus();
        },
      },
      template: `
        <div class="segment md-layout md-gutter md-alignment-top-space-between">
          <div contenteditable="false" class="md-layout-item md-size-15 md-small-size-20 md-xsmall-size-25" @contextmenu.prevent="selectWholeFrame()">
            <div class="speaker">
              <input tabindex="-1" type="text" v-bind:readonly="!editable" v-model="speakerName">
              <span class="timestamp-text">{{parseSeconds(startTime, {showUnit: false})}} - {{parseSeconds(endTime, {showUnit: false})}}</span>
              <md-tooltip v-show="editable" md-direction="top">Timestamp {{parseSeconds(startTime)}} - {{parseSeconds(endTime)}}</md-tooltip>
            </div>
          </div>
          <div :contenteditable="editable" class="frame md-layout-item md-size-85 md-small-size-80 md-xsmall-size-75 utterance" ref="content"></div>
        </div>
      `,
    };
  }
}

export class Transcription extends Node {
  get name() {
    return 'transcription';
  }

  get schema() {
    return {
      content: 'speaker_frame*',
      parseDOM: [{
        tag: 'transcription',
      }],
      toDOM: () => ['transcription', 0],
    };
  }
}

export class Doc extends Node {
  get name() {
    return 'doc';
  }

  get schema() {
    return {
      content: 'transcription',
    };
  }
}
