<template>
  <div>
    <Header />
    <div class="main-content">
      <slot />
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "./Header";
import Footer from "./Footer";

export default {
  components: {
    Header,
    Footer,
  },
};
</script>

<style>
.main-content {
  min-height: calc(
    100vh - 88px
  ); /* screen height - 64px for header and 24px for footer*/
  overflow-x: hidden;
}
</style>
