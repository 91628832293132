<template>
  <div class="load-interview">
    <md-steppers class="steppers" :md-active-step.sync="active" md-vertical md-linear>
      <md-step id="first" :md-label="$t('loadAudio.importFile')" :md-editable="false" :md-done.sync="first">
        <md-field>
          <label>{{ $t('loadInterview.openFile') }}</label>
          <md-file accept=".its" id="interviewFileInput" ref="interviewFile"  @change="setInterview($event)"/>
        </md-field>
        <md-button class="md-raised md-primary next-button" @click="openFile" :disabled="interviewFileEntry === null">
          {{$t('loadAudio.next')}}
        </md-button>
        <div v-if="interviewFilePath">
          {{ $t('loadInterview.loading') }}
        </div>
      </md-step>

      <md-step id="second" :md-label="$t('loadAudio.projectName')" :md-editable="true" :md-done.sync="second">
        <md-field class="field-project-name">
          <label>{{$t('loadAudio.project')}}</label>
          <md-input v-model="projectName" v-on:change="setProjectName($event.target.value)"></md-input>
        </md-field>
        <NameExistsError v-if="!isValidName"></NameExistsError>
        <md-button class="md-raised md-primary open-project-button" @click="openProject" v-bind:disabled="loading || !isNameChecked">
          <span v-if="!loading">{{$t('loadAudio.openInterview')}}</span>
          <md-progress-spinner v-else :md-diameter="30" :md-stroke="3" md-mode="indeterminate"></md-progress-spinner>
        </md-button>
      </md-step>
    </md-steppers>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import JSZip from 'jszip';
import NameExistsError from './NameExistsError';

export default {
  name: 'LoadInterview',
  components: {
    NameExistsError,
  },
  data() {
    return {
      interviewFilePath: null,
      audioFileEntry: null,
      interviewFileEntry: null,
      isValidName: false,
      isNameChecked: false,
      isSuggestedNameSet: false,
      audioFile: {
        file: null,
        localPath: '',
        metadata: {
          contentType: '',
        },
      },
      active: 'first',
      first: false,
      second: false,
      projectName: '',
      loading: true,
    };
  },
  methods: {
    ...mapActions('transcript', [
      'importInterview',
      'fetchInterview',
      'suggestProjectName',
      'isValidProjectName',
    ]),
    setDone(id, index) {
      this[id] = true;
      if (index) {
        this.active = index;
      }
    },
    async getProjectName(file) {
      await new JSZip()
        .loadAsync(file)
        .then(async(zip) => {
          await zip.file('transcript.json').async('string')
            .then(async(text) => {
              this.projectName = await this.suggestProjectName(JSON.parse(text).name);
            }).catch(error => this.$notify({ text: error }));
        }).catch(error => this.$notify({ text: error }));
    },
    async openFile() {
      this.loading = true;
      if (this.interviewFileEntry === null) {
        return;
      }

      this.interviewFilePath = this.interviewFileEntry.path;
      await this.getProjectName(this.interviewFileEntry);
      this.isSuggestedNameSet = true;
      this.isNameChecked = true;
      this.isValidName = true;
      this.loading = false;
      this.setDone('first', 'second');
    },
    async setProjectName(name) {
      this.projectName = name;
      this.isNameChecked = false;
      this.isValidName = await this.isValidProjectName(name);
      this.isNameChecked = true;
      if (this.isValidName && this.isSuggestedNameSet) {
        this.openProject();
      }
    },
    setInterview(event) {
      this.interviewFileEntry = event.target.files[0];
    },
    async openProject() {
      // Abort if file input is cancelled
      this.loading = true;
      if (this.interviewFileEntry === null || !this.isValidName || !this.isNameChecked) {
        this.loading = false;
        return;
      }

      this.interviewFilePath = this.interviewFileEntry.path;
      const formData = new FormData();
      formData.append('interviewFile', this.interviewFileEntry);
      const promiseImport = this.importInterview({ formData, projectName: this.projectName });

      await promiseImport.then(() => {
        this.loading = false;
        this.$emit('load-audio-file');
        this.$emit('set-interview-file-path', this.interviewFilePath);
      }).catch((error) => {
        this.loading = false;
        this.$notify({ text: error });
      });
      this.$emit('imported');
    },
    openInterviewFileInput() {
      // security restrictions: element can't be clicked from renderer process
      document.querySelector('#interviewFileInput').click();
    },
  },
};
</script>

<style scoped>
.spinner-dialog-box {
  background-color: transparent !important;
  backface-visibility: visible;
}

.field-project-name {
  margin-bottom: 0px;
}
</style>
