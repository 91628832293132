<template>
  <div ref="draggableContainer" id="draggable-container" @mousedown="dragMouseDown">
    <md-card>
      <md-table class="shortcut-table" md-card v-if="showShortcuts">
        <div class="close-button">
          <md-button
            @click="$emit('close-show-shortcut')"
            class="md-icon-button"
          >
            <md-icon>close</md-icon>
          </md-button>
        </div>
        <div class="keyboard-title">
          <h3 class="md-title md-subhead">
            {{ $t("editor.keybordShortcutTitle") }}
          </h3>
        </div>
        <md-table-row>
          <md-table-cell>{{
            $t("editor.wordContextMenu.startStop")
          }}</md-table-cell>
          <md-table-cell>ALT + s</md-table-cell>
        </md-table-row>
        <md-table-row>
          <md-table-cell>{{ $t("editor.wordContextMenu.play") }}</md-table-cell>
          <md-table-cell>ALT + p</md-table-cell>
        </md-table-row>
        <md-table-row>
          <md-table-cell>{{
            $t("editor.wordContextMenu.jumpBack")
          }}</md-table-cell>
          <md-table-cell>ALT + j</md-table-cell>
        </md-table-row>
        <md-table-row>
          <md-table-cell>{{
            $t("editor.wordContextMenu.jumpForth")
          }}</md-table-cell>
          <md-table-cell>ALT + k</md-table-cell>
        </md-table-row>
        <md-table-row>
          <md-table-cell>{{
            $t("editor.segmentContextMenu.playRepeat")
          }}</md-table-cell>
          <md-table-cell>ALT + r</md-table-cell>
        </md-table-row>
        <md-table-row>
          <md-table-cell>{{
            $t("editor.wordContextMenu.toggleCase")
          }}</md-table-cell>
          <md-table-cell>ALT + t</md-table-cell>
        </md-table-row>
        <md-table-row>
          <md-table-cell>{{ $t("editor.wordContextMenu.undo") }}</md-table-cell>
          <md-table-cell>
                CTRL + Z (PC)
                <br />
                CMD + Z (MAC)
          </md-table-cell>
        </md-table-row>
        <md-table-row>
          <md-table-cell>{{ $t("editor.wordContextMenu.redo") }}</md-table-cell>
          <md-table-cell>
                CTRL + Y (PC)
                <br />
                CMD + Y (MAC)
          </md-table-cell>
        </md-table-row>
        <md-table-row>
          <md-table-cell>{{
            $t("editor.wordContextMenu.assignToPreviousSpeaker")
          }}</md-table-cell>
          <md-table-cell>ALT + UP-ARROW</md-table-cell>
        </md-table-row>
        <md-table-row>
          <md-table-cell>{{
            $t("editor.wordContextMenu.assignToNextSpeaker")
          }}</md-table-cell>
          <md-table-cell>ALT + DOWN-ARROW</md-table-cell>
        </md-table-row>
        <md-table-row v-for="speaker in getSpeakers" :key="speaker.id">
          <md-table-cell
            >{{ $t("editor.wordContextMenu.changeTo") }}
            {{ speaker.name }}</md-table-cell
          >
          <md-table-cell>ALT + {{ speaker.id }}</md-table-cell>
        </md-table-row>
      </md-table>
    </md-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: ["showShortcuts"],
  data: function () {
      return {
        positions: {
          clientX: undefined,
          clientY: undefined,
          movementX: 0,
          movementY: 0
        }
      }
    },
  computed: {
    ...mapGetters("transcript", ["getSpeakers"]),
  },
  methods: {
      dragMouseDown: function (event) {
        event.preventDefault()
        // get the mouse cursor position at startup:
        this.positions.clientX = event.clientX
        this.positions.clientY = event.clientY
        document.onmousemove = this.elementDrag
        document.onmouseup = this.closeDragElement
      },
      elementDrag: function (event) {
        event.preventDefault()
        this.positions.movementX = this.positions.clientX - event.clientX
        this.positions.movementY = this.positions.clientY - event.clientY
        this.positions.clientX = event.clientX
        this.positions.clientY = event.clientY
        // set the element's new position:
        this.$refs.draggableContainer.style.top = (this.$refs.draggableContainer.offsetTop - this.positions.movementY) + 'px'
        this.$refs.draggableContainer.style.left = (this.$refs.draggableContainer.offsetLeft - this.positions.movementX) + 'px'
      },
      closeDragElement () {
        document.onmouseup = null
        document.onmousemove = null
      }
    }
};
</script>

<style lang="scss" scoped>
  #draggable-container {
    position: absolute;
    left: calc(98vw - 400px);
    top: calc(98vh - 600px);
    z-index: 9;
  }
.md-card {
  box-shadow: 0px 1px 20px 3px rgba(0, 0, 0, 0.2),
    0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.shortcut-table {
  bottom: 0px;
  right: 0px;
  max-width: 400px;
  min-width: 250px;
  max-height: 500px;
  min-height: 20px;
  border: solid;
  border-width: 1px;
  border-color: $color-sea-breeze;
  overflow: hidden;
}

.shortcut-table ::v-deep .md-content {
  max-width: inherit !important;
}

.shortcut-button {
  position: static !important;
}

.md-table-cell {
  text-align: initial;
}

.md-table-row {
  pointer-events: none;
}

.keyboard-title .md-title {
  letter-spacing: 0.07em;
  line-height: 40px;
}

@media (max-width: 960px) {
  .shortcut-table {
    max-width: 550px;
  }
}
@media (max-width: 600px) {
  .shortcut-table {
    max-width: 340px;
  }
}

@media (max-width: 960px) {
  .shortcut-button {
    position: static !important;
  }
}
</style>
