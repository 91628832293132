import axios from 'axios';
import i18n from '../i18n';
import { TokenService } from '@/services/token.service';
import store from '@/store';

const ApiService = {
  _401interceptor: null,

  init(baseUrl, router) {
    axios.defaults.baseURL = baseUrl;
    axios.defaults.timeout = 500000;
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.defaults.headers.common.Accept = 'application/json';
    this._router = router;
  },

  setHeader() {
    axios.defaults.headers.common.Authorization = `Bearer ${TokenService.getAccessToken()}`;
  },

  removeHeader() {
    delete axios.defaults.headers.common.Authorization;
  },

  get(resource) {
    return axios.get(resource, this.appendHeaders());
  },

  post(resource, data) {
    return axios.post(resource, data, this.appendHeaders());
  },

  custom(reqData) {
    return axios(this.appendHeaders(reqData));
  },

  mount401interceptor() {
    this._401interceptor = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.request.status !== 401) throw error;
        if (error.config.url.includes('/User.refresh_token')) this.handleRefreshFail();
        else return this.refreshAndRetry(error);
      },
    );
  },

  handleRefreshFail() {
    store.dispatch('JWT/logout').then(() => {
      this._router.push('/login');
    });
  },

  refreshAndRetry(error) {
    return store.dispatch('JWT/refreshJWT').then(() => {
      error.response.config.headers.Authorization = `Bearer ${TokenService.getAccessToken()}`;
      return this.custom(error.config);
    });
  },

  unmount401interceptor() {
    axios.interceptors.response.eject(this._401interceptor);
  },

  appendHeaders(options = {}) {
    if (!options.headers) {
      options.headers = {};
    }
    if (!options.headers['Accept-Language']) {
      options.headers['Accept-Language'] = i18n.locale;
    }
    return options;
  },
};

export default ApiService;
