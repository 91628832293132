<template>
  <Page>
    <div
      class="md-layout md-gutter md-alignment-top-space-around load-file-section"
    >
      <div class="md-layout-item md-size-100">
        <form novalidate class="md-layout" @submit.prevent="validateUser">
          <md-card class="md-layout-item md-size-100 md-small-size-100">
            <md-card-header>
              <div class="md-title">{{ user.name }}</div>
            </md-card-header>

            <md-card-content>
              <div class="md-layout md-gutter">
                <div class="md-layout-item md-small-size-100">
                  <md-field :class="getValidationClass('name')">
                    <label for="user-name">Name</label>
                    <md-input
                      name="user-name"
                      id="user-name"
                      v-model="$v.user.name.$model"
                      :disabled="sending"
                    />
                    <span class="md-error" v-if="!$v.user.name.required">{{
                      $t("users.nameRequired")
                    }}</span>
                    <span
                      class="md-error"
                      v-else-if="!$v.user.name.minlength"
                      >{{ $t("users.nameInvalid") }}</span
                    >
                  </md-field>
                  <md-field :class="getValidationClass('id')">
                    <label for="id">ID</label>
                    <md-input name="id" id="id" :value="id" disabled />
                  </md-field>
                  <md-field :class="getValidationClass('created')">
                    <label for="registration-date">Registration date</label>
                    <md-input
                      name="registration-date"
                      id="registration-date"
                      :value="registrationDate"
                      disabled
                    />
                  </md-field>
                </div>
              </div>

              <md-field :class="getValidationClass('email')">
                <label for="email">Email</label>
                <md-input
                  type="email"
                  name="email"
                  id="email"
                  autocomplete="email"
                  v-model="$v.user.email.$model"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.user.email.required">{{
                  $t("users.emailRequired")
                }}</span>
                <span class="md-error" v-else-if="!$v.user.email.email">{{
                  $t("users.emailInvalid")
                }}</span>
              </md-field>

              <md-field :class="getValidationClass('status')">
                <label for="status">Status</label>
                <md-input
                  name="status"
                  id="status"
                  :value="$v.user.status.$model"
                  disabled
                />
              </md-field>

              <md-field :class="getValidationClass('role')">
                <label for="role">Role</label>
                <md-select v-model="$v.user.role.$model" name="role" id="role">
                  <md-option
                    v-for="availableRole in availableRoles"
                    :key="availableRole"
                    :value="availableRole"
                  >
                    {{ availableRole }}
                  </md-option>
                </md-select>
                <span class="md-error" v-if="!$v.user.role.required">{{
                  $t("users.roleRequired")
                }}</span>
              </md-field>

              <md-field :class="getValidationClass('credits')">
                <label for="credits">{{
                  $t("users.userMinutes")
                }}</label>
                <md-input
                  name="credits"
                  id="credits"
                  v-model="$v.user.credits.$model"
                  :disabled="sending"
                />
                <span
                  class="md-error"
                  v-if="!$v.user.credits.required"
                  >{{ $t("users.freeTimeRequired") }}</span
                >
              </md-field>
            </md-card-content>

            <md-card-actions>
              <md-button type="submit" class="md-primary" :disabled="sending">{{
                $t("users.updateUser")
              }}</md-button>
            </md-card-actions>
          </md-card>

          <md-card class="md-layout-item md-size-100 md-small-size-100">
            <md-card-content>
              <ProjectsOverview :userId="parseInt(id)"></ProjectsOverview>
            </md-card-content>
          </md-card>

          <md-card class="md-layout-item md-size-100 md-small-size-100">
            <md-card-content>
              <PaymentsOverview :userId="parseInt(id)"></PaymentsOverview>
            </md-card-content>
          </md-card>

          <md-card class="md-layout-item md-size-100 md-small-size-100">
            <md-card-header>
              <div class="md-title">{{ $t("users.billingDetails") }}</div>
            </md-card-header>
            <md-card-content>
              <BillingTab :userId="parseInt(id)"></BillingTab>
            </md-card-content>
          </md-card>

          <md-card class="md-layout-item md-size-100 md-small-size-100">
            <md-card-header>
              <div class="md-title">{{ $t("users.projectDetails") }}</div>
            </md-card-header>
            <md-card-content>
              <ProjectsTable :userId="parseInt(id)"></ProjectsTable>
            </md-card-content>
          </md-card>

          <md-snackbar :md-active.sync="userSaved"
            >The user was saved with success!</md-snackbar
          >
        </form>
      </div>
    </div>
  </Page>
</template>

<script>
import BillingTab from "../components/BillingTab";
import Page from "../components/Page";
import PaymentsOverview from "../components/PaymentsOverview";
import ProjectsOverview from "../components/ProjectsOverview";
import ProjectsTable from "../components/ProjectsTable";
import { required, email, minLength } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "UserDetail",
  components: {
    BillingTab,
    Page,
    PaymentsOverview,
    ProjectsOverview,
    ProjectsTable,
  },
  data() {
    return {
      userSaved: false,
      sending: false,
      user: {
        name: "",
        id: "",
        email: "",
        password: "",
        password_repeat: "",
        role: "",
        credits: 0,
        created: "",
      },
    };
  },
  computed: {
    ...mapGetters("user", {
      fieldLength: "getFieldLength",
      availableRoles: "getAvailableRoles",
    }),
    id() {
      return this.$route.params.id;
    },
    registrationDate() {
      return new Date(this.user.created).toDateString();
    },
  },
  validations() {
    return {
      user: {
        name: {
          required,
          minLength: minLength(this.fieldLength.name),
        },
        email: {
          required,
          email,
        },
        role: {
          required,
        },
        credits: {
          required,
        },
        status: {
          required,
        },
      },
    };
  },
  mounted() {
    if (!this.id) {
      throw new Error("An id is required");
    }
    this.fetchUser(this.id).then((data) => {
      this.user = data;
    });
    this.fetchAvailableRoles().catch((error) => {
      this.$notify({ text: error });
    });
  },

  methods: {
    ...mapActions("user", ["fetchUser", "updateUser", "fetchAvailableRoles"]),
    getValidationClass(fieldName) {
      const field = this.$v.user[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    saveUser() {
      this.sending = true;
      this.updateUser({ id: this.id, user: this.user })
        .then((newUser) => {
          this.user = newUser;
          this.userSaved = true;
        })
        .catch(({ response }) => {
          this.$notify({
            text: response && response.data && response.data.error,
          });
        })
        .finally(() => {
          this.sending = false;
        });
    },
    validateUser() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.saveUser();
      }
    },
  },
};
</script>

<style scoped>
.md-title {
  text-align: left;
}
.md-layout-item {
  text-align: left;
}
</style>
