<template>
  <div>
    <div v-if="with_qr">
      <div class="md-title md-layout-item title-text">MFA</div>
      <qrcode-vue :value="mfa_uri" :size="200" :level="'H'" />
      <label>{{ mfa_secret }}</label>
    </div>

    <div>
      <span>{{ $t("mfaTab.insertToken") }}</span>
      <md-field>
        <label for="mfa_token">{{ $t("mfaTab.token") }}</label>
        <md-input
          name="mfa_token"
          id="mfa_token"
          :placeholder="'Token'"
          v-model="form.mfa_token"
        />
      </md-field>
    </div>

    <div v-if="with_password">
      <span>{{ $t("mfaTab.mfaPw") }}</span>
      <md-field md-has-password>
        <label for="password">{{ $t("fieldName.password") }}</label>
        <md-input
          name="password"
          id="password"
          :placeholder="$t('fieldName.password')"
          v-model="form.password"
          type="password"
        />
      </md-field>
    </div>

    <md-button class="md-raised md-primary" @click="confirm_clicked()">
      {{ button_text }}
    </md-button>
  </div>
</template>

<script>
import QrcodeVue from "qrcode.vue";

export default {
  name: "SetupMFA",
  props: {
    with_qr: Boolean,
    with_password: Boolean,
    mfa_secret: String,
    mfa_uri: String,
    button_text: String,
  },
  components: {
    QrcodeVue,
  },
  data: () => ({
    form: {
      password: "",
      mfa_token: "",
    },
  }),
  methods: {
    confirm_clicked() {
      this.$emit("confirm_token", this.form);
    },
  },
};
</script>

<style scoped></style>
