<template>
  <div>
    <Page>
      <div class="md-layout md-gutter">
        <div class="md-layout-item md-size-100">
          <md-button
            class="button-back md-icon-button md-primary"
            @click="$router.push('/editor')"
          >
            <md-icon>keyboard_backspace</md-icon>
            <md-tooltip md-direction="top">{{
              $t("editor.tooltip.explorerBack")
            }}</md-tooltip>
          </md-button>
          <h1 class="page-title">{{ $t("transcriptTranslation.title") }}</h1>
        </div>
        <div class="frame-wrapper md-layout-item md-size-50">
          <div class="md-title filter-title">
            {{ $t("transcriptTranslation.original") }}
          </div>
          <div class="search-output">
            <div v-for="(searchFrame, i) in searchResult" :key="i">
              <div class="search-result-wrapper">
                <div
                  class="search-result segment md-layout md-gutter"
                  v-for="(frame, j) in searchFrame"
                  :key="j"
                >
                  <div
                    class="speaker md-layout-item md-size-15 md-small-size-20 md-xsmall-size-25"
                  >
                    <input
                      class="speaker-name"
                      tabindex="-1"
                      v-model="frame.speaker"
                      readonly
                    />
                    <span class="speaker-timestamp"
                      >{{ parseSeconds(frame.start, { showUnit: false }) }} -
                      {{ parseSeconds(frame.end, { showUnit: false }) }}</span
                    >
                  </div>
                </div>
                <hr />
              </div>
            </div>
          </div>
          <div class="md-layout-item search-output">
            <editor-content
              :editor="styledTranscript"
              style="text-align: center"
            />
          </div>
        </div>

        <div class="frame-wrapper summary-wrapper md-layout-item md-size-50">
          <div class="language-selection">
            <md-field>
              <label for="movie">{{
                $t("transcriptTranslation.language")
              }}</label>
              <md-select v-model="movie" name="movie" id="movie">
                <md-option value="german">{{
                  $t("transcriptTranslation.german")
                }}</md-option>
                <md-option value="french">{{
                  $t("transcriptTranslation.french")
                }}</md-option>
                <md-option value="english">{{
                  $t("transcriptTranslation.english")
                }}</md-option>
                <md-option value="italian">{{
                  $t("transcriptTranslation.italian")
                }}</md-option>
                <md-option value="spanish">{{
                  $t("transcriptTranslation.spanish")
                }}</md-option>
              </md-select>
            </md-field>
          </div>
          <div>
            <md-button
              class="button-summary-text md-raised md-primary"
              :disabled="isTranslation"
              @click="getTranslation()"
            >
              {{ $t("transcriptTranslation.generate") }}
            </md-button>
          </div>

          <div class="list-summary-section section">
            <div class="md-title">
              {{ $t("transcriptTranslation.translation") }}
            </div>
            <div class="editor-field-summary">
              <editor-content v-if="editor" :editor="editor" />
            </div>
          </div>
          <div class="editor-section section">
            <div>
              <md-button
                class="button-summary-text md-raised md-primary"
                :disabled="isExporting"
                @click="updateAndExportToWord()"
              >
                {{ $t("transcriptSummary.export") }}
              </md-button>
            </div>
          </div>
        </div>
      </div>
    </Page>
  </div>
</template>

<script>
import Page from "../components/Page";
import { mapActions, mapGetters } from "vuex";
import { Editor, EditorContent, Text } from "tiptap";
import ExportMixin from "../components/ExportMixin";
import { Doc, Phrase, SpeakerFrame, Transcription } from "../editor/schema";

export default {
  name: "ProjectExplorer",
  components: {
    Page,
    EditorContent,
  },
  mixins: [ExportMixin],
  data() {
    return {
      translation: {},
      editor: null,
      isExporting: false,
      isTranslating: false,
      styledTranscript: null,
    };
  },
  computed: {
    ...mapGetters("transcript", ["getTranscriptTranslation", "getTranscript"]),
  },
  methods: {
    ...mapActions("transcript", ["generateTranslation"]),
    async exportToWord() {
      this.isExporting = true;
      try {
        await this.exporttranscriptummaryToWord();
      } catch (e) {
        this.$notify({ text: e });
      }
      this.isExporting = false;
    },
    //TODO
    async getTranslation() {
      this.Translating = true;
    },
  },
  mounted() {
    const that = this;
    const retrievedSummary = this.getTranscriptummary;
    if (retrievedSummary) {
      this.summary = Object.assign(this.summary, retrievedSummary);
      if (
        this.summary &&
        Object.prototype.hasOwnProperty.call(this.summary, "text")
      ) {
        this.textSummary = this.summary.text;
      }
    }
    this.editor = new Editor({
      content: this.textSummary,
      useBuiltInExtensions: true,
      onUpdate() {
        that.textSummary = this.getJSON();
        that.updateSummary();
      },
    });
    this.styledTranscript = new Editor({
      editable: false,
      content: this.getTranscript.document,
      extensions: [
        new Doc(),
        new SpeakerFrame(this),
        new Transcription(),
        new Text(),
        new Phrase(),
      ],
    });
  },
};
</script>

<style lang="scss" scoped>
.page-title {
  display: inline-block;
  margin-left: 40px;
}
.button-back {
  float: left;
  right: 3px;
}
.frame-wrapper {
  text-align: left;
  padding: 10px 20px 0 20px !important;
}
.summary-wrapper {
  border-left: 2px solid #54a1a9;
  margin-bottom: 10px;
}
.search-input-container {
  display: flex;
  padding-bottom: 15px;
  padding-left: 30px;
  align-items: center;
}
.filter-title {
  padding-left: 30px;
}
.search-label {
  padding-right: 10px;
  font-weight: bold;
}
.search-input {
  width: 40%;
  padding: 5px;
}
.search-output {
  display: block;
  max-height: 145vh;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: transparent;
}
.search-result-wrapper {
  width: 100%;
}
.search-result {
  display: flex;
  font-size: 14px;
  padding: 5px;
}
.search-empty {
  display: flex;
  font-size: 16px;
  margin-top: 20px;
  margin-left: 30px;
}
.invalid-keyword {
  color: red;
  margin-left: 30px;
}
.search-spinner ::v-deep .md-progress-spinner-draw {
  width: 25px !important;
  height: 25px !important;
}
.search-button {
  width: 25px !important;
  height: 25px !important;
}
.md-button,
.md-button-clean {
  white-space: normal;
}
.speaker-timestamp {
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  font-size: 10px;
  color: grey;
  opacity: 0.8;
  bottom: 8px;
  max-width: max-content;
  min-width: min-content;
}
.speaker-text {
  display: inline-block;
  border-left: 2px solid black;
  text-align: left;
  padding: 5px 5px 5px 30px !important;
}
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
  z-index: 10;
}
.participants-message {
  font-size: smaller;
}
.participants-warning {
  color: red;
}
.editor-field-summary {
  border: solid black 1px;
  background-color: white;
  padding: 0 5px;
}
.button-summary-text {
  float: right;
}
table {
  width: 100%;
}
.list-summary-input {
  width: 100%;
  padding: 5px;
}
.editor-field-summary {
  min-height: 80px;
  width: 99%;
}
.delete-button {
  width: 50px;
}
.add-button {
  width: 100%;
  padding-right: 65px;
}
.section {
  padding-bottom: 15px;
}
.first-time-data-sharing-options-introduction {
  padding-bottom: 15px;
}
.first-time-data-sharing-options {
  display: flex;
  flex-direction: column;

  > * {
    margin-bottom: 0px;
  }
}
.first-time-data-sharing-options-confirmation {
  padding-top: 30px;

  .md-checkbox {
    margin-bottom: 0px;
  }

  #terms-and-conditions-container {
    display: flex;

    #terms-and-conditions-link {
      margin-top: 16px;
      margin-left: -16px;
    }
  }
}
</style>
