<template>
  <div class="mfa-table">
    <div class="md-layout-item md-layout md-size-100">
      <div class="md-layout-item md-size-30">
        <span>MFA</span>
      </div>

      <div v-if="this.mfa_enabled" class="md-layout-item md-size-60">
        <label>{{ $t("mfaTab.mfaEnabled") }}</label>
      </div>
      <div v-else class="md-layout-item md-size-30">
        <label>{{ $t("mfaTab.mfaDisabled") }}</label>
      </div>
      <div class="md-layout-item md-size-30">
        <md-button type="submit" class="md-icon-button" @click="change_mfa">
          <md-icon>edit</md-icon></md-button
        >
      </div>
    </div>
    <md-content v-if="mfa_setup">
      <SetupMFA
        :with_qr="true"
        :with_password="true"
        :mfa_secret="mfa.secret"
        :mfa_uri="mfa.uri"
        :button_text="$t('mfaTab.confirmMfa')"
        @confirm_token="this.confirm_mfa_token"
      ></SetupMFA>
    </md-content>
    <md-content v-if="mfa_disabling">
      <SetupMFA
        :with_qr="false"
        :with_password="true"
        :mfa_secret="mfa.secret"
        :mfa_uri="mfa.uri"
        :button_text="$t('mfaTab.disableMfa')"
        @confirm_token="this.disable_mfa_token"
      ></SetupMFA>
    </md-content>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import SetupMFA from "../components/SetupMFA";

export default {
  name: "SetupMFATab",
  components: {
    SetupMFA,
  },
  data: () => ({
    mfa_enabled: true,
    mfa_setup: false,
    mfa_disabling: false,
    form: {
      mfa_token: "",
      password: "",
    },
    mfa: {
      secret: "",
      uri: "",
    },
  }),
  computed: {
    ...mapGetters("user", {
      user: "getEditedUserDetails",
    }),
  },
  watch: {},
  mounted() {
    this.mfa_enabled = this.user.mfa_enabled;
  },
  methods: {
    ...mapActions("user", [
      "setupMFA",
      "confirmMFA",
      "disableMFA",
      "fetchUserDetails",
      "updateUserDetails",
      "updateUser",
    ]),
    ...mapMutations("user", ["setUserDetails", "setUserDetailProperty"]),
    change_mfa() {
      if (!this.mfa_enabled) {
        this.setupMFA()
          .then((response) => {
            this.mfa.secret = response.data.mfa_secret;
            this.mfa.uri = response.data.mfa_uri;
            this.mfa_setup = true;
            this.mfa_disabling = false;
          })
          .catch((response) => {
            // TODO: add error handling
          });
      } else {
        this.mfa_setup = false;
        this.mfa_disabling = true;
      }
    },
    confirm_mfa_token(mfa_form) {
      data = {
        password: mfa_form.password,
        mfa_token: mfa_form.mfa_token,
      };
      this.confirmMFA({ formData: data })
        .then((response) => {
          this.mfa_enabled = response.data.data.mfa_enabled;
          this.fetchUserDetails();
          this.mfa_setup = false;
        })
        .catch((response) => {
          // TODO: add error handling
        });
    },
    disable_mfa_token(mfa_form) {
      data = {
        password: mfa_form.password,
        mfa_token: mfa_form.mfa_token,
      };
      this.disableMFA({ formData: data })
        .then((response) => {
          this.mfa_enabled = response.data.data.mfa_enabled;
          this.fetchUserDetails();
          this.mfa_disabling = false;
        })
        .catch((response) => {
          // TODO: add error handling
        });
    },
  },
};
</script>

<style scoped>
.mfa-table {
  max-width: 400px;
}
span {
  font-size: 16px;
  float: left;
  padding-top: 1.5rem;
  padding-left: 0.8rem;
}
label {
  font-size: 16px;
  float: left;
  padding-top: 1.5rem;
}
.md-button {
  margin-top: 1rem;
}
</style>
