<script>
import { mapGetters, mapActions } from "vuex";
import ApiService from "../services/api.service";

export default {
  name: "ExportMixin",
  data() {
    return {};
  },
  computed: {
    ...mapGetters("JWT", ["refresh_token", "access_token"]),
    ...mapGetters("transcript", ["getTranscriptId"]),
  },
  methods: {
    ...mapActions("JWT", ["refreshJWT"]),
    __trigger_download(endpoint, _transcriptId, params = {}) {
      // refresh access token
      this.refreshJWT({})
        .then((result) => {
          const transcriptId = _transcriptId || this.getTranscriptId;
          const accessToken = this.access_token;
          const url = new URL(
            `${process.env.VUE_APP_PROTOCOL}://${process.env.VUE_APP_HOST}:${process.env.VUE_APP_PORT}${endpoint}`
          );
          params.transcript_id = transcriptId;
          params.jwt = accessToken;
          Object.keys(params).forEach(function (key) {
            url.searchParams.append(key, params[key]);
          });
          window.open(url.href);
        })
        .catch((error) => {
          this.$notify({ text: error });
        });
    },
    exportToClipboard() {
      const plainTextQuery = {
        method: "get",
        url: "/Export.plaintext",
        params: {
          transcript_id: this.getTranscriptId,
        },
      };
      ApiService.custom(plainTextQuery)
        .then((response) => {
          navigator.clipboard
            .writeText(response.data.data.text)
            .then((result) => {})
            .catch((error) => {
              this.$notify({ text: error });
            });
        })
        .catch((error) => {
          this.$notify({ text: error });
        });
    },
    exportToWord({ showInformation, showTimestamps }) {
      this.__trigger_download("/Export.docx", null, {
        show_information: showInformation,
        show_timestamps: showTimestamps,
      });
    },
    exportToTxt({ showTimestamps }) {
      this.__trigger_download("/Export.txt", null, {
        show_timestamps: showTimestamps,
      });
    },
    exportToSrt({ showTimestamps }) {
      this.__trigger_download("/Export.srt", null, {
        show_timestamps: showTimestamps,
      });
    },
    exportToCsv() {
      this.__trigger_download("/Export.csv");
    },
    exportToIts(transcriptId) {
      this.__trigger_download("/Export.its", transcriptId);
    },
    exportStudentSummaryToWord() {
      this.__trigger_download("/Export.summary_docx", null);
    },
  },
};
</script>
