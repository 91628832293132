<template>
  <md-dialog
    :md-active.sync="showDialog"
    class="smart-playback-settings-dialog md-primary"
    v-drag
  >
    <md-dialog-title
      >{{ $t("editorSettings.smartPlaybackDialog.title") }}
      <div class="hint-text">
        {{ $t("editorSettings.smartPlaybackDialog.hint") }}
      </div>
    </md-dialog-title>
    <md-dialog-content>
      <div class="md-layout">
        <div class="md-layout-item md-size-100">
          <md-radio
            @change="changeSmartPlaybackOption"
            v-model="smartPlaybackOption"
            value="continuePlayback"
            class="md-primary"
          >
            {{ $t("editorSettings.smartPlaybackDialog.continuePlayback") }}
          </md-radio>
        </div>
        <div class="md-layout-item md-size-100">
          <md-radio
            @change="changeSmartPlaybackOption"
            v-model="smartPlaybackOption"
            value="continueBefore"
            class="md-primary"
          >
            {{ $t("editorSettings.smartPlaybackDialog.continueBefore") }}
          </md-radio>
        </div>
        <div class="md-layout-item md-size-100">
          <md-radio
            @change="changeSmartPlaybackOption"
            v-model="smartPlaybackOption"
            value="continueAfter"
            class="md-primary"
          >
            {{ $t("editorSettings.smartPlaybackDialog.continueAfter") }}
          </md-radio>
        </div>
        <div class="md-layout-item md-size-100">
          <md-radio
            @change="changeSmartPlaybackOption"
            v-model="smartPlaybackOption"
            value="jumpBack"
            class="md-primary"
          >
            {{ $t("editorSettings.smartPlaybackDialog.jumpBack") }}
          </md-radio>
          <div class="counter">
            <md-field>
              <label for="age">{{
                $t("editorSettings.smartPlaybackDialog.jumpBackTime")
              }}</label>
              <md-input
                :value="jumpBackTime"
                v-on:change="changeJumpBackTime($event.target.value)"
                type="number"
                min="0"
              />
            </md-field>
          </div>
        </div>
      </div>
    </md-dialog-content>
    <md-dialog-actions>
      <md-button class="md-primary" @click="close">
        {{ $t("buttons.okey") }}
      </md-button>
    </md-dialog-actions>
  </md-dialog>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "SmartPlaybackDialog",
  props: ["show"],
  data() {
    return {};
  },
  computed: {
    ...mapGetters("transcript", [
      "getSmartPlaybackState",
      "getSmartPlaybackOption",
      "getJumpBackTime",
    ]),
    showDialog: {
      get() {
        return this.show;
      },
      set(newValue) {
        if (!newValue) {
          this.close();
        }
      },
    },
    activateSmartPlayback: {
      get() {
        return this.getSmartPlaybackState;
      },
      set(val) {
        this.setSmartPlaybackState(val);
      },
    },
    smartPlaybackOption: {
      get: function () {
        return this.getSmartPlaybackOption;
      },
      set: function (val) {
        this.setSmartPlaybackOption(val);
      },
    },
    jumpBackTime: {
      get: function () {
        return this.getJumpBackTime;
      },
      set: function (val) {
        this.setJumpBackTime(val);
      },
    },
  },
  methods: {
    ...mapMutations("transcript", [
      "setSmartPlaybackState",
      "setSmartPlaybackOption",
      "setJumpBackTime",
    ]),
    changeSmartPlaybackOption: function (newValue) {
      this.selectedValue = newValue;
      this.setSmartPlaybackOption(newValue);
    },
    changeJumpBackTime: function (newValue) {
      this.selectedValue = newValue;
      this.setJumpBackTime(newValue);
    },
    close() {
      this.$emit("close-window");
    },
  },
};
</script>

<style lang="scss" scoped>
.counter {
  padding-left: 35px;
  max-width: min-content;
}
.smart-playback-settings-dialog {
  max-width: 600px;
  position: absolute;
  left: calc(50% - 245px);
  right: 0;
  top: 60px;
}
.md-dialog ::v-deep.md-dialog-container {
  width: 510px;
  max-width: 100%;
  border: 5px solid #54a1a9;
}
.md-radio {
  margin: 8px 16px 8px 0;
}
.hint-text {
  font-size: 10px;
  color: grey;
  line-height: 1.5em;
}
.md-dialog-title {
  margin-bottom: 12px;
}
.radio-container {
  margin-left: 16px;
}
</style>
