
const TokenService = {
  // access token
  getAccessToken() {
    return localStorage.getItem('access_token');
  },
  saveAccessToken(accessToken) {
    localStorage.setItem('access_token', accessToken);
  },
  removeAccessToken() {
    localStorage.removeItem('access_token');
  },

  // refresh token
  getRefreshToken() {
    return localStorage.getItem('refresh_token');
  },
  saveRefreshToken(refreshToken) {
    localStorage.setItem('refresh_token', refreshToken);
  },
  removeRefreshToken() {
    localStorage.removeItem('refresh_token');
  },

  // admin
  isAdmin() {
    return localStorage.getItem('is_admin');
  },
  setAdmin(userIsAdmin) {
    localStorage.setItem('is_admin', userIsAdmin);
  },
  removeIsAdmin() {
    localStorage.removeItem('is_admin');
  },

  // poweruser
  isPoweruser() {
    return localStorage.getItem('is_poweruser');
  },
  setIsPoweruser(userIsPoweruser) {
    localStorage.setItem('is_poweruser', userIsPoweruser);
  },
  removeIsPoweruser() {
    localStorage.removeItem('is_poweruser');
  },
};

export { TokenService };
