<template>
  <div class="container">
    <LoadInterview
      ref="loadinterview"
      v-on:imported="emitImported()"
    ></LoadInterview>
  </div>
</template>

<script>
import LoadInterview from './LoadInterview';

export default {
  name: 'ProjectFromITS',
  components: {
    LoadInterview,
  },
  data: () => ({

  }),
  methods: {
    emitImported() {
      this.$emit('imported');
    },
  },
};
</script>

<style scoped>
  .container {
    padding: 2em;
  }
</style>
