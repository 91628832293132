function _parseSeconds(rawSeconds, precision, showUnit) {
  if (rawSeconds === '') {
    return rawSeconds;
  }
  let seconds = (Number(rawSeconds).toFixed(precision));
  const minutes = Math.floor(seconds / 60);
  let text, unit;
  if (minutes < 1) {
    text = `${seconds}`;
    unit = 's';
  } else {
    // if the precision is 0 then there is not dot
    const expectedLength = 2 + precision + (precision === 0 ? 0 : 1);
    seconds = (seconds - minutes * 60).toFixed(precision).padStart(expectedLength, '0');
    text = `${minutes}:${seconds}`;
    unit = 'min';
  }
  return showUnit ? `${text} ${unit}` : `${text}`;
}

function handleUndefined(value, defaultValue) {
  return value === undefined ? defaultValue : value;
}

export function parseSeconds(rawSeconds, { precision, showUnit } = {}) {
  return _parseSeconds(rawSeconds, handleUndefined(precision, 1), handleUndefined(showUnit, true));
}

export function formatDurationInMinutes(duration) {
  let minutes, seconds;
  if (duration || duration === 0) {
    minutes = Math.floor(duration / 60);
    seconds = Math.round(duration % 60).toString().padStart(2, '0');
  } else {
    minutes = seconds = '--';
  }
  if (seconds === '60') {
    minutes++;
    seconds = '00';
  }
  return `${minutes}:${seconds}`;
}

export function formatDate(value) {
  const locale = window.navigator.userLanguage || window.navigator.language;
  return value.toLocaleDateString(locale, { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' });
}

export function formatDateString(value) {
  if (value) {
    return formatDate(new Date(value));
  }
}

export function formatDateUnix(value) {
  if (value) {
    return formatDate(new Date(value));
  }
}
