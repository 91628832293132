<template>
  <div class="save-interview">
      <md-button class="md-icon-button md-raised md-primary" md-menu-trigger
                 v-on:click="saveProject()">
        <md-icon>cloud_download</md-icon>
        <md-tooltip md-direction="top">{{ $t('editor.export.download') }}</md-tooltip>
      </md-button>

  </div>
</template>

<script>
import ExportMixin from './ExportMixin';

export default {
  name: 'SaveInterview',
  props: ['interviewFilePath', 'interview', 'audioFile'],
  mixins: [ExportMixin],
  watch: {
    interview() {
      this.localInterview = this.interview;
    },
    interviewFilePath(p) {
      this.save.path = p;
    },
  },
  data() {
    return {
      save: {
        path: '',
        disabled: true,
      },
    };
  },
  methods: {
    saveProject() {
      this.exportToIts();
    },
    saveProjectAs() {

    },
  },
};
</script>

<style scoped>
  .save-interview {
    text-align: center;
  }

  .list-item {
    font-size: 14px;
  }

  @media (max-width: 600px) {
    .md-icon-button {
      min-width: 35px !important;
      width: 35px !important;
      height: 35px !important;
    }
  }
</style>
