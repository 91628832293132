import { Plugin, TextSelection } from 'prosemirror-state';
import { Decoration, DecorationSet } from 'prosemirror-view';
import { Slice, Fragment } from 'prosemirror-model';
import { Extension } from 'tiptap';
import { parseSeconds } from '../utils/time';
import { AudioBus } from '../audioBus';
import store from '../store';

const currentWordPlugin = new Plugin({
  calculateDecoration(doc, currentTime) {
    const result = [];
    doc.descendants(function(node, pos) {
      if (node.type.name === 'phrase' && node.attrs.startTime < currentTime && node.attrs.endTime > currentTime) {
        const startPos = pos;
        const endPos = pos + node.nodeSize;
        result.push(Decoration.inline(startPos, endPos, { class: 'playback-highlight' }));
        return false;
      }
      return true;
    });
    return DecorationSet.create(doc, result);
  },
  state: {
    init(_, { doc }) {
      return currentWordPlugin.spec.calculateDecoration(doc, 0);
    },
    apply(tr, value) {
      if (tr.getMeta('audio')) return currentWordPlugin.spec.calculateDecoration(tr.doc, tr.getMeta('audio'));
      return value;
    },
  },
  props: {
    decorations(state) {
      return currentWordPlugin.getState(state);
    },
  },
});

const emptyFramePlugin = new Plugin({
  removeEmptyFrames(tr) {
    let addedTransaction = false;
    while (true) {
      let frame;
      tr.doc.descendants(function(node, pos) {
        if (node.type.name === 'speaker_frame') {
          if (node.textContent === '' || node.textContent === ' ') {
            frame = { from: pos, to: pos + node.nodeSize };
          }
          return false;
        }
        return true;
      });
      if (frame === undefined) { break; }
      tr.delete(frame.from, frame.to);
      addedTransaction = true;
    }
    return addedTransaction;
  },
  appendTransaction(tr, oldState, newState) {
    const newTr = newState.tr;
    if (oldState.doc !== newState.doc && this.spec.removeEmptyFrames(newTr)) {
      return newTr;
    }
  },
});

const emptyPhrasePlugin = new Plugin({
  removeEmptyPhrases(tr) {
    let addedTransaction = false;
    while (true) {
      let frame;
      tr.doc.descendants(function(node, pos) {
        if (frame !== undefined) {
          return false;
        }
        if (node.type.name === 'phrase' && node.attrs.type === 'WORD') {
          if (node.textContent === '') {
            frame = { from: pos, to: pos + node.nodeSize };
          }
          return false;
        }
        return true;
      });
      if (frame === undefined) { break; }
      tr.delete(frame.from, frame.to);
      addedTransaction = true;
    }
    return addedTransaction;
  },
  appendTransaction(tr, oldState, newState) {
    const newTr = newState.tr;
    if (oldState.doc !== newState.doc && this.spec.removeEmptyPhrases(newTr)) {
      return newTr;
    }
  },
});

const phraseBreakerPlugin = new Plugin({
  breakPhrases(tr) {
    let addedTransaction = false;
    while (true) {
      let splitPos;
      tr.doc.descendants(function(node, pos) {
        if (splitPos !== undefined) {
          return false;
        }
        if (node.type.name === 'phrase' && node.attrs.type === 'WORD') {
          if (node.textContent.trim().split(' ').length !== 1) {
            splitPos = pos + 1 + node.textContent.indexOf(' ') + 1;
          }
          return false;
        }
        return true;
      });
      if (splitPos === undefined) {
        break;
      }
      tr.split(splitPos);
      addedTransaction = true;
    }
    return addedTransaction;
  },
  appendTransaction(tr, oldState, newState) {
    const newTr = newState.tr;
    if (oldState.doc !== newState.doc && this.spec.breakPhrases(newTr)) {
      return newTr;
    }
  },
});

const sameSpeakerPlugin = new Plugin({
  joinFramesWithSameSpeakers(tr) {
    let addedTransaction = false;
    while (true) {
      const frames = [];
      tr.doc.descendants(function(node, pos) {
        if (node.type.name === 'speaker_frame') {
          frames.push({
            speaker: node.attrs.speakerId,
            from: pos,
            to: pos + node.nodeSize,
          });
          return false;
        }
        return true;
      });
      let lastFrame;
      let joinPos;
      for (const frame of frames) {
        if (lastFrame !== undefined && lastFrame.speaker === frame.speaker) {
          joinPos = frame.from;
        }
        lastFrame = frame;
      }
      if (joinPos === undefined) { break; }
      tr.join(joinPos);
      if (!tr.doc.textBetween(joinPos - 3, joinPos).includes(' ')) {
        tr.insertText(' ', joinPos);
      }
      addedTransaction = true;
    }
    return addedTransaction;
  },
  appendTransaction(tr, oldState, newState) {
    const newTr = newState.tr;
    if (oldState.doc !== newState.doc && this.spec.joinFramesWithSameSpeakers(newTr)) {
      return newTr;
    }
  },
});

const disableMultiFrame = new Plugin({
  appendTransaction(tr, oldState, newState) {
    const newTr = newState.tr;
    if (!oldState.selection.eq(newState.selection)) {
      const frames = [];
      const from = newState.selection.from;
      const to = newState.selection.to;
      newState.doc.nodesBetween(from, to,
        function(node, pos) {
          if (node.type.name === 'speaker_frame') {
            frames.push({ start: pos, end: pos + node.nodeSize });
            return false;
          }
          return true;
        });
      if (frames.length > 1) {
        const anchor = newState.selection.anchor;
        const head = newState.selection.head;
        for (const { start, end } of frames) {
          if (start <= anchor && anchor <= end) {
            const selection = TextSelection.create(newTr.doc, anchor, Math.max(start + 2, Math.min(head, end - 2)));
            newTr.setSelection(selection);
            return newTr;
          }
        }
      }
    }
  },
});

const gapEditorPlugin = new Plugin({
  props: {
    handleDOMEvents: {
      dblclick(view, event) {
        const target = event.target;
        const resolvedPos = view.state.selection.$to;
        const pauseNode = view.state.doc.nodeAt(resolvedPos.pos - 1);
        const transcript = view.state.doc.child(0);
        const speakerFrameIndex = view.state.selection.$from.index(1);
        const speakerId = transcript.child(speakerFrameIndex).attrs.speakerId;

        const utteranceText = [];
        let pauseIndex = null;
        let i = 0;
        transcript.child(speakerFrameIndex).descendants(function(node) {
          if (node.type.name === 'phrase') {
            if (node.attrs.startTime === pauseNode.attrs.startTime) {
              pauseIndex = i;
              utteranceText.push('[GAP: ' + (node.attrs.endTime - node.attrs.startTime).toFixed(2) + 'sec] ');
              i++;
            } else if (node.textContent.trim().length) {
              utteranceText.push(node.textContent);
              i++;
            }
          }
        });

        const gapSubtext = '...' + utteranceText.slice(
          Math.max(0, pauseIndex - 3), Math.min(utteranceText.length, pauseIndex + 4),
        ).join('').trim() + '...';

        const result = {
          target: event.target,
          speakerId: speakerId,
          pos: resolvedPos.pos - 1,
          node: pauseNode,
          gapSubtext: gapSubtext,
        };

        const pmEditor = document.getElementById('pmeditor-container');
        if (target.getAttribute('type') === 'NO SPEECH' ||
          target.getAttribute('type') === 'PAUSE' ||
          target.getAttribute('type') === 'PARALLEL SPEECH') {
          pmEditor.vueRefs.openGapEditor(result);
        }
      },
    },
  },
});

const tooltipPlugin = new Plugin({
  props: {
    handleDOMEvents: {
      mouseover(view, event) {
        const target = event.target;
        if (target === null) {
          return;
        }
        const pos = view.posAtDOM(target);
        const node = view.state.doc.nodeAt(pos);
        const tooltip = document.getElementById('phrase-tooltip');
        if (node.type.name !== 'text' || !view.state.selection.empty) {
          tooltip.style.display = 'none';
          return;
        }
        const resolved = view.state.doc.resolve(pos);
        const { startTime, endTime, confidence } = resolved.parent.attrs;
        const rect = event.target.getBoundingClientRect();
        tooltip.style.left = (rect.x + rect.width / 2) + 'px';
        tooltip.style.top = rect.y + 'px';
        tooltip.innerHTML = `Timestamp: ${parseSeconds(startTime)} - ${parseSeconds(endTime)} <br/> Confidence: ${Number(confidence).toFixed(2)}`;
        tooltip.style.display = '';
      },
      keydown(view, event) {
        const tooltip = document.getElementById('phrase-tooltip');
        tooltip.style.display = 'none';
      },
      dblclick() {
        const tooltip = document.getElementById('phrase-tooltip');
        tooltip.style.display = 'none';
      },
      input(view, event) {
        function debounce(cb, wait) {
          let timeout;
          return (...args) => {
            clearTimeout(timeout);
            timeout = setTimeout(function() { cb.apply(this, args); }, wait);
          };
        }
        if (store.getters['transcript/getAudioState'] === true && store.getters['transcript/getSmartPlaybackState'] === true) {
          AudioBus.$emit('stop');
          const pmEditor = document.getElementById('pmeditor-container');
          if (store.getters['transcript/getSmartPlaybackOption'] === 'continuePlayback') {
            pmEditor.addEventListener('keyup', debounce(() => {
              AudioBus.$emit('resumeAtEditPosition', 0);
            }, 1000));
          }
          if (store.getters['transcript/getSmartPlaybackOption'] === 'continueBefore') {
            pmEditor.addEventListener('keyup', debounce(() => {
              if (typeof view.state.selection.$to !== 'undefined') {
                const resolvedPos = view.state.selection.$to;
                const resolved = view.state.doc.resolve(resolvedPos.pos);
                AudioBus.$emit('resumeBeforeAfterEditPosition', resolved.parent.attrs.startTime);
              }
            }, 1000));
          }
          if (store.getters['transcript/getSmartPlaybackOption'] === 'continueAfter') {
            pmEditor.addEventListener('keyup', debounce(() => {
              if (typeof view.state.selection.$to !== 'undefined') {
                const resolvedPos = view.state.selection.$to;
                const resolved = view.state.doc.resolve(resolvedPos.pos);
                AudioBus.$emit('resumeBeforeAfterEditPosition', resolved.parent.attrs.endTime);
              }
            }, 1000));
          }
          if (store.getters['transcript/getSmartPlaybackOption'] === 'jumpBack') {
            pmEditor.addEventListener('keyup', debounce(() => {
              AudioBus.$emit('resumeAtEditPosition', store.getters['transcript/getJumpBackTime']);
            }, 1000));
          }
        }
      },
    },
  },
});

const pastePlugin = new Plugin({
  props: {
    transformPasted(slice) {
      const fragment = slice.content;
      let rawText = '';
      let schema = false;
      fragment.descendants(function(node) {
        if (node.isLeaf) {
          rawText = rawText + node.textContent;
        }
        schema = node.type.schema;
      });
      if (schema) {
        return new Slice(Fragment.from(schema.text(rawText)), 0, 0);
      }
      return slice;
    },
  },
});

const adaptConfidenceForManuallyEditedPhrasesPlugin = new Plugin({
  appendTransaction(tr, oldState, newState) {
    // if (oldState.doc !== newState.doc) {
    //   const oldNodesWithConfidence = {};
    //   const newNodesWithConfidence = {};
    //   oldState.doc.descendants(node => {
    //     if (node.attrs.confidence && node.attrs.startTime && node.attrs.endTime) {
    //       oldNodesWithConfidence[`${node.attrs.startTime}-${node.attrs.endTime}`] = node;
    //     }
    //   });
    //   newState.doc.descendants(node => {
    //     if (node.attrs.confidence && node.attrs.startTime && node.attrs.endTime) {
    //       newNodesWithConfidence[`${node.attrs.startTime}-${node.attrs.endTime}`] = node;
    //     }
    //   });
    //   for (const [timespan, node] of Object.entries(newNodesWithConfidence)) {
    //     if (oldNodesWithConfidence[timespan] === undefined) {
    //       node.attrs.confidence = 1.0;
    //     } else if (oldNodesWithConfidence[timespan] !== node) {
    //       node.attrs.confidence = 1.0;
    //     }
    //   }
    // }
    return newState.tr;
  },
});

export class PluginInjector extends Extension {
  get name() {
    return 'injector';
  }

  get plugins() {
    return [currentWordPlugin, emptyFramePlugin, sameSpeakerPlugin, emptyPhrasePlugin,
      phraseBreakerPlugin, disableMultiFrame, tooltipPlugin, pastePlugin,
      adaptConfidenceForManuallyEditedPhrasesPlugin, gapEditorPlugin];
  }
}
