<template>
  <div>
    <Page>
      <div class="md-layout md-gutter">
        <div class="md-layout-item md-size-100">
          <md-button
            class="button-back md-icon-button md-primary"
            @click="$router.push('/editor')"
          >
            <md-icon>keyboard_backspace</md-icon>
            <md-tooltip md-direction="top">{{
              $t("editor.tooltip.explorerBack")
            }}</md-tooltip>
          </md-button>
          <h1 class="page-title">{{ $t("transcriptSummary.title") }}</h1>
        </div>
        <div class="frame-wrapper md-layout-item md-size-50">
          <div class="md-title filter-title">
            {{ $t("transcriptSummary.filter") }}
          </div>
          <div class="search-input-container">
            <div class="search-label">
              {{ $t("transcriptSummary.keyword") }}
            </div>
            <input
              class="search-input"
              :disabled="searchInProgress"
              @input="searchValue = $event.target.value"
              @keyup.enter="onSearch()"
            />
            <md-button
              v-if="!searchInProgress"
              class="md-icon-button md-primary search-button"
              @click="onSearch()"
            >
              <md-icon>search</md-icon>
            </md-button>
            <md-progress-spinner
              v-else
              :md-diameter="30"
              :md-stroke="3"
              md-mode="indeterminate"
            ></md-progress-spinner>
          </div>

          <div
            v-if="isValidKeyword && searchFinished && searchResult.length"
            class="search-output"
          >
            <div v-for="(searchFrame, i) in searchResult" :key="i">
              <div class="search-result-wrapper">
                <div
                  class="search-result segment md-layout md-gutter"
                  v-for="(frame, j) in searchFrame"
                  :key="j"
                >
                  <div
                    class="speaker md-layout-item md-size-15 md-small-size-20 md-xsmall-size-25"
                  >
                    <input
                      class="speaker-name"
                      tabindex="-1"
                      v-model="frame.speaker"
                      readonly
                    />
                    <span class="speaker-timestamp"
                      >{{ parseSeconds(frame.start, { showUnit: false }) }} -
                      {{ parseSeconds(frame.end, { showUnit: false }) }}</span
                    >
                  </div>
                  <span
                    v-if="frame.trimmed_left && frame.trimmed_right"
                    class="speaker-text md-layout-item md-size-85 md-small-size-80 md-xsmall-size-75"
                    v-html="'... ' + frame.html + ' ...'"
                  ></span>
                  <span
                    v-if="frame.trimmed_left && !frame.trimmed_right"
                    class="speaker-text md-layout-item md-size-85 md-small-size-80 md-xsmall-size-75"
                    v-html="'... ' + frame.html"
                  ></span>
                  <span
                    v-if="!frame.trimmed_left && frame.trimmed_right"
                    class="speaker-text md-layout-item md-size-85 md-small-size-80 md-xsmall-size-75"
                    v-html="frame.html + ' ...'"
                  ></span>
                  <span
                    v-if="!frame.trimmed_left && !frame.trimmed_right"
                    class="speaker-text md-layout-item md-size-85 md-small-size-80 md-xsmall-size-75"
                    v-html="frame.html"
                  ></span>
                </div>
                <hr />
              </div>
            </div>
          </div>

          <div
            v-if="isValidKeyword && searchFinished && !searchResult.length"
            class="md-layout-item search-empty"
          >
            {{ $t("transcriptSummary.noResults") }}
          </div>

          <div
            v-if="
              isValidKeyword &&
              !searchFinished &&
              !searchResult.length &&
              !searchInProgress
            "
            class="md-layout-item search-output"
          >
            <editor-content
              :editor="styledTranscript"
              style="text-align: center"
            />
          </div>
          <div v-if="!isValidKeyword" class="md-layout-item invalid-keyword">
            {{ $t("transcriptSummary.invalidKeyword") }}
          </div>
        </div>

        <div class="frame-wrapper summary-wrapper md-layout-item md-size-50">
          <div class="list-summary-section section">
            <div class="md-title">{{ $t("transcriptSummary.textTitle") }}</div>
            <div class="editor-field-summary">
              <editor-content v-if="editor" :editor="editor" />
            </div>
            <div>
              <md-field>{{ textSummary }}</md-field>
            </div>
            <div>
              <md-button
                class="button-summary-text md-raised md-primary"
                :disabled="isGenerating"
                @click="getSummary()"
              >
                Auto generate
              </md-button>
            </div>
          </div>
          <div class="list-summary-section section">
            <div class="md-title">{{ $t("transcriptSummary.listTitle") }}</div>
            <table>
              <tr v-for="(topic, i) in listSummary" :key="i">
                <td>
                  <input
                    class="list-summary-input"
                    type="text"
                    :value="topic"
                    @input="
                      updateListSummary(i, $event.target.value);
                      updateSummary();
                    "
                  />
                </td>
                <td class="delete-button">
                  <md-button
                    class="button-summary-text md-icon-button md-primary"
                    @click="listSummary.splice(i, 1)"
                  >
                    <md-icon>delete</md-icon>
                  </md-button>
                </td>
              </tr>
            </table>
            <md-button
              class="add-button md-primary"
              @click="listSummary.push('')"
            >
              <md-icon>add</md-icon>
            </md-button>
          </div>
          <div class="decisions-section section">
            <div class="md-title">
              {{ $t("transcriptSummary.decisionsTitle") }}
            </div>
            <table>
              <tr v-for="(topic, i) in decisions" :key="i">
                <td>
                  <input
                    class="list-summary-input"
                    type="text"
                    :value="topic"
                    @input="
                      updateDecisions(i, $event.target.value);
                      updateSummary();
                    "
                  />
                </td>
                <td class="delete-button">
                  <md-button
                    class="button-summary-text md-icon-button md-primary"
                    @click="decisions.splice(i, 1)"
                  >
                    <md-icon>delete</md-icon>
                  </md-button>
                </td>
              </tr>
            </table>
            <md-button
              class="add-button md-primary"
              @click="decisions.push('')"
            >
              <md-icon>add</md-icon>
            </md-button>
          </div>
          <div class="todos-section section">
            <div class="md-title">
              {{ $t("transcriptSummary.todos.title") }}
            </div>
            <table>
              <tr>
                <th>{{ $t("transcriptSummary.todos.who") }}</th>
                <th>{{ $t("transcriptSummary.todos.what") }}</th>
                <th>{{ $t("transcriptSummary.todos.until") }}</th>
                <th class="delete-button"></th>
              </tr>
              <tr v-for="(todo, i) in todos" :key="i">
                <td>
                  <input
                    class="list-summary-input"
                    type="text"
                    :value="todo.who"
                    @input="
                      updateTodos(i, $event.target.value, 'who');
                      updateSummary();
                    "
                  />
                </td>
                <td>
                  <input
                    class="list-summary-input"
                    type="text"
                    :value="todo.what"
                    @input="
                      updateTodos(i, $event.target.value, 'what');
                      updateSummary();
                    "
                  />
                </td>
                <td>
                  <input
                    class="list-summary-input"
                    type="text"
                    :value="todo.until"
                    @input="
                      updateTodos(i, $event.target.value, 'until');
                      updateSummary();
                    "
                  />
                </td>

                <td class="delete-button">
                  <md-button
                    class="button-summary-text md-icon-button md-primary"
                    @click="todos.splice(i, 1)"
                  >
                    <md-icon>delete</md-icon>
                  </md-button>
                </td>
              </tr>
              <tr>
                <td>
                  <!-- here to make sure the buttons are in the same place as above and below -->
                </td>
                <td>
                  <!-- here to make sure the buttons are in the same place as above and below -->
                </td>
                <td>
                  <!-- here to make sure the buttons are in the same place as above and below -->
                </td>
                <td class="delete-button">
                  <!-- here to make sure the buttons are in the same place as above and below -->
                </td>
              </tr>
            </table>
            <md-button
              class="add-button md-primary"
              @click="todos.push({ who: '', what: '', until: '' })"
            >
              <md-icon>add</md-icon>
            </md-button>
          </div>
          <!-- <div class="section">
            <div class="md-title">
              {{ $t("transcriptSummary.participantsTitle") }}
            </div>
            <div class="participants-message participants-warning">
              {{ $t("transcriptSummary.participantsWarning") }}
            </div>
            <div class="participants-message">
              {{ $t("transcriptSummary.participantsMessage") }}
            </div>
            <table>
              <tr>
                <th>{{ $t("transcriptSummary.participants.name") }}</th>
                <th>{{ $t("transcriptSummary.participants.email") }}</th>
                <th class="delete-button"></th>
              </tr>
              <tr v-for="(participant, i) in participants" :key="i">
                <td>
                  <input
                    class="list-summary-input"
                    type="text"
                    :value="participant.name"
                    @input="
                      updateParticipants(i, $event.target.value, 'name');
                      updateSummary();
                    "
                  />
                </td>
                <td>
                  <input
                    class="list-summary-input"
                    type="text"
                    :value="participant.email"
                    @input="
                      updateParticipants(i, $event.target.value, 'email');
                      updateSummary();
                    "
                  />
                </td>
                <td class="delete-button">
                  <md-button
                    class="button-summary-text md-icon-button md-primary"
                    @click="participants.splice(i, 1)"
                  >
                    <md-icon>delete</md-icon>
                  </md-button>
                </td>
              </tr>
            </table>
            <md-button
              class="add-button md-primary"
              @click="participants.push({ name: '', email: '' })"
            >
              <md-icon>add</md-icon>
            </md-button>
          </div> -->

          <div class="editor-section section">
            <div>
              <md-button
                class="button-summary-text md-raised md-primary"
                :disabled="isExporting"
                @click="updateAndExportToWord()"
              >
                {{ $t("transcriptSummary.export") }}
              </md-button>
            </div>
          </div>
        </div>
      </div>
    </Page>
    <!-- <md-dialog
      :md-active.sync="firstTimeOpening"
      :md-close-on-esc="false"
      :md-click-outside-to-close="false"
    >
      <md-dialog-title>{{
        $t("transcriptSummary.firstTime.title")
      }}</md-dialog-title>
      <md-dialog-content>
        <div class="first-time-data-sharing-options-introduction">
          {{ $t("transcriptSummary.firstTime.introduction") }}
        </div>
        <div class="first-time-data-sharing-options-question">
          {{ $t("transcriptSummary.firstTime.question") }}
        </div>
        <div class="first-time-data-sharing-options">
          <md-radio v-model="dataSharingOption" value="allowEverything">{{
            $t("transcriptSummary.firstTime.allowEverything")
          }}</md-radio>
          <md-radio
            v-model="dataSharingOption"
            value="allowTranscriptAndSummary"
            >{{
              $t("transcriptSummary.firstTime.allowTranscriptAndSummary")
            }}</md-radio
          >
          <md-radio v-model="dataSharingOption" value="allowNothing">{{
            $t("transcriptSummary.firstTime.allowNothing")
          }}</md-radio>
        </div>
        <div class="first-time-data-sharing-options-confirmation">
          <div>{{ $t("transcriptSummary.firstTime.confirmation") }}</div>
          <md-checkbox
            v-model="dataSharingOptionConfirmations"
            value="data"
            :disabled="dataSharingOptionConfirmationRequired"
            >{{
              $t("transcriptSummary.firstTime.confirmAllowTranscriptAndSummary")
            }}</md-checkbox
          >
          <div id="terms-and-conditions-container">
            <md-checkbox
              v-model="dataSharingOptionConfirmations"
              value="termsAndConditions"
              :disabled="dataSharingOptionConfirmationRequired"
              >{{
                $t("transcriptSummary.firstTime.confirmTermsAndConditions")
              }}</md-checkbox
            >
            <div id="terms-and-conditions-link">
              <a target="_blank" href="/terms-of-use-summarization"
                >&nbsp;Terms & Conditions</a
              >
              .
            </div>
          </div>
        </div>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button
          class="md-primary"
          @click="cancelDataSharingOptionSelection()"
          >{{ $t("transcriptSummary.firstTime.cancel") }}</md-button
        >
        <md-button
          class="md-primary"
          @click="confirmDataSharingOptionSelection()"
          :disabled="isConfirmButtonDisabled"
          >{{ $t("transcriptSummary.firstTime.confirm") }}</md-button
        >
      </md-dialog-actions>
    </md-dialog> -->
  </div>
</template>

<script>
import Page from "../components/Page";
import { mapActions, mapGetters } from "vuex";
import { Editor, EditorContent, Text } from "tiptap";
import ExportMixin from "../components/ExportMixin";
import { Doc, Phrase, SpeakerFrame, Transcription } from "../editor/schema";

export default {
  name: "ProjectExplorer",
  components: {
    Page,
    EditorContent,
  },
  mixins: [ExportMixin],
  data() {
    return {
      summary: {},
      listSummary: ["", "", ""],
      participants: [
        { name: "", email: "" },
        { name: "", email: "" },
      ],
      decisions: ["", "", ""],
      todos: [
        { who: "", until: "", what: "" },
        { who: "", until: "", what: "" },
        { who: "", until: "", what: "" },
      ],
      searchValue: "",
      searchResult: [],
      searchFinished: false,
      searchInProgress: false,
      textSummary: "",
      editor: null,
      isExporting: false,
      isGenerating: false,
      isValidKeyword: true,
      firstTimeOpening: false,
      dataSharingOption: "",
      dataSharingOptionConfirmations: [],
      styledTranscript: null,
    };
  },
  computed: {
    ...mapGetters("transcript", ["getTranscriptSummary", "getTranscript"]),
    // dataSharingOptionConfirmationRequired: function () {
    //   return (
    //     this.dataSharingOption === "" ||
    //     this.dataSharingOption === null ||
    //     this.dataSharingOption === "allowNothing"
    //   );
    // },
    // isConfirmButtonDisabled: function () {
    //   if (this.dataSharingOption === "allowNothing") {
    //     return false;
    //   } else if (
    //     this.dataSharingOption === "allowTranscriptAndSummary" ||
    //     this.dataSharingOption === "allowEverything"
    //   ) {
    //     return !(
    //       this.dataSharingOptionConfirmations.length === 2 &&
    //       this.dataSharingOptionConfirmations.includes("data") &&
    //       this.dataSharingOptionConfirmations.includes("termsAndConditions")
    //     );
    //   } else {
    //     return true;
    //   }
    // },
  },
  watch: {
    // dataSharingOption: function (newOption, oldOption) {
    //   if (newOption !== oldOption) {
    //     this.dataSharingOptionConfirmations = [];
    //   }
    // },
  },
  methods: {
    ...mapActions("transcript", [
      "searchInTranscript",
      "updateTranscriptSummary",
      "generateSummary",
    ]),
    async onSearch() {
      this.isValidKeyword = this.searchValue.trim().indexOf(" ") === -1;

      if (this.isValidKeyword && this.searchValue.length > 0) {
        this.speakerStatistics = {};
        this.searchFinished = false;
        this.searchResult = [];
        this.searchInProgress = true;
        const result = await this.searchInTranscript(this.searchValue);
        this.searchInProgress = false;
        this.searchFinished = true;
        for (const item of result.data) {
          this.searchResult.push(item.frames);
        }
      } else if (this.searchValue.length === 0) {
        this.searchFinished = false;
        this.searchInProgress = false;
        this.searchResult = [];
      }
    },
    async updateSummary() {
      this.summary.text = this.textSummary;
      this.summary.participants = this.participants;
      this.summary.bulletPoints = this.listSummary;
      this.summary.decisions = this.decisions;
      this.summary.todos = this.todos;
      this.summary.dataSharingOption = this.dataSharingOption;
      return this.updateTranscriptSummary(this.summary);
    },
    async updateAndExportToWord() {
      this.isExporting = true;
      try {
        await this.updateSummary();
        await this.exporttranscriptummaryToWord();
      } catch (e) {
        this.$notify({ text: e });
      }
      this.isExporting = false;
    },
    //TODO
    async getSummary() {
      this.isGenerating = true;
      try {
        this.textSummary = await this.generateSummary(this.getTranscript);
        updateSummary();
      } catch (e) {
        this.$notify({ text: e });
      }
      this.isGenerating = false;
    },
    updateParticipants(index, value, property) {
      this.participants[index] = {
        ...this.participants[index],
        [property]: value,
      };
    },
    updateDecisions(index, value) {
      this.decisions[index] = value;
    },
    updateListSummary(index, value) {
      this.listSummary[index] = value;
    },
    updateTodos(index, value, property) {
      this.todos[index] = { ...this.todos[index], [property]: value };
    },
    confirmDataSharingOptionSelection() {
      this.updateSummary();
      this.firstTimeOpening = false;
    },
    cancelDataSharingOptionSelection() {
      this.$router.go(-1);
    },
  },
  mounted() {
    const that = this;
    const retrievedSummary = this.getTranscriptummary;
    if (retrievedSummary) {
      this.summary = Object.assign(this.summary, retrievedSummary);
      if (
        this.summary &&
        Object.prototype.hasOwnProperty.call(this.summary, "text")
      ) {
        this.textSummary = this.summary.text;
      }
      if (
        this.summary &&
        Object.prototype.hasOwnProperty.call(this.summary, "participants")
      ) {
        this.participants = this.summary.participants;
      }
      if (
        this.summary &&
        Object.prototype.hasOwnProperty.call(this.summary, "bulletPoints")
      ) {
        this.listSummary = this.summary.bulletPoints;
      }
      if (
        this.summary &&
        Object.prototype.hasOwnProperty.call(this.summary, "decisions")
      ) {
        this.decisions = this.summary.decisions;
      }
      if (
        this.summary &&
        Object.prototype.hasOwnProperty.call(this.summary, "todos")
      ) {
        this.todos = this.summary.todos;
      }
      if (
        this.summary &&
        Object.prototype.hasOwnProperty.call(this.summary, "dataSharingOption")
      ) {
        this.dataSharingOption = this.summary.dataSharingOption;
      } else {
        this.firstTimeOpening = true;
      }
    } else {
      this.firstTimeOpening = true;
    }
    this.editor = new Editor({
      content: this.textSummary,
      useBuiltInExtensions: true,
      onUpdate() {
        that.textSummary = this.getJSON();
        that.updateSummary();
      },
    });
    this.styledTranscript = new Editor({
      editable: false,
      content: this.getTranscript.document,
      extensions: [
        new Doc(),
        new SpeakerFrame(this),
        new Transcription(),
        new Text(),
        new Phrase(),
      ],
    });
  },
};
</script>

<style lang="scss" scoped>
.page-title {
  display: inline-block;
  margin-left: 40px;
}
.button-back {
  float: left;
  right: 3px;
}
.frame-wrapper {
  text-align: left;
  padding: 10px 20px 0 20px !important;
}
.summary-wrapper {
  border-left: 2px solid #54a1a9;
  margin-bottom: 10px;
}
.search-input-container {
  display: flex;
  padding-bottom: 15px;
  padding-left: 30px;
  align-items: center;
}
.filter-title {
  padding-left: 30px;
}
.search-label {
  padding-right: 10px;
  font-weight: bold;
}
.search-input {
  width: 40%;
  padding: 5px;
}
.search-output {
  display: block;
  max-height: 145vh;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: transparent;
}
.search-result-wrapper {
  width: 100%;
}
.search-result {
  display: flex;
  font-size: 14px;
  padding: 5px;
}
.search-empty {
  display: flex;
  font-size: 16px;
  margin-top: 20px;
  margin-left: 30px;
}
.invalid-keyword {
  color: red;
  margin-left: 30px;
}
.search-spinner ::v-deep .md-progress-spinner-draw {
  width: 25px !important;
  height: 25px !important;
}
.search-button {
  width: 25px !important;
  height: 25px !important;
}
.md-button,
.md-button-clean {
  white-space: normal;
}
.speaker-timestamp {
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  font-size: 10px;
  color: grey;
  opacity: 0.8;
  bottom: 8px;
  max-width: max-content;
  min-width: min-content;
}
.speaker-text {
  display: inline-block;
  border-left: 2px solid black;
  text-align: left;
  padding: 5px 5px 5px 30px !important;
  background-color: cyan;
}
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
  z-index: 10;
}
.participants-message {
  font-size: smaller;
}
.participants-warning {
  color: red;
}
.editor-field-summary {
  border: solid black 1px;
  background-color: white;
  padding: 0 5px;
}
.button-summary-text {
  float: right;
}
table {
  width: 100%;
}
.list-summary-input {
  width: 100%;
  padding: 5px;
}
.editor-field-summary {
  min-height: 80px;
  width: 99%;
}
.delete-button {
  width: 50px;
}
.add-button {
  width: 100%;
  padding-right: 65px;
}
.section {
  padding-bottom: 15px;
}
.first-time-data-sharing-options-introduction {
  padding-bottom: 15px;
}
.first-time-data-sharing-options {
  display: flex;
  flex-direction: column;

  > * {
    margin-bottom: 0px;
  }
}
.first-time-data-sharing-options-confirmation {
  padding-top: 30px;

  .md-checkbox {
    margin-bottom: 0px;
  }

  #terms-and-conditions-container {
    display: flex;

    #terms-and-conditions-link {
      margin-top: 16px;
      margin-left: -16px;
    }
  }
}
</style>
