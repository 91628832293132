<template>
  <div>
    <md-progress-bar md-mode="buffer" :md-value="progress" :md-buffer="0"></md-progress-bar>
    <div class="md-layout md-gutter md-alignment-center-center">
      <div class="md-layout-item md-size-50 timer">
        <p class="md-body-1">{{ $t('progressBar.elapsed') }}</p>
      </div>
      <div class="md-layout-item md-size-50 timer">
        <p class="md-body-1">{{ $t('progressBar.estimatedDuration') }}</p>
      </div>
    </div>
    <div class="md-layout md-gutter md-alignment-center-center">
      <div class="md-layout-item md-size-50 timer">
        <p class="md-display-1">{{timeString()}}</p>
      </div>
      <div class="md-layout-item md-size-50 timer">
        <p class="md-display-1">{{parseSeconds(maxDuration, {precision: 0})}}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProgressBar',
  props: ['maxDuration'],
  data() {
    return {
      progress: 0,
      seconds: 0,
    };
  },
  methods: {
    start() {
      this.interval = setInterval(() => this.updateTime(), 1000);
    },
    stop() {
      clearInterval(this.interval);
    },
    updateTime() {
      this.seconds += 1;
      this.progress = this.calculateProgress();
    },
    calculateProgress() {
      return Math.floor((100 * this.seconds) / this.maxDuration);
    },
    timeString() {
      const hours = `${Math.floor(this.seconds / 3600)}`;
      let minutes = `${Math.floor(this.seconds / 60) - (hours * 60)}`;
      let seconds = `${this.seconds - (hours * 3600) - (minutes * 60)}`;

      minutes = minutes.length < 2 ? `0${minutes}` : `${minutes}`;
      seconds = seconds.length < 2 ? `0${seconds}` : `${seconds}`;
      return `${hours}:${minutes}:${seconds}`;
    },
  },
};
</script>

<style scoped>
  .timer {
    text-align: center;
  }

  .timer > .md-display-1 {
    margin-top: 0;
  }

  .timer > .md-body-1 {
    margin: 30px 0 0;
  }
</style>
