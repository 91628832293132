var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"md-layout-item"},[(
      _vm.editName || (!_vm.isValidName && _vm.isNameChecked) || _vm.isCurrentlyValidating
    )?_c('input',{directives:[{name:"focus",rawName:"v-focus"}],staticClass:"project-name-input",attrs:{"disabled":_vm.isCurrentlyValidating},domProps:{"value":_vm.editedTranscriptName},on:{"blur":function($event){_vm.$emit('update');
      _vm.onProjectEdit($event, _vm.transcript);},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;_vm.$emit('update');
      _vm.onProjectEdit($event, _vm.transcript);}}}):_c('h3',{staticClass:"project-name",on:{"click":function($event){_vm.editName = true;
      _vm.$emit('update');
      _vm.setTemporaryName(_vm.transcript);}}},[_vm._v(" "+_vm._s(_vm.transcript.name)+" ")]),(!_vm.isValidName && _vm.isNameChecked)?_c('NameExistsError',{staticClass:"name-exists-error"}):_vm._e(),_c('audio',{ref:"htmlPlayer",staticClass:"play",attrs:{"controls":"","controlslist":"nodownload"},on:{"error":_vm.audioLoadingError,"play":_vm.start,"pause":_vm.stop,"timeupdate":_vm.syncTranscriptToAudio}},[_c('source',{attrs:{"src":_vm.audioSrc,"type":"audio/wav"}}),_c('source',{attrs:{"src":_vm.audioSrc,"type":"audio/mpeg"}}),_c('source',{attrs:{"src":_vm.audioSrc,"type":"audio/ogg"}}),_vm._v(" Your browser does not support the audio element. ")]),_c('button',{staticClass:"stepBackwardButton",on:{"click":function($event){return _vm.jumpBack(_vm.audioStep)}}},[_c('img',{staticClass:"stepIcon",attrs:{"src":_vm.stepBackwardIcon}}),_c('md-tooltip',{attrs:{"md-direction":"top"}},[_vm._v(_vm._s(_vm.$t("editor.wordContextMenu.jumpBack")))])],1),_c('button',{staticClass:"stepForwardButton",on:{"click":function($event){return _vm.jumpForth(_vm.audioStep)}}},[_c('img',{staticClass:"stepIcon",attrs:{"src":_vm.stepForwardIcon}}),_c('md-tooltip',{attrs:{"md-direction":"top"}},[_vm._v(_vm._s(_vm.$t("editor.wordContextMenu.jumpForth")))])],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.audioStep),expression:"audioStep"}],staticClass:"audioStepper",attrs:{"type":"number","min":0,"max":99999,"steps":1},domProps:{"value":(_vm.audioStep)},on:{"change":function($event){return _vm.setAudioStep($event.target.value)},"input":function($event){if($event.target.composing)return;_vm.audioStep=$event.target.value}}}),(_vm.isInvalidInput)?_c('div',{staticClass:"invalid-input-message"},[_vm._v(" "+_vm._s(_vm.$t("editor.invalidAudioStepperInput"))+" ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }