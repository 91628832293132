import ApiService from '@/services/api.service';

const state = {
  createdAt: undefined,
  syncing: false,
  highlights: [],
  showUpdatePopup: false,
  showHelp: false,
  changes: [],
  transcriptId: undefined,
};

const mutations = {
  setShowUpdatePopup(state, show) {
    state.showUpdatePopup = show;
  },
  setHighlights(state, highlights) {
    state.highlights = highlights;
  },
  clearChanges(state) {
    state.changes = [];
  },
  setSelectedHighlight(state, id) {
    const highlights = [...state.highlights];
    const index = highlights.findIndex(highlight => highlight.id === id);
    const highlight = highlights[index];

    highlight.selected = !highlight.selected;
    if (state.changes.some(change => change.id === highlight.id)) {
      state.changes = state.changes.filter(change => change.id !== highlight.id);
    } else {
      state.changes = [
        ...state.changes,
        {
          id: highlight.id,
          selected: highlight.selected,
        },
      ];
    }
  },
  setCreatedAt(state, createdAt) {
    state.createdAt = createdAt;
  },
  setSyncing(state, syncing) {
    state.syncing = syncing;
  },
  setTranscriptId(state, transcriptId) {
    state.transcriptId = transcriptId;
  },
  setShowHelp(state, show) {
    state.showHelp = show;
  },
};

const actions = {
  async updateHighlights({
    commit,
    getters,
  }, transcriptId) {
    const changes = getters.getChanges;
    if (changes.length === 0) {
      return;
    }

    const reqData = {
      method: 'post',
      url: `/highlights/update?transcript_id=${transcriptId}`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(changes),
    };
    await ApiService.custom(reqData);
    commit('clearChanges');
  },
  async fetchHighlights({
    commit,
    getters,
  }, {
    transcriptId,
    force = false,
  }) {
    if (!force) {
      if (getters.getHighlights && getters.getHighlights.length > 0 && getters.getHighlightsTranscriptId === transcriptId) {
        return;
      }
    }
    const options = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    };
    let newHighlights = false;
    commit('setSyncing', true);
    try {
      const getResponse = await ApiService.get(`/highlights/get?transcript_id=${transcriptId}`);
      const getData = getResponse.data;
      commit('setHighlights', getData.highlights);
      const createDate = new Date(getData.created_at);
      commit('setCreatedAt', createDate.toLocaleString([], options));
      if (!getData.stale) {
        commit('setSyncing', false);
        return;
      }
    } catch (error) {
      newHighlights = error?.response?.status === 404;
      commit('setHighlights', []);
      commit('setCreatedAt', '');
    } finally {
      commit('setTranscriptId', transcriptId);
    }
    try {
      const generateResponse = await ApiService.get(`/highlights/generate?transcript_id=${transcriptId}`);
      const generateData = generateResponse.data;
      commit('setHighlights', generateData.highlights);
      const createDate = new Date(generateData.created_at);
      commit('setCreatedAt', createDate.toLocaleString([], options));
    } catch (error) {
      // console.log(error);
    }
    commit('setSyncing', false);
    commit('setShowUpdatePopup', !newHighlights);
    commit('setShowHelp', newHighlights);
  },
};

const getters = {
  getHighlights: (state) => {
    return state.highlights;
  },
  getSyncing: (state) => {
    return state.syncing;
  },
  getChanges: (state) => {
    return state.changes;
  },
  getCreatedAt: (state) => {
    return state.createdAt;
  },
  getShowUpdatePopup: (state) => {
    return state.showUpdatePopup;
  },
  getHighlightsTranscriptId: (state) => {
    return state.transcriptId;
  },
  getShowHelp: (state) => {
    return state.showHelp;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
