<template>
  <md-table
    v-model="transactions"
    md-sort="id"
    md-sort-order="asc"
    md-fixed-header
  >
    <md-table-toolbar>
      <h1 class="md-title">{{ $t("settings.transactions") }}</h1>
    </md-table-toolbar>
    <md-table-row slot="md-table-row" slot-scope="{ item }">
      <md-table-cell
        :md-label="$t('transactions.id')"
        md-sort-by="id"
        class="table-cell-wrap-text"
      >
        {{ item.id }}
      </md-table-cell>
      <md-table-cell
        :md-label="$t('transactions.date')"
        md-sort-by="date"
        class="table-cell-wrap-text"
      >
        {{ item.date }}
      </md-table-cell>
      <md-table-cell
        :md-label="$t('transactions.type')"
        md-sort-by="type"
        class="table-cell-wrap-text"
      >
        {{ item.type }}
      </md-table-cell>
      <md-table-cell
        :md-label="$t('transactions.amount')"
        md-sort-by="credit_amount"
        class="table-cell-wrap-text"
      >
        {{ item.credit_amount }}
      </md-table-cell>
    </md-table-row>
  </md-table>
</template>

<script>
import ApiService from "../services/api.service";
export default {
  name: "TransactionsTab",
  components: {},
  data: () => {
    return {
      transactions: [],
    };
  },
  computed: {},
  watch: {},
  mounted() {
    ApiService.get("/Transaction.list").then(async (response) => {
      this.transactions = response.data.data;
      this.transactions = this.transactions.map((transaction) => ({
        ...transaction,
        date: this.formatDate(transaction.date),
      }));
    });
  },
  methods: {
    formatDate(dateString) {
      const options = { day: "2-digit", month: "2-digit", year: "numeric" };
      const date = new Date(dateString);
      return date.toLocaleDateString("en-GB", options);
    },
  },
};
</script>

<style scoped lang="scss">
.md-table-cell-container {
  padding: 2px 2px 2px 2px !important;
  text-align: left !important;
  width: 100px;
}
.table-cell-wrap-text {
  word-break: break-all;
}

.md-table-cell {
  height: 40px;
}
.md-table.md-theme-default .md-table-row td {
  text-align: left;
}

.md-title {
  text-align: left;
}
</style>
