import { mapActions } from "vuex";
import i18n from "@/i18n";
export default {
  data() {
    return {
      subscriptionName: "",
      subscriptionPriceInCHF: null,
      subscriptionTimeInHours: null,
      activeSubscription: null,
      activeSubscriptionStart: "",
      activeSubscriptionEnd: "",
      activeSubscriptionTimeLeftInMinutes: null,
      subscriptionCanceled: false,
    };
  },
  async mounted() {
    this.reloadSubscription();
  },
  methods: {
    ...mapActions("payment", [
      "getSubscriptionInfo",
      "cancelSubscription",
      "createSubscription",
      "reactivateSubscription",
    ]),
    reloadSubscription() {
      const body = {
        plan_id: this.planId,
      };
      this.getSubscriptionInfo(body).then((response) => {
        this.updateUI(response.data);
      });
    },
    updateUI(subInfo) {
      this.subscriptionName = subInfo.name;
      this.subscriptionPriceInCHF = subInfo.price_in_rp / 100;
      this.subscriptionTimeInHours = subInfo.free_transcription_time / 60;
      this.activeSubscription = subInfo.active;
      if (this.activeSubscription) {
        this.activeSubscriptionTimeLeftInMinutes = subInfo.free_transcription_time_left / 60;
      }

      if (subInfo.has_stripe_status) {
        this.subscriptionCanceled = subInfo.canceled;
        let startDate = new Date(subInfo.created_date * 1000);
        this.activeSubscriptionStart =
          i18n.t("subscriptions.starts") + startDate.toLocaleDateString();
        let enddDate = new Date(
          subInfo.canceled ? subInfo.end_date * 1000 : subInfo.renew_date * 1000
        );
        this.activeSubscriptionEnd =
          (this.subscriptionCanceled
            ? i18n.t("subscriptions.ends")
            : i18n.t("subscriptions.renew")) + enddDate.toLocaleDateString();
      }
    },
    cancel() {
      const body = {
        plan_id: this.planId,
      };
      this.cancelSubscription(body).then((response) =>
        this.updateUI(response.data)
      );
    },
    reactivate() {
      if (this.subscriptionCanceled) {
        const body = {
          plan_id: this.planId,
        };
        this.reactivateSubscription(body).then((response) =>
          this.updateUI(response.data)
        );
      }
    },
  },
};
