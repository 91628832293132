<template>
  <Page>
    <div
      class="md-layout md-gutter md-alignment-top-space-around load-file-section"
    >
      <div class="md-layout-item md-size-100">
        <md-card class="help">
          <md-card-content>
            <md-card-title class="help-title">
              {{ $t("help.title") }}
            </md-card-title>
            <md-list>
              <md-list-item>
                <div class="video-with-description">
                  <div class="content-text">
                    {{ $t("help.tutorials") }}
                  </div>
                  <WelcomeVideos class="video-text" />
                </div>
              </md-list-item>
              <md-list-item>
                <div class="content-text">
                  <a href="https://stenoris.com/faq/" target="_blank">{{
                    $t("help.faqs")
                  }}</a>
                </div>
              </md-list-item>
              <md-list-item>
                <div class="content-text">
                  {{ $t("help.support0") }}<br />
                  {{ $t("help.support1") }}
                  <a href="mailto:info@stenoris.ch"
                    ><span class="email">info@stenoris.ch</span></a
                  >.
                </div>
              </md-list-item>
            </md-list>
          </md-card-content>
        </md-card>
      </div>
    </div>
  </Page>
</template>

<script>
import Page from "../components/Page";
import WelcomeVideos from "../components/WelcomeVideos";
export default {
  name: "Help",
  components: { Page, WelcomeVideos },
  props: ["show"],
  computed: {
    helpDialogActive: {
      get() {
        return this.show;
      },
      set(newValue) {
        if (!newValue) {
          this.close();
        }
      },
    },
  },
  methods: {
    close() {
      this.$emit("close-window");
    },
  },
};
</script>

<style scoped>
.help-title {
  font-size: 24px;
}
.content-text {
  font-size: 16px;
  margin-top: 20px;
}
.email {
  text-decoration: underline;
}
a:hover {
  cursor: pointer;
}
.video-text {
  font-size: 12px;
}
.md-dialog-title {
  padding-left: 16px;
}
</style>
